import React from 'react'
import PrimaryButton from '../PrimaryButton'
import { ExclamationIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import SecondaryButton from '../SecondaryButton'

const DeleteConfirmModalState = ({ title, setState, deleteAction, deleteOnlyModal, onClose, testId }) => {
  return (
    <>
      <div className="sm:flex sm:items-start" data-testid={testId}>
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            Delete Confirmation
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to delete <b>{title}</b>?
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <SecondaryButton
          label="Back"
          testId="delete-confirm-secondary-button"
          extraClasses="uppercase"
          onClick={() => {
            if (deleteOnlyModal) onClose()
            else setState('form')
          }}
        />
        <PrimaryButton testId={testId ? "delete-confirm-primary-button" : undefined} extraClasses="uppercase" label="Delete" onClick={() => deleteAction()} />
      </div>
    </>
  )
}

export default DeleteConfirmModalState
