import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../../components/general/PrimaryButton'
import FormField from '../../utils/FormField'
import { upsertTagAsync, clearErrors } from './tagSlice'

const TagForm = ({ setState, setOpen, editingItem }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({})
  const { error } = useSelector((state) => state.user)

  useEffect(() => {
    if (editingItem) {
      setFormValues({ ...editingItem })
    } else {
      setFormValues({})
    }
  }, [editingItem])

  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    error && error[name] && dispatch(clearErrors({ field: name }))
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  return (
    <div>
      <h3 className="text-2xl">
        {formValues?.id ? 'Edit tag: ' + formValues.name : 'Add a new tag'}
      </h3>
      <form
        onSubmit={async (event) => {
          event.preventDefault()
          const upsertResult = await dispatch(upsertTagAsync({ tag: formValues }))

          if ('tag/upsert/fulfilled' === upsertResult.type) {
            setOpen(false)
          }
        }}
      >
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <FormField
              label="Name"
              field="name"
              error={error && error['name']}
              onChange={onChange}
              state={formValues}
            />
          </div>
        </div>

        <div className="mt-24 py-3 sm:flex sm:justify-between">
          <div>
            {editingItem?.id && (
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                onClick={() => setState('delete')}
              >
                Delete
              </button>
            )}
          </div>
          <div className="flex">
            <PrimaryButton extraClasses="sm:w-auto sm:ml-3" label="Save" type="submit" />

            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default TagForm
