import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'
import { removeLoadingKey } from '../../helpers'

export const ResourceLoadingKey = {
  resources: "ALL_RESOURCES",
  categories: "ALL_CATEGORIES"
}

const initialState = {
  loading: true,
  loadingKeys: [],
  error: null,
  resources: [],
  resourceCategories: []
}

export const readManyResourcesAsync = createAsyncThunk(
  'resource/readMany',
  async ({ filters, search, itemsPerPage }) => {
    const { categories, type } = filters
    const params = { categories, type, search, itemsPerPage }
    const { data } = await axios.get('/api/resource/read-many', { params })
    return { resources: data.resources }
  }
)

export const readResourceByIdAsync = createAsyncThunk('resource/readById', async (id) => {
  const { data } = await axios.get(`/api/resource/${id}`)
  return data
})

export const readManyResourceCategoriesAsync = createAsyncThunk(
  'resource/category/readMany',
  async () => {
    const {
      data: { resourceCategories }
    } = await axios.get('/api/resource/category/read-many')
    return { resourceCategories }
  }
)

export const upsertResourceAsync = createAsyncThunk(
  'resource/upsert',
  async ({ resource }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/resource/upsert`, resource)
      return response.data
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors)
      }
    }
  }
)

export const upsertResourceCategoryAsync = createAsyncThunk(
  'resource/category/upsert',
  async ({ category }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/resource/category/upsert`, category)
      return response.data
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors)
      }
    }
  }
)

export const deleteResourceAsync = createAsyncThunk(
  'resource/delete',
  async ({ resource: { id } }) => {
    await axios.delete(`/api/resource/${id}`)
    return { id }
  }
)

export const deleteResourceCategoryAsync = createAsyncThunk(
  'resource/category/delete',
  async ({ category: { id } }) => {
    await axios.delete(`/api/resource/category/${id}`)
    return { id }
  }
)

export const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null
    },
    clearErrors: (state) => {
      state.error = null
      state.generalError = null
    },
    setError: (state, action) => {
      state.error[action.payload.field] = action.payload.error
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(readManyResourcesAsync.pending, (state) => {
        state.loading = true
        state.loadingKeys = [...state.loadingKeys, ResourceLoadingKey.resources]
      })
      .addCase(readManyResourcesAsync.fulfilled, (state, action) => {
        state.resources = action.payload.resources
        state.loading = false
        state.loadingKeys = removeLoadingKey(ResourceLoadingKey.resources, state.loadingKeys)
      })
      .addCase(readManyResourcesAsync.rejected, (state, action) => {
        state.loading = false
        state.loadingKeys = removeLoadingKey(ResourceLoadingKey.resources, state.loadingKeys)
      })
      .addCase(readResourceByIdAsync.pending, (state) => {
        state.loading = true
        state.loadingKeys = [...state.loadingKeys, ResourceLoadingKey.resources]
      })
      .addCase(readResourceByIdAsync.fulfilled, (state, action) => {
        state.resources = [action.payload.resource]
        state.loading = false
        state.loadingKeys = removeLoadingKey(ResourceLoadingKey.resources, state.loadingKeys)
      })
      .addCase(readResourceByIdAsync.rejected, (state, action) => {
        state.loading = false
        state.loadingKeys = removeLoadingKey(ResourceLoadingKey.resources, state.loadingKeys)
      })
      .addCase(readManyResourceCategoriesAsync.pending, (state) => {
        state.loading = true
        state.loadingKeys = [...state.loadingKeys, ResourceLoadingKey.categories]
      })
      .addCase(readManyResourceCategoriesAsync.fulfilled, (state, action) => {
        state.resourceCategories = action.payload.resourceCategories
        state.loading = false
        state.loadingKeys = removeLoadingKey(ResourceLoadingKey.categories, state.loadingKeys)
      })
      .addCase(readManyResourceCategoriesAsync.rejected, (state, action) => {
        state.loading = false
        state.loadingKeys = removeLoadingKey(ResourceLoadingKey.categories, state.loadingKeys)
      })
      .addCase(upsertResourceAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertResourceAsync.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.isNew) {
          // insert
          state.resources.unshift(action.payload.resource)
          toast.success('Resource created successfully')
        } else {
          // update
          state.resources = state.resources.map((resource) =>
            resource.id === action.payload.resource.id ? action.payload.resource : resource
          )
          toast.success('Resource saved successfully')
        }
      })
      .addCase(upsertResourceAsync.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(upsertResourceCategoryAsync.pending, (state, action) => {
        state.loading = true
      })
      .addCase(upsertResourceCategoryAsync.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.isNew) {
          // insert
          state.resourceCategories.unshift(action.payload.resourceCategory)
          toast.success('Resource created successfully')
        } else {
          // update
          state.resourceCategories = state.resourceCategories.map((category) =>
            category.id === action.payload.resourceCategory.id
              ? action.payload.resourceCategory
              : category
          )
          toast.success('Resource saved successfully')
        }
      })
      .addCase(upsertResourceCategoryAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })

      .addCase(deleteResourceAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteResourceAsync.fulfilled, (state, action) => {
        state.loading = false
        state.resources = state.resources.filter((resource) => resource.id !== action.payload.id)
        toast.success('Resource deleted successfully')
      })
      .addCase(deleteResourceAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(deleteResourceCategoryAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteResourceCategoryAsync.fulfilled, (state, action) => {
        state.loading = false
        state.resourceCategories = state.resourceCategories.filter(
          (category) => category.id !== action.payload.id
        )
        toast.success('Category deleted successfully')
      })
      .addCase(deleteResourceCategoryAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
  }
})
export const { clearError, clearErrors, setError } = resourceSlice.actions
export default resourceSlice.reducer
