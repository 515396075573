import React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { useDispatch } from 'react-redux'
import { mentionRemoveUser } from '../moveTheNeedleSlice'

const Mentions = ({ mentionedUsers, committed }) => {
  const dispatch = useDispatch()
  return (
    <ul className="absolute top-2 left-2 flex space-x-1">
      {mentionedUsers?.map((user) => {
        return (
          <li
            key={user.id}
            className="flex items-center border bg-white text-purple-450 border-purple-450 px-2 py-0.5 text-xs rounded-md"
          >
            @{user.fullName}{' '}
            {!committed && (
              <span
                onClick={() => dispatch(mentionRemoveUser({ UserId: user.id }))}
                className="ml-1 cursor-pointer"
              >
                <XIcon className="h-4 w-4" />
              </span>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default Mentions
