import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../../components/general/LoadingSpinner.js'
import { uploadFileAsync, deleteFile } from './uploaderSlice.js'
import { XIcon } from '@heroicons/react/solid'
import { upsertAttachmentAsync } from '../move-the-needle/moveTheNeedleSlice.js'

const AttachmentUploader = ({ field, setAttachmentShowing, CardId }) => {
  const file = useRef(null)
  const { loading } = useSelector((state) => state.uploader)
  const dispatch = useDispatch()

  // upload file listener
  useEffect(() => {
    document.getElementById(field).onclick = (event) => {
      document.getElementById(field).value = null
    }

    document.getElementById(field).onchange = (event) => {
      if (event.target.files && event.target.files.length > 0) {
        dispatch(uploadFileAsync({ id: field })).then(({ payload }) => {
          dispatch(
            upsertAttachmentAsync({
              attachment: {
                fileUrl: payload.latestUploadedFileUrl,
                fileName: event.target.files[0].name
              },
              CardId
            })
          )
        })
      }
    }

    return () => {
      dispatch(deleteFile())
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="">
      <input id={field} type="file" name={field} ref={file} className="hidden" accept="image/*" />
      <div className=" border-gray-300 rounded-md">
        <div>
          <div className="flex justify-between mb-4">
            <div className="">Attachments</div>
            <div onClick={() => setAttachmentShowing(false)}>
              <XIcon className="h-4 w-4" />
            </div>
          </div>
          <button
            type="button"
            className="uppercase inline-flex items-center w-full justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-450 hover:bg-purple-450 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450"
            disabled={loading}
            onClick={() => {
              if (file && file.current) {
                file.current.click()
              }
            }}
          >
            {loading ? <LoadingSpinner /> : 'From Computer'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AttachmentUploader
