import { InformationCircleIcon } from "@heroicons/react/outline"
import classNames from "classnames";
import ReactTooltip from "react-tooltip"

export const InformationIcon = ({
    tooltipId,
    tooltipMessage,
    html = false,
    type = "default",
    onClick,
}) => {
    if (!tooltipMessage) return null
    return (
        <div onClick={onClick} className={classNames([
            "cursor-pointer",
            "transition-colors",
            {
                "w-full md:w-auto xl:w-80": type === "default",
                "w-auto flex items-center text-dark hover:text-secondary": type === "module",
                "w-auto text-dark hover:text-secondary": 
                    type === "lesson" || type === "course-title",
                "bg-white rounded-lg flex items-center justify-center w-8 h-8 md:w-auto md:h-auto text-dark hover:text-secondary": type === "active-lesson"
            }
        ])} data-tip data-for={tooltipId}>
            <InformationCircleIcon className={classNames([
                "w-5 h-5",
                "bg-white rounded-lg",
                {
                    "w-6 h-6": type === "active-lesson"
                }
            ])} />
            <ReactTooltip 
                id={tooltipId} 
                effect="solid"
                html={html}
                className={classNames([
                    {
                        "max-w-course-title-tooltip": type === "course-title"
                    }
                ])}
            >
                {tooltipMessage}
            </ReactTooltip>
        </div>
    )
}