import classNames from "classnames";
import {CheckIcon} from '@heroicons/react/solid';

const LessonCompleted = ({completed, active}) => {
    return (
        <div className={classNames([
            'w-5 lg:w-6 h-5 lg:h-6 flex-shrink-0 border-2 border-solid border-secondary rounded-full relative overflow-hidden',
            {
                'bg-secondary text-white flex justify-center items-center': completed
            }
        ])}>
            {completed ? (
                <CheckIcon className="w-3.5 lg:w-4 h-3.5 lg:h-4" />
            ) : null}
            {active ? (
                <div className="absolute top-0 left-0 h-full w-1/2 bg-secondary" />
            ) : null}
        </div>
    );
}

export default LessonCompleted;