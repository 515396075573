import { LockClosedIcon } from '@heroicons/react/solid'

const LockedFeature = ({ dataForId }) => {
  return (
    <div className="relative w-32 h-32">
      <div className="absolute inset-0 bg-grey rounded-lg shadow-lg filter blur"></div>
      <div className="absolute inset-0 flex items-center justify-center">
        <a
          href={'https://rainmakerfamily.com/mastermind/?el=rmapp'}
          target="_blank"
          data-for={dataForId}
          data-tip
          rel="noopener noreferrer"
          className="absolute top-0 left-0 w-full h-full cursor-pointer z-20"
        />
        <LockClosedIcon className="w-28 h-28 z-10 text-purple-450" />
      </div>
    </div>
  )
}

export default LockedFeature
