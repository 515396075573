import classNames from "classnames"

export const MainLoader = ({
    visibility = "default"
}) => {
    
    return (
        <div className="h-full w-full flex justify-center items-center">
            <div className={classNames([
                "border-gray-300 border-t-transparent rounded-full animate-spin main-spinner-view",
                {
                    "default-loader-size": visibility === "default",
                    "small-loader-size": visibility === "small"
                }
            ])}></div>
        </div>
    )
}