import React from 'react';

const ChatProductCreationExpert = () => {
  return (<>
    <iframe
        title="chatbot"
        src="https://www.chatbase.co/chatbot-iframe/9GbUlAdiNrpPL7wZBa2bb"
        width="100%"
        className="ai-tool-iframe"
    ></iframe>
  </>);
}

export default ChatProductCreationExpert
