import React, { useState, useEffect } from 'react'
import PrimaryButton from '../../../components/general/PrimaryButton'
import SecondaryButton from '../../../components/general/SecondaryButton'
import FormField from '../../../utils/FormField'
import { CheckIcon } from '@heroicons/react/outline'
import { useDispatch, useSelector } from 'react-redux'
import { upsertLabelAsync } from '../moveTheNeedleSlice'

const NewLabelForm = ({ editingLabel, setEditingLabel, userId }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(editingLabel || { isNew: true })
  const { labels } = useSelector((state) => state.moveTheNeedle)
  const [originalColor, setOriginalColor] = useState(null)

  useEffect(() => {
    if (editingLabel) {
      setOriginalColor(editingLabel.color)
      setFormValues(editingLabel)
    }
    // eslint-disable-next-line
  }, [editingLabel])

  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const [colors] = useState([
    { color: 'red-200', checked: true },
    { color: 'pink-200', checked: false },
    { color: 'purple-200', checked: false },
    { color: 'blue-200', checked: false },
    { color: 'green-200', checked: false },
    { color: 'yellow-200', checked: false },
    { color: 'yellow-400', checked: false },
    { color: 'gray-200', checked: false }, // sky
    { color: 'indigo-200', checked: false }, // mint
    { color: 'purple-400', checked: false } // lime
  ])

  return (
    <form
      className="mt-4"
      onSubmit={async (event) => {
        event.preventDefault()
        await dispatch(upsertLabelAsync({ label: { ...formValues, userId } }))
        setEditingLabel(false)
      }}
    >
      <h4>{formValues?.isNew ? 'Create' : 'Update'} Label</h4>

      <FormField
        label="Label name"
        field="name"
        value={formValues?.name || ''}
        onChange={onChange}
        required={false}
        state={formValues}
      />

      <label className="block text-sm font-medium text-gray-700 mt-2">Choose Color</label>

      <div className="mt-1">
        <ul className="flex space-x-4">
          {colors
            .filter(
              ({ color }) =>
                originalColor === color ||
                formValues?.color === color ||
                !labels.find((label) => label.color === color)
            )
            .map(({ color }) => (
              <li key={color}>
                <span
                  className={`flex justify-center items-center rounded-full w-6 h-6 text-xs bg-${color} text-white cursor-pointer`}
                  onClick={() => {
                    setFormValues((prevState) => ({
                      ...prevState,
                      color
                    }))
                  }}
                >
                  {formValues?.color === color && <CheckIcon className="w-4 h-4" />}
                </span>
              </li>
            ))}
        </ul>
      </div>

      <div className="flex justify-between mt-4 gap-2">
        <SecondaryButton
          label="Back"
          extraClasses="uppercase w-full"
          onClick={() => setEditingLabel(false)}
        />
        <PrimaryButton
          label={formValues?.isNew ? 'Create' : 'Update'}
          extraClasses={`uppercase w-full ${
            !formValues?.color ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          type="submit"
          disabled={!formValues?.color}
        />
      </div>
    </form>
  )
}

export default NewLabelForm
