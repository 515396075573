import React from 'react'
import { Link } from 'react-router-dom'

export const NotFound = ({ 
    testId,
    title,
    description,
    linkButton,
    titleButton
}) => {
  return (
    <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8" data-testid={testId}>
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-purple-450 sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              {title && (
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    {title}
                </h1>
              )}
              {description && (
                <p className="mt-1 text-base text-gray-500">
                    {description}
                </p>
              )}
            </div>
            {linkButton && (
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                    <Link
                        to={linkButton}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-450 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450"
                    >
                        {titleButton || "Go back"}
                    </Link>
                </div>
            )}
          </div>
        </main>
      </div>
    </div>
  )
}
