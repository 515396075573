import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardBannerForm from '../../features/dashboard/DashboardBannerForm'
import { getBannerByType, getBanners, removeBanner } from '../../features/dashboard/dashboardSlice'
import { Banner } from './Banner'
import FormModal from './FormModal'
import BoxCard from './box-card/BoxCard'
import Btn from './Btn'

const DashboardBanner = ({ testId, testData }) => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => state.auth)
  const currentUserItem = testId ? testData?.currentUser : currentUser
  const [bannerModalOpen, setModalBannerOpen] = useState(false)
  const [editingBanner, setEditingBanner] = useState({})
  const dashboardBasicBanner = useSelector((state) => state.dashboardBanner.basicBanner)
  const dashboardCourseOnlyBanner = useSelector((state) => state.dashboardBanner.courseOnlyBanner)
  const dashboardPremiumBanner = useSelector((state) => state.dashboardBanner.premiumBanner)
  const [basicBanner, setBasicBanner] = useState(testId ? testData?.basicBanner : dashboardBasicBanner)
  const [courseOnlyBanner, setCourseOnlyBanner] = useState(testId ? testData?.courseOnlyBanner : dashboardCourseOnlyBanner)
  const [premiumBanner, setPremiumBanner] = useState(testId ? testData?.premiumBanner : dashboardBasicBanner)

  useEffect(() => {
    if (!testId) {
      if (currentUserItem.role === 'admin') {
        dispatch(getBanners(null))
      } else if (currentUserItem.role === 'basic') {
        dispatch(getBannerByType({ type: 'basic' }))
      } else if (currentUserItem.role === 'course only') {
        dispatch(getBannerByType({ type: 'course only' }))
      } else if (currentUserItem.role === 'premium' || currentUserItem.role === 'coach') {
        dispatch(getBannerByType({ type: 'premium' }))
      }
    }
  }, [dispatch, currentUserItem])

  useEffect(() => {
    if (!testId) {
      setBasicBanner(dashboardBasicBanner)
      setCourseOnlyBanner(dashboardCourseOnlyBanner)
      setPremiumBanner(dashboardPremiumBanner)
    }
  }, [dashboardBasicBanner, dashboardCourseOnlyBanner, dashboardPremiumBanner])

  const createBanner = (type) => {
    setModalBannerOpen(true)
    setEditingBanner({ type })
  }

  const editBanner = (banner) => {
    setModalBannerOpen(true)
    setEditingBanner(banner)
  }

  return (
    <>
      <div className="space-y-4" data-testid={testId}>
        {premiumBanner &&
          (currentUserItem.role === 'premium' ||
            currentUserItem.role === 'coach' ||
            currentUserItem.role === 'admin') && (
            <Banner banner={premiumBanner} editBanner={editBanner} testId={testId ? `banner-${testId}-premiumBanner` : undefined} />
          )}
        {courseOnlyBanner &&
          (currentUserItem.role === 'course only' || currentUserItem.role === 'admin') && (
            <Banner banner={courseOnlyBanner} editBanner={editBanner} testId={testId ? `banner-${testId}-courseOnlyBanner` : undefined} />
          )}
        {basicBanner && (currentUserItem.role === 'basic' || currentUserItem.role === 'admin') && (
          <Banner banner={basicBanner} editBanner={editBanner} testId={testId ? `banner-${testId}-basicBanner` : undefined} />
        )}

        {!premiumBanner && currentUserItem.role === 'admin' && (
          <BoxCard>
            <div className="text-right">
              <Btn onClick={() => createBanner('premium')}>
                <span className="uppercase">New Banner for Premium Users</span>
              </Btn>
            </div>
          </BoxCard>
        )}
        {!courseOnlyBanner && currentUserItem.role === 'admin' && (
          <BoxCard>
            <div className="text-right">
              <Btn onClick={() => createBanner('course only')}>
                <span className="uppercase">New Banner for Course Only Users</span>
              </Btn>
            </div>
          </BoxCard>
        )}
        {!basicBanner && currentUserItem.role === 'admin' && (
          <BoxCard>
            <div className="text-right">
              <Btn onClick={() => createBanner('basic')}>
                <span className="uppercase">New Banner for Basic Users</span>
              </Btn>
            </div>
          </BoxCard>
        )}
      </div>
      <FormModal
        open={bannerModalOpen}
        setOpen={setModalBannerOpen}
        Form={DashboardBannerForm}
        editingItem={editingBanner}
        setEditingItem={setEditingBanner}
        deleteAction={() => dispatch(removeBanner({ type: editingBanner.type }))}
      />
    </>
  )
}

export default DashboardBanner
