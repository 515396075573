import React from 'react';
import { useSelector } from 'react-redux'
import AItoolCard from '../../components/general/AItoolCard';
import { AITags } from './const';

const AiToolKit = () => {
  const { currentUser } = useSelector((state) => state.auth);
  
  const hasRequiredTag = (REQUIRED_TAG_NAME) => {
    const isAdmin = currentUser.role === 'admin';
    return currentUser.Tags?.some((tag) => tag.name === REQUIRED_TAG_NAME) || isAdmin;
  }

  return (<>
    <div className="flex flex-col min-h-full py-6">
      <div className="container md:pb-6 mb-7.5 md:mb-3.5">
        <h1 className="text-lg md:text-xl leading-38 font-semibold tracking-1 font-base mt-1.5 md:mt-3.5">
          Ai Tool kit
        </h1>
      </div>
      <div className="container flex-grow overflow-y-auto flex items-center xl:items-start justify-center">
        <div className="w-full">
          <div className="2xl:mt-20 mb-8 mx-auto max-w-lg video wrapper">
            <div className="relative" style={{ paddingTop: '56.25%' }}>
              <iframe
                src="https://player.vimeo.com/video/1008297645?"
                className="absolute inset-0 w-full h-full"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen=""
                title="AI Tool Kit"
                data-ready="true"
              />
            </div>
          </div>
          <div className="grid sm:grid-cols-3 xl:grid-cols-5 gap-4">
            <AItoolCard
              title="Instagram Bio Expert"
              img="/images/ig-bio-expert-both.png"
              name="Sophie"
              link="/ai-tool-kit-chat"
              blocked={!hasRequiredTag(AITags.SOPHIE)}
            />
            <AItoolCard
              title="AI Curriculum Coach"
              img="/images/ai-rainmaker-coach.png"
              name="Raina"
              click={() => {
                // eslint-disable-next-line no-undef
                if (!!_searchie && typeof _searchie === 'object') {
                  // eslint-disable-next-line no-undef
                  _searchie.toggle();
                }
              }}
              blocked={!hasRequiredTag(AITags.RAINA)}
            />
            <AItoolCard
              title="Product Creation Expert"
              img="/images/product-creation-expert.png"
              name="Nova"
              link="/ai-tool-kit-chat-nova"
              blocked={!hasRequiredTag(AITags.NOVA)}
            />
            <AItoolCard
              title="Amazon Listing Expert"
              img="/images/listing-expert.png"
              name="Teller - Coming Soon"
              blocked={!hasRequiredTag(AITags.TALLER)}
            />
            <AItoolCard
              title="Customer Support"
              description="RainmakerFamilySupport.com"
              img="/images/customer-support-both.png"
              link="https://rainmakerfamilysupport.com"
              name="Renee - Coming Soon"
              blocked={!hasRequiredTag(AITags.RENEE)}
            />
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default AiToolKit
