import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FormModal from '../../components/general/FormModal'
import {
  deleteResourceAsync,
  deleteResourceCategoryAsync,
  readManyResourceCategoriesAsync,
  readManyResourcesAsync,
  readResourceByIdAsync,
  ResourceLoadingKey,
  upsertResourceAsync
} from './resourceSlice'
import ResourceForm from './ResourceForm'
import {
  PlusIcon
} from "@heroicons/react/solid";
import ResourceCategoryForm from './ResourceCategoryForm'
import ResourcesFilters from './ResourcesFilters'
import ResourceItem from './ResourceItem'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import ConfirmPurchaseForm from './ConfirmPurchaseForm'
import { useCourseModules } from '../course/courseSlice'
import MarkdownPreview from '../../components/general/MarkdownPreview'
import Btn from './../../components/general/Btn'
import { ArrowSmUpIcon, ArrowSmDownIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { useDebounce } from '../../helpers/index';
import { MainLoader } from '../../components/Loaders/MainLoader'

const Resources = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => state.auth)
  const { 
    resources, 
    loading,
    loadingKeys: resourcesLoadingKeys,
  } = useSelector((state) => state.resource)

  const location = useLocation()
  const locationSearch = new URLSearchParams(location.search)
  const resourceIdFromQuery = locationSearch.get('id')
  // can also have query string with category ids, e.g. ?categories=1&categories=2
  const categoryIdsFromQuery = locationSearch.getAll('categories').map(Number)
  const typeFromQuery = locationSearch.get('type') || 'all'

  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [viewingResource, setViewingResource] = useState(null)
  const [filters, setFilters] = useState({ type: typeFromQuery, categories: categoryIdsFromQuery })
  const [search, setSearch] = useState('')
  const { modules, refreshModules } = useCourseModules()

  const debouncedSearchValue = useDebounce(search, 500);

  useEffect(() => {
    // initial load is either with the resource id from the query string or all resources
    // and then if the user changes the filters or search we load all resources with the new filters
    if (isInitialLoad) {
      dispatch(readManyResourceCategoriesAsync())
      if (resourceIdFromQuery) dispatch(readResourceByIdAsync(resourceIdFromQuery))
      else dispatch(readManyResourcesAsync({ filters, search: debouncedSearchValue, itemsPerPage: 1000 }))
      setIsInitialLoad(false)
    } else dispatch(readManyResourcesAsync({ filters, search: debouncedSearchValue, itemsPerPage: 1000 }))

    // purposefully exclude isInitialLoad from the dependency array
    // otherwise the readResourceByIdAsync action will be overriden by the readManyResourcesAsync action
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, resourceIdFromQuery, filters, debouncedSearchValue])

  // modal
  const [resourceCategoryModalOpen, setResourceCategoryModalOpen] = useState(false)
  const [resourceModalOpen, setResourceModalOpen] = useState(false)
  const [editingResource, setEditingResource] = useState({})
  const [editingResourceCategory, setEditingResourceCategory] = useState({})
  const [confirmPurchaseModalOpen, setConfirmPurchaseModalOpen] = useState(false)
  const [showFilterOptions, setShowFilterOptions] = useState(false)

  const onClickForSaleResource = (resource) => {
    const isResourceOwned = getIsResourceOwned(resource, currentUser)
    const isLink = resource.type === 'Link'
    const isCourse = resource.type === 'Course'
    const isArticle = resource.type === 'Article'
    const isVideo = resource.type === 'Video'

    if (isResourceOwned && isLink) return window.open(resource.url, '_blank')
    if (isResourceOwned && isCourse) {
      // TODO: load modules on page load if not in state (redirect won't work if you haven't visited the course page yet)
      // TODO: reload modules after purchase (redirect won't work if you just purchased the course)
      const url = getRedirectLinkForCourseResource(resource, modules)
      return url ? window.open(url, '_blank') : null
    }
    if (isResourceOwned && (isArticle || isVideo)) return setViewingResource(resource)

    if (!isResourceOwned) {
      if (resource.purchaseUrl) return window.open(resource.purchaseUrl, '_blank')

      setEditingResource(resource)
      setConfirmPurchaseModalOpen(true)
    }
  }

  const onChangeFilters = (newFilters) => {
    const newCategoryIds = newFilters.categories
    const newType = newFilters.type
    // set the query string
    const newSearch = new URLSearchParams()
    newCategoryIds.forEach((id) => newSearch.append('categories', id))
    newSearch.set('type', newType)
    // update the url
    window.history.pushState({}, '', `${location.pathname}?${newSearch}`)
    setFilters(newFilters)
  }

  const onPaymentSuccess = async () => {
    await dispatch(readManyResourcesAsync({ filters, search: debouncedSearchValue, itemsPerPage: 1000 }))
    await refreshModules()
  }

  const onClickResource = (resource) => {
    if (resource.visibility === 'For Sale') onClickForSaleResource(resource)
    else if (resource.type === 'Link') window.open(resource.url, '_blank')
    else if (resource.type === 'Article' || resource.type === 'Video') setViewingResource(resource)
  }

  const onClickResourceEdit = (resource) => {
    setEditingResource(resource)
    setResourceModalOpen(true)
  }

  return (
    <>
      <div className="pt-7.5 md:pt-9.5 pb-6 md:pb-12">
        <div className="container pb-9 md:pb-9.5">
          <div className="flex items-center gap-6">
            <h1 className="text-lg md:text-xl leading-38 font-semibold tracking-1 font-base">
              Resources
            </h1>
            {'admin' === currentUser?.role && (
              <div className="ml-auto">
                <Btn
                  onClick={() => {
                    setEditingResource(null)
                    setResourceModalOpen(true)
                  }}
                  row={true}
                  disabled={loading && resourcesLoadingKeys.includes(ResourceLoadingKey.resources)}
                >
                  <div className="w-6 h-6 flex content-center justify-center relative">
                      <div className="bg-white bg-opacity-30 h-full w-full absolute z-10 rounded change-background-color-plus"></div>
                      <PlusIcon className="w-4 h-4 mt-1 z-20 fill-white" />
                  </div>
                  <span>Add New Resource</span>
                </Btn>
              </div>
            )}
          </div>
        </div>
        <div className="container">
          <div className="flex flex-col md:flex-row gap-10 md:gap-4 xl:gap-11">
            <div className="flex-shrink flex-grow order-2 md:order-1">
              {loading && resourcesLoadingKeys.includes(ResourceLoadingKey.resources) ? (
                <MainLoader />
              ) : (
                viewingResource ? (
                  <div>
                    <button
                      className="flex px-4 ml-4 items-center justify-center bg-gray-200 hover:bg-pink-200 text-purple-450 text-xs py-2 rounded-md"
                      onClick={() => setViewingResource(null)}
                    >
                      <ArrowLeftIcon className="h-5 w-5 mr-2" />
                      back to resources
                    </button>
  
                    <div className="px-5" data-color-mode="light">
                      <h1 className="text-2xl text-purple-450 my-6">{viewingResource.title}</h1>
                      <MarkdownPreview markdown={viewingResource.content} />
                    </div>
                  </div>
                ) : !resources?.length ? (
                  <p className="text-base leading-24 font-medium font-base">No resources found.</p>
                ) : (
                  <div className="grid sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4">
                    {resources.map((resource) => (
                      <div key={resource.id}>
                        {resource.visibility === 'For Sale' ||
                        resource.type === 'Link' ||
                        resource.type === 'Article' ||
                        resource.type === 'Video' ? (
                          <ResourceItem
                            resource={resource}
                            currentUser={currentUser}
                            onClick={() => onClickResource(resource)}
                            onClickResourceEdit={() => onClickResourceEdit(resource)}
                          />
                        ) : null}
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
            <div className="flex-shrink-0 order-1 md:order-2 w-full md:w-72 lg:w-80">
              <div
                className={classNames([
                  '',
                  {
                    'max-h-96 md:max-h-full overflow-hidden md:overflow-visible px-4 md:px-0 -mx-4 md:mx-0':
                      !showFilterOptions
                  }
                ])}
              >
                <ResourcesFilters
                  setResourceCategoryModalOpen={setResourceCategoryModalOpen}
                  setEditingResourceCategory={setEditingResourceCategory}
                  viewingResource={viewingResource}
                  filters={filters}
                  categoriesLoading={loading && resourcesLoadingKeys.includes(ResourceLoadingKey.categories)}
                  resourcesLoading={loading && resourcesLoadingKeys.includes(ResourceLoadingKey.resources)}
                  onChangeFilters={onChangeFilters}
                  search={search}
                  setSearch={setSearch}
                />
                <div className="pt-6 mb-6 md:mb-0">
                  <Btn
                    onClick={() => window.open('https://wkf.ms/3K6942i', '_blank').focus()}
                    full={true}
                  >
                    Recommend A Resource/ Service Here
                  </Btn>
                </div>
              </div>
              <div className="md:hidden relative">
                <span
                  className={classNames([
                    'absolute bottom-1.25 left-0 w-full h-25 bg-gradient-to-t from-white-200 to-transparent z-0',
                    {
                      hidden: showFilterOptions
                    }
                  ])}
                />
                <div className="relative z-1">
                  <Btn
                    onClick={() => {
                      document.getElementById('main').scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                      })
                      setShowFilterOptions(!showFilterOptions)
                    }}
                    variant="secondary"
                    outline={true}
                    row={true}
                    full={true}
                  >
                    <span>See {showFilterOptions ? 'less' : 'more'} categories</span>

                    {showFilterOptions ? (
                      <ArrowSmUpIcon className="h-4 w-4" />
                    ) : (
                      <ArrowSmDownIcon className="h-4 w-4" />
                    )}
                  </Btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormModal
        open={resourceModalOpen}
        setOpen={setResourceModalOpen}
        Form={ResourceForm}
        onSubmit={async (resource) => {
          await dispatch(upsertResourceAsync({ resource }))
          await dispatch(readManyResourcesAsync({ filters, search: debouncedSearchValue, itemsPerPage: 1000 }))
        }}
        editingItem={editingResource}
        setEditingItem={setEditingResource}
        deleteAction={async () => {
          await dispatch(deleteResourceAsync({ resource: editingResource }))
        }}
      />

      <FormModal
        open={resourceCategoryModalOpen}
        setOpen={setResourceCategoryModalOpen}
        Form={ResourceCategoryForm}
        editingItem={editingResourceCategory}
        setEditingItem={setEditingResourceCategory}
        deleteAction={async () => {
          await dispatch(deleteResourceCategoryAsync({ category: editingResourceCategory }))
        }}
      />

      <FormModal
        open={confirmPurchaseModalOpen}
        setOpen={setConfirmPurchaseModalOpen}
        Form={ConfirmPurchaseForm}
        onPaymentSuccess={onPaymentSuccess}
        editingItem={editingResource}
        setEditingItem={setEditingResource}
        deleteAction={async () => {}}
      />
    </>
  )
}

export function getIsResourceOwned(resource, currentUser) {
  const isAdmin = currentUser?.role === 'admin'
  // user could have bought the resource on the site or through an external link which updated their tags
  const userOwnsResource = currentUser?.Resources.map((r) => r.id).includes(resource.id)
  const userHasResourceTag = resource.Tags.some((tag) =>
    currentUser?.Tags.map((t) => t.id).includes(tag.id)
  )
  return isAdmin || userOwnsResource || userHasResourceTag
}
function getRedirectLinkForCourseResource(resource, modules) {
  // get the first lesson of the first modules that matches the resource tags
  const resourceTagIds = resource.Tags.map((tag) => tag.id)
  const module = modules.find((module) =>
    module.Tags.some((tag) => resourceTagIds.includes(tag.id))
  )
  if (!module) return

  const lesson = module.Lessons[0]
  if (!lesson) return

  return `/course/${module.id}/${lesson.id}`
}

export default Resources
