import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../../components/general/PrimaryButton'
import FormField from '../../utils/FormField'
import { clearError, upsertProductAsync } from './powerProfitToolSlice'
import ReactTooltip from 'react-tooltip'
import { LockClosedIcon } from '@heroicons/react/solid'
import { BASIC_ROLE } from '../../constants'
import { ADDITIONAL_FEES, AMAZON_RETAIL, COST_PER_UNIT, DOLLAR, PERCENTAGE, SHIPPING_COST, UNITS } from './consts'

const ProductForm = ({ setState, setOpen, editingItem, setEditingItem }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({})
  const { error } = useSelector((state) => state.powerProfitTool)

  const { currentUser } = useSelector((state) => state.auth)
  const isBasicRole = currentUser?.role === BASIC_ROLE
  const [typeSwitcher, setTypeAdditionalFees] = useState({
    additionalFees: editingItem?.typeAdditionalFees || PERCENTAGE
  });

  // useEffect(() => {
  //   const units = formValues[UNITS] || 0;
  //   const costPerUnit = formValues[COST_PER_UNIT] || 0;
  //   setFormValues((prevState) => ({ ...prevState, [SHIPPING_COST]: Number(units) * Number(costPerUnit) }))
  // }, [formValues[UNITS], formValues[COST_PER_UNIT]])

  const changeValueAdditionalFees = (type) => {
    if (type === DOLLAR) {
      setFormValues((prevState) => ({ ...prevState, [ADDITIONAL_FEES]: ((Number(formValues[AMAZON_RETAIL]) * Number(formValues[ADDITIONAL_FEES])) / 100).toFixed(2) }))
    }
    if (type === PERCENTAGE) {
      const percentage = (Number(formValues[ADDITIONAL_FEES]) / Number(formValues[AMAZON_RETAIL])) * 100;
      const fixedPercentage = Number(percentage.toFixed(2));
      const value = fixedPercentage >= 49.99 ? 50 : fixedPercentage;
      setFormValues((prevState) => ({ ...prevState, [ADDITIONAL_FEES]: value }))
    }
  }

  const getAdditionalFeesValue = (editingItem) => {
    if (!editingItem) return 25;
    if (editingItem?.additionalFees && typeSwitcher.additionalFees === DOLLAR) {
      return ((Number(editingItem[AMAZON_RETAIL]) * Number(editingItem[ADDITIONAL_FEES])) / 100).toFixed(2)
    }
    if (editingItem?.additionalFees && typeSwitcher.additionalFees === PERCENTAGE) return editingItem?.additionalFees
    return 25;
  }

  useEffect(() => {
    const additionalFields = !isBasicRole ? {
      additionalFees: getAdditionalFeesValue(editingItem)  
    } : {}
    if (editingItem) {
      setFormValues({ 
          ...editingItem, 
          ...additionalFields 
      })
    } else {
      setFormValues({ position: 0, ...additionalFields })
    }
  }, [editingItem])

  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    const onBasic = !!(isBasicRole && name === ADDITIONAL_FEES);
    const additionalFeesIncorrect = !!(
      name === ADDITIONAL_FEES && 
      typeSwitcher.additionalFees === PERCENTAGE && 
      (Number(value) < 0 || Number(value) > 50)
    ) || !!(
      name === ADDITIONAL_FEES &&
      typeSwitcher.additionalFees === DOLLAR &&
      (Number(value) < 0 || Number(value) > Number(formValues[AMAZON_RETAIL]) * 0.5)
    );
    // const additionalFeesIncorrect = !!(
    //   name === ADDITIONAL_FEES && 
    //   (typeSwitcher.additionalFees === PERCENTAGE || typeSwitcher.additionalFees === DOLLAR) && 
    //   (Number(value) < 0)
    // );
    if (onBasic || additionalFeesIncorrect) return
    error && error[name] && dispatch(clearError({ field: name }))
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <div>
      <h3 className="text-2xl">
        {formValues?.id ? 'Edit product: ' + formValues?.title : 'Add a new product'}
      </h3>

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          const productValues = formValues ? { ...formValues } : {}
          if (typeSwitcher.additionalFees === DOLLAR) {
            productValues[ADDITIONAL_FEES] = String(((Number(formValues[ADDITIONAL_FEES]) / Number(formValues[AMAZON_RETAIL])) * 100).toFixed(2))
          }
          await dispatch(upsertProductAsync({ product: productValues, typeAdditionalFees: typeSwitcher.additionalFees }))
          setOpen(false)
          setEditingItem?.(null)
        }}
      >
        <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6 mt-auto">
            <div className="mt-1">
              <FormField
                label="Title"
                field="title"
                error={error && error['title']}
                onChange={onChange}
                state={formValues}
              />
            </div>
          </div>

          <div className="sm:col-span-6 mt-auto">
            <div className="mt-1">
              <FormField
                type="textarea"
                label="Description"
                field="description"
                required={false}
                error={error && error['description']}
                onChange={onChange}
                state={formValues}
              />
            </div>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3 mt-auto">
            <div className="mt-1">
              <FormField
                label="Number of Units"
                field={UNITS}
                error={error && error[UNITS]}
                onChange={onChange}
                state={formValues}
                tooltip="units-tooltip"
                type="number"
              />
            </div>
          </div>

          <div className="sm:col-span-3 mt-auto">
            <div className="mt-1">
              <FormField
                label="Manufacturing Cost Per Unit"
                inputLabel={"$"}
                field={COST_PER_UNIT}
                error={error && error[COST_PER_UNIT]}
                onChange={onChange}
                state={formValues}
                tooltip="cost-per-unit-tooltip"
                type="number"
              />
            </div>
          </div>

          <div className="sm:col-span-3 mt-auto">
            <div className="mt-1">
              <FormField
                label="Shipping and Customs Per Unit"
                inputLabel={"$"}
                field={SHIPPING_COST}
                // enableMath={true}
                // onUpdateMathClick={() => {
                //   if (formValues[UNITS] && formValues[COST_PER_UNIT]) {
                //     setFormValues((prevState) => ({ ...prevState, [SHIPPING_COST]: Number(formValues[UNITS]) * Number(formValues[COST_PER_UNIT]) }))
                //   }
                // }}
                error={error && error[SHIPPING_COST]}
                onChange={onChange}
                state={formValues}
                tooltip="shipping-cost-tooltip"
                type="number"
              />
            </div>
          </div>

          <div className="sm:col-span-3 mt-auto">
            <div className="mt-1">
              <FormField
                label="Amazon Fulfillment Fees Per Unit"
                field="amazonFees"
                inputLabel={"$"}
                error={error && error['amazonFees']}
                onChange={onChange}
                state={formValues}
                tooltip="amazon-fees-tooltip"
                type="number"
              />
            </div>
          </div>

          <div className="sm:col-span-3 mt-auto">
            <div className="mt-1">
              <FormField
                label="Amazon Retail Price"
                inputLabel={"$"}
                field={AMAZON_RETAIL}
                error={error && error[AMAZON_RETAIL]}
                onChange={onChange}
                state={formValues}
                tooltip="amazon-retail-tooltip"
                type="number"
              />
            </div>
          </div>

          <>
            {isBasicRole && (<ReactTooltip id={"additional-fees-tooltip-link"}>Click here to unlock this feature</ReactTooltip>)}
            <div className="sm:col-span-3 mt-auto relative">
              {isBasicRole && (
                <>
                  <a
                    href={"https://rainmakerfamily.com/mastermind/?el=rmapp"}
                    target="_blank"
                    data-for={"additional-fees-tooltip-link"}
                    data-tip
                    rel="noopener noreferrer"
                    className="absolute top-0 left-0 w-full h-full cursor-pointer z-20"
                  />
                  <LockClosedIcon className="w-28 h-28 absolute z-10 text-purple-450 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                </>
              )}
              <div className="mt-1">
                <FormField
                  // label="Total Additional Fees & Costs"
                  label={<>Total Additional Fees<br/> & Costs</>}
                  inputLabel={typeSwitcher.additionalFees === PERCENTAGE ? "%" : "$"}
                  field={ADDITIONAL_FEES}
                  isBasicRole={isBasicRole}
                  enableSwitcher={isBasicRole ? false : true}
                  disabled={isBasicRole}
                  required={false}
                  // enableMath={isBasicRole ? false : true}
                  // onUpdateMathClick={() => {
                  //   if (formValues[AMAZON_RETAIL]) {
                  //     setFormValues((prevState) => ({ ...prevState, [ADDITIONAL_FEES]: Number(formValues[AMAZON_RETAIL]) * Number(formValues[ADDITIONAL_FEES]) }))
                  //   }
                  // }}
                  typeSwitcher={typeSwitcher.additionalFees}
                  toggleSwitch={() => {
                    const newType = typeSwitcher.additionalFees === PERCENTAGE ? DOLLAR : PERCENTAGE;
                    setTypeAdditionalFees({
                      ...typeSwitcher,
                      additionalFees: newType
                    });
                    changeValueAdditionalFees(newType);
                  }}
                  // maxValueForTypeDollar={formValues[AMAZON_RETAIL]}
                  error={error && error[ADDITIONAL_FEES]}
                  onChange={onChange}
                  state={formValues}
                  tooltip="additional-fees-tooltip"
                  type="number"
                />
              </div>
            </div>
          </>
          
        </div>

        <div className="mt-16 py-3 flex flex-row space-x-4 sm:justify-between">
          <div className="w-1/3">
            {editingItem?.id && (
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1.5 sm:py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                onClick={() => setState('delete')}
              >
                Delete
              </button>
            )}
          </div>
          <div className="w-2/3 flex flex-1 space-x-4 sm:flex-grow-0 items-start">
            <PrimaryButton extraClasses="px-8" label="Save" type="submit" />

            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-1.5 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450"
              onClick={() => {
                setOpen(false);
                setEditingItem?.(null);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ProductForm
