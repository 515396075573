import { AdditionalFeesSwitcher } from './AdditionalFeesSwitcher'
import { PERCENTAGE, AMAZON_RETAIL } from './consts'
import Range from '../../components/general/Range'

const AdditionalFeesEditor = ({
  product,
  type,
  onTypeToggle,
  percentage,
  onPercentageChange,
  dollarValue
}) => {
  return (
    <div className="flex justify-center flex-col items-center">
      <AdditionalFeesSwitcher typeSwitcher={type} onChangeType={onTypeToggle} />
      {type === PERCENTAGE ? (
        <Range
          min={0}
          max={50}
          value={percentage || 0}
          fixedPoints={2}
          onChange={onPercentageChange}
          suffix="%"
          minLabel={'0%'}
          maxLabel={'50%'}
        />
      ) : (
        <Range
          min={0}
          max={Number((Number(product[AMAZON_RETAIL]) / 2).toFixed(2))}
          fixedPoints={2}
          value={dollarValue || 0}
          prefix={'$'}
          minLabel="$0"
          maxLabel={'$' + (Number(product[AMAZON_RETAIL]) / 2).toFixed(2)}
          onChange={(v) => {
            const rawPercentage = (v / Number(product[AMAZON_RETAIL])) * 100;
            const fixedPercentage = Number(rawPercentage.toFixed(2));
            const value = fixedPercentage >= 49.99 ? 50 : fixedPercentage;
            onPercentageChange(value);
          }}
        />
      )}
      {percentage < 25 ? (
        <div className="text-sm mt-4 mb-4 text-gray-500">
            We suggest a minimum of 25% of your Amazon retail price.
        </div>
      ) : null}
    </div>
  )
}

export default AdditionalFeesEditor
