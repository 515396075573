import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const uploadFileAsync = createAsyncThunk('uploader/upload', async ({ id }) => {
  const formData = new FormData()
  var image = document.getElementById(id)
  formData.append('image', image.files[0])
  const response = await axios.post('/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return { ...response.data, id }
})

export const uploaderSlice = createSlice({
  name: 'uploader',
  initialState: {
    loading: false,
    error: null,
    latestUploadedFileUrl: null,
    fileId: null
  },
  reducers: {
    deleteFile: (state) => {
      state.latestUploadedFileUrl = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFileAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(uploadFileAsync.fulfilled, (state, action) => {
        state.loading = false
        state.latestUploadedFileUrl = action.payload.latestUploadedFileUrl
        state.fileId = action.payload.id
      })
      .addCase(uploadFileAsync.rejected, (state) => {
        state.loading = false
        state.error = 'Error uploading file'
      })
  }
})

export const { deleteFile } = uploaderSlice.actions
export default uploaderSlice.reducer
