import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { upsertListAsync } from '../moveTheNeedleSlice'

const NewListForm = ({ setAddingNewList, lists, userId }) => {
  const dispatch = useDispatch()
  const [newList, setNewList] = useState({})

  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    setNewList((prevState) => ({ ...prevState, [name]: value }))
  }

  const maxPosition = () => {
    let maxPosition = 0
    lists.forEach((list) => {
      if (list.position > maxPosition) {
        maxPosition = list.position
      }
    })
    return maxPosition + 1
  }

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault()
        await dispatch(
          upsertListAsync({
            list: {
              name: newList.name,
              position: maxPosition(),
              userId
            }
          })
        )
        setAddingNewList(null)
      }}
      className="mx-auto"
      style={{ maxWidth: 250 }}
    >
      <input
        name="name"
        onChange={onChange}
        className="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none mb-3"
        placeholder="List Name"
      />
      <div className="flex justify-between">
        <button
          className="uppercase py-1.5 px-3 hover:bg-gray-100 rounded-md"
          type="button"
          onClick={() => setAddingNewList(null)}
        >
          Cancel
        </button>
        <button
          className="uppercase py-1.5 px-3 bg-purple-450 hover:opacity-80 text-white rounded-md transition-opacity"
          type="submit"
        >
          Add
        </button>
      </div>
    </form>
  )
}

export default NewListForm
