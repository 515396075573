import React from 'react'
import SecondaryButton from '../../components/general/SecondaryButton'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'

const ProductQualifierQuestion = ({
  question,
  id,
  name,
  onChange,
  onLearnMore = () => {},
  componentUnderQuestion
}) => {
  return (
    <fieldset>
      <div className="flex items-center">
        <legend className="text-md font-medium">{question}</legend>
        <LearnMoreButton onClick={onLearnMore} />
      </div>
      {componentUnderQuestion}
      <div className="mt-2 space-y-4">
        <div className="flex items-center">
          <input
            id={id + '-no'}
            name={name}
            type="radio"
            className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300"
            value="0"
            onChange={onChange}
            required
          />
          <label htmlFor={id + '-no'} className="ml-3 block font-medium text-md">
            No
          </label>
        </div>
        <div className="flex items-center">
          <input
            id={id + '-yes'}
            name={name}
            type="radio"
            className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300"
            value="1"
            onChange={onChange}
          />
          <label htmlFor={id + '-yes'} className="ml-3 block text-md font-medium">
            Yes
          </label>
        </div>
      </div>
    </fieldset>
  )
}

export default ProductQualifierQuestion

export const LearnMoreButton = ({ onClick }) => {
  return (
    <SecondaryButton
      extraClasses="ml-2 text-gray-400"
      size="md"
      label={
        <span className="flex items-center" onClick={onClick}>
          <QuestionMarkCircleIcon className="h-5 w-5 mr-2" /> Learn More
        </span>
      }
    />
  )
}
