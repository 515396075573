import React from 'react'
import Avatar from '../../../components/general/Avatar'
import * as moment from 'moment'
import Mentions from './Mentions'

const Comment = ({ activity }) => {
  return (
    <>
      {activity ? (
        <div className="flex space-x-4 mt-6">
          <Avatar user={activity.User} />
          <div className="flex flex-col mt-2 flex-1">
            <p className="text-sm mb-2">{activity.User?.fullName}</p>
            <div
              className={`border px-3 pb-3 rounded-md text-sm relative ${
                activity.TaggedUsers.length ? 'pt-9' : 'pt-3'
              }`}
            >
              <Mentions mentionedUsers={activity.TaggedUsers} committed={true} />
              {activity.comment || 'the comment'}
            </div>
            <span className="text-xs text-gray-600 mt-2">
              {moment(activity.createdAt).format('MMM D, YYYY [at] LT')}
            </span>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Comment
