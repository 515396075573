import React from 'react'
import CommentBox from './CommentBox'
import Comment from './Comment'

const Activity = ({ card }) => {
  return (
    <div>
      <CommentBox />

      {card?.Activities?.map((activity) => (
        <Comment key={activity.id} activity={activity} />
      ))}
    </div>
  )
}

export default Activity
