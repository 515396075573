import { useState } from "react";
import { MenuAlt4Icon } from '@heroicons/react/outline'
import CourseCardItem from "./CourseCardItem"

const AllCoursesList = ({ courses, isReorderingMode, onDragEnd, setTemporaryItems, isAdmin, handleCourseEdit }) => {
    const [draggedItem, setDragedItem] = useState();
    return (
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 pb-9 md:pb-9.5">
            {courses.map((course) => (
                isReorderingMode ? <div 
                    key={`course-${course?.id}`}
                    className="relative group"
                    draggable
                    onDragStart={() => setDragedItem(course)}
                    onDragOver={(e) => {
                        e.preventDefault();
                        if (!draggedItem || draggedItem === course) {
                            return;
                        }
                        const currentIndex = courses.indexOf(draggedItem);
                        const targetIndex = courses.indexOf(course);
                        if (currentIndex !== -1 && targetIndex !== -1) {
                            const newItems = [...courses];
                            newItems.splice(currentIndex, 1);
                            newItems.splice(targetIndex, 0, draggedItem);
                            setTemporaryItems(newItems);
                        }
                    }}
                    onDragEnd={() => {
                        onDragEnd(courses);
                        setDragedItem(undefined);
                        setTemporaryItems(undefined);
                    }}
                    >
                        <div className="cursor-move absolute z-10 flex items-center justify-center w-full h-full opacity-0 hover:opacity-100 transition-opacity duration-300 bg-grey-100 bg-opacity-50 hover:bg-opacity-75 rounded-lg">
                            <MenuAlt4Icon className="w-5 h-5 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                        </div>
                    <CourseCardItem courseItem={course} isAdmin={isAdmin} onClickEdit={() => handleCourseEdit(course)} isReorderingMode={isReorderingMode} />
                </div>
                :
                <CourseCardItem key={`course-${course?.id}`} isAdmin={isAdmin} courseItem={course} onClickEdit={() => handleCourseEdit(course)} isReorderingMode={isReorderingMode} />
            ))}
        </div>
    )
}

export default AllCoursesList
