import Btn from './../../components/general/Btn'
import Breadcrumbs from "./../../components/courses/Breadcrumbs"
import { Link } from "react-router-dom"

const ModuleLessonCard = ({ buttonTitle, title, breadcrumbs, path }) => {
  return (
    <Link to={path} role="link" className="data-item-card bg-white p-4 shadow-md rounded-lg flex items-start md:items-center justify-between border border-purple-200 flex-col md:flex-row gap-4 md:gap-6">
      <div className='flex flex-col gap-1'>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <p className="text-md leading-24 xl:text-md-2 font-title text-dark transition-colors group-hover:text-secondary">{title}</p>
      </div>
      <Btn row={true} href={path} className="w-full md:w-auto whitespace-nowrap">
        <span className='uppercase'>{buttonTitle}</span>
      </Btn>
    </Link>
  )
}

export default ModuleLessonCard
