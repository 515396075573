import { useEffect } from 'react'
import rg4js from 'raygun4js'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const RoutesListener = (props) => {
  const { currentUser } = useSelector((state) => state.auth)

  const history = useHistory()
  useEffect(() => {
    rg4js('apiKey', 'wfhxfom679Y2FcDFioE0w') // the RM Raygun API key
    rg4js('enableCrashReporting', true)
    rg4js('enablePulse', true)
    rg4js('options', {
      ignoreAjaxAbort: true,
      debugMode: false,
      excludedHostnames: ['localhost', '127.0.0.1'],
      excludedUserAgents: ['PhantomJS', 'MSIE']
    })

    if (currentUser) {
      rg4js('setUser', {
        identifier: currentUser.id,
        isAnonymous: false,
        email: currentUser.email,
        fullName: currentUser.fullName
      })
    }

    // To start listening for location changes...
    const unlisten = history.listen((location, action) => {
      // The current location changed.
      rg4js('trackEvent', { type: 'pageView', path: location?.pathname })
    })

    return () => {
      unlisten()
    }
  }, [currentUser, history])
  return props.children
}

export default RoutesListener
