import React, { Fragment, useState, useEffect, Suspense } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  XCircleIcon,
  ArrowRightIcon,
  ClipboardCheckIcon,
  DocumentIcon
} from '@heroicons/react/outline'
import {
  deleteAttachmentAsync,
  deleteCardAsync,
  selectActiveCard,
  setActiveCardId,
  upsertCardAsync,
  upsertCheckListAsync
} from '../moveTheNeedleSlice'
import { useDispatch, useSelector } from 'react-redux'
import NewLabelForm from '../label/NewLabelForm'
import LabelList from '../label/LabelList'
import AttachmentUploader from '../../uploader/AttachmentUploader'
import Activity from '../activity/Activity'
import CheckList from '../checklist/CheckList'
import { useLocation } from 'react-router-dom'
import SecondaryButton from '../../../components/general/SecondaryButton'
import { rehypeRewrite } from '../../../components/general/MarkdownPreview'
import LoadingSpinner from '../../../components/general/LoadingSpinner'

const MDEditor = React.lazy(() => import('@uiw/react-md-editor'))

const CardModal = ({ open, setOpen, activeList: list, setActiveList }) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const { labels } = useSelector((state) => state.moveTheNeedle)
  const { currentUser } = useSelector((state) => state.auth)
  const activeCard = useSelector(selectActiveCard)

  const [formValues, setFormValues] = useState(activeCard)

  const [isShowingDescriptionTextarea, setIsShowingDescriptionTextarea] = useState(false)
  const [isViewingLabels, setIsViewingLabels] = useState(false)
  const [editingLabel, setEditingLabel] = useState(false)
  const [attachmentShowing, setAttachmentShowing] = useState(false)
  const [userId, setUserId] = useState()
  const [deleteCardConfirmShowing, setDeleteCardConfirmShowing] = useState(null)

  useEffect(() => {
    setFormValues(activeCard)
  }, [activeCard])

  useEffect(() => {
    const uid = parseInt(location.pathname.replace('/move-the-needle/', ''))
    setUserId(uid)
  }, [location.pathname])

  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const onCloseHandler = () => {
    setIsShowingDescriptionTextarea(false)
    setOpen(false)
    dispatch(setActiveCardId({ activeCardId: null }))
    setActiveList(null)
    setIsViewingLabels(false)
    setAttachmentShowing(false)

    if (formValues.id) {
      dispatch(upsertCardAsync({ card: formValues }))
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        data-testid="card-dialog"
        onClose={onCloseHandler}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={onCloseHandler}
                >
                  <span className="sr-only">Close</span>
                  <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div onClick={() => setIsShowingDescriptionTextarea(false)}>
                <div className="">
                  <Dialog.Title as="h3" className="text-sm leading-6 text-gray-500">
                    <div className="flex space-x-1">
                      {activeCard?.Labels?.map((label) => (
                        <span
                          key={label.id}
                          className={`inline-flex items-center px-6 py-1.5 rounded text-xs bg-${label.color}`}
                        ></span>
                      ))}
                    </div>

                    <div>In List: {list?.name}</div>
                  </Dialog.Title>

                  <div className="mt-6 flex justify-between space-x-12">
                    <div className="w-full mt-2" style={{ width: '70%' }}>
                      <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-purple-450 focus-within:border-purple-450">
                        <label
                          htmlFor="title"
                          className="absolute -top-2 left-2 -mt-px inline-block px-2 bg-white text-xs text-gray-500"
                        >
                          Card Title
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          className="block w-full border-0 p-0  py-2 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                          placeholder="Card title..."
                          value={formValues?.title || ''}
                          onChange={onChange}
                        />
                      </div>
                      <div className="sm:col-span-6 mt-6">
                        <label htmlFor="description" className="block text-lg text-gray-500">
                          Description
                        </label>
                        <div
                          className="mt-1 border-2 rounded-md hover:border-gray-400"
                          onClick={(event) => {
                            // if user clicked on a link, don't show the textarea
                            if (event.target.tagName === 'A') return
                            event.stopPropagation()
                            setIsShowingDescriptionTextarea(true)
                          }}
                          data-color-mode="light"
                        >
                          <Suspense fallback={<LoadingSpinner />}>
                            <MDEditor
                              id="description"
                              name="description"
                              rows={3}
                              value={formValues?.description || ''}
                              onChange={(value) =>
                                setFormValues((prevState) => ({
                                  ...prevState,
                                  description: value
                                }))
                              }
                              onBlur={() => setIsShowingDescriptionTextarea(false)}
                              hideToolbar={true}
                              preview={isShowingDescriptionTextarea ? 'edit' : 'preview'}
                              previewOptions={{ rehypeRewrite }}
                              autoFocus={true}
                              className="px-0 text-lg"
                              visibleDragbar={false}
                            />
                          </Suspense>
                        </div>
                      </div>
                      <div className="mt-6 text-xl">Attachments</div>

                      <ul>
                        {activeCard?.Attachments?.map((attachment) => (
                          <li className="py-2" key={attachment.id}>
                            <div className="flex border-2 rounded-md">
                              <img width="170" src={attachment.fileUrl} alt="attachment" />

                              <div className="flex flex-col flex-1">
                                <div className="mt-2 mb-6 px-6">
                                  <div>{attachment.fileName}</div>
                                </div>
                                <div className="flex justify-between px-6">
                                  <button
                                    onClick={() => {
                                      dispatch(
                                        upsertCardAsync({
                                          card: {
                                            ...formValues,
                                            coverPhotoUrl:
                                              activeCard?.coverPhotoUrl === attachment.fileUrl
                                                ? null
                                                : attachment.fileUrl
                                          }
                                        })
                                      )
                                    }}
                                    className="uppercase border-2 px-4 py-2 rounded-md"
                                  >
                                    {activeCard?.coverPhotoUrl === attachment.fileUrl
                                      ? 'Remove Cover'
                                      : 'Make Cover'}
                                  </button>
                                  <button
                                    onClick={() => {
                                      if (activeCard?.coverPhotoUrl === attachment.fileUrl) {
                                        dispatch(
                                          upsertCardAsync({
                                            card: {
                                              ...formValues,
                                              coverPhotoUrl: null
                                            }
                                          })
                                        )
                                      }
                                      dispatch(
                                        deleteAttachmentAsync({
                                          id: attachment.id
                                        })
                                      )
                                    }}
                                    className="uppercase bg-gray-300 px-4 py-2 rounded-md"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>

                      <div className="mt-6 text-xl">Checklists</div>
                      {activeCard?.CheckLists?.map((checkList) => (
                        <CheckList key={checkList.id} checkList={checkList} />
                      ))}

                      <div className="mt-6 mb-3 text-xl">Activity</div>
                      <Activity card={activeCard} />
                    </div>

                    <div className="" style={{ width: '30%' }}>
                      <div className="flex flex-col space-y-2">
                        <h4 className="uppercase text-gray-500 text-sm">Add to Card</h4>
                        <button
                          onClick={() =>
                            dispatch(
                              upsertCheckListAsync({
                                checkList: {}
                              })
                            )
                          }
                          className="flex text-left uppercase text-sm text-gray-800 bg-gray-200 rounded-md py-1 px-3 hover:bg-gray-300 transition ease-in"
                        >
                          <ClipboardCheckIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                          Checklist
                        </button>
                        <button
                          onClick={() => setAttachmentShowing(true)}
                          className="flex text-left uppercase text-sm text-gray-800 bg-gray-200 rounded-md py-1 px-3 hover:bg-gray-300 transition ease-in"
                        >
                          <DocumentIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                          Attachments
                        </button>
                        {attachmentShowing && (
                          <AttachmentUploader
                            CardId={activeCard.id}
                            field="attachmentUrl"
                            setAttachmentShowing={setAttachmentShowing}
                          />
                        )}
                      </div>
                      <div className="mt-12 flex flex-col space-y-2">
                        <h4 className="uppercase text-gray-500 text-sm">Actions</h4>
                        <button
                          className="flex text-left uppercase text-sm text-gray-800 bg-gray-200 rounded-md py-1 px-3 hover:bg-gray-300 transition ease-in"
                          onClick={() => setIsViewingLabels(!isViewingLabels)}
                        >
                          <ArrowRightIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                          Labels
                        </button>
                        {isViewingLabels && (
                          <div>
                            {editingLabel ? (
                              <NewLabelForm
                                card={activeCard}
                                editingLabel={editingLabel}
                                setEditingLabel={setEditingLabel}
                                userId={userId}
                              />
                            ) : (
                              <LabelList
                                labels={labels}
                                card={activeCard}
                                setEditingLabel={setEditingLabel}
                              />
                            )}
                          </div>
                        )}

                        {'admin' === currentUser?.role && !deleteCardConfirmShowing && (
                          <button
                            className="bg-red-600 text-white rounded-md py-1 uppercase"
                            onClick={() => setDeleteCardConfirmShowing(true)}
                          >
                            Delete card
                          </button>
                        )}
                        {deleteCardConfirmShowing && (
                          <div>
                            <span>Are you sure you want to delete this card?</span>
                            <div className="flex mt-1">
                              <button
                                type="button"
                                className="flex justify-center py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-450 hover:bg-purple-450 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450 px-3"
                                onClick={() => {
                                  setOpen(false)
                                  dispatch(deleteCardAsync({ id: activeCard.id }))
                                }}
                              >
                                yes
                              </button>
                              <SecondaryButton
                                label="cancel"
                                onClick={() => setDeleteCardConfirmShowing(false)}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CardModal
