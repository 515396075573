import { Link } from 'react-router-dom'
import {
  ArrowSmUpIcon
} from "@heroicons/react/outline";

const BoxCardHeader = ({ children, title, link, linkIcon }) => {
  if (children || title || (link || linkIcon)) {
    return (
      <div className="flex flex-wrap gap-2.5 items-center pb-3.5" data-testid="box-header-card">
        {title ? (
          <div className="flex-1 max-w-full overflow-hidden order-first sm:order-none">
            <h4
              title={title}
              className="text-md leading-22 text-dark font-title font-medium tracking-1 truncate"
            >
              {title}
            </h4>
          </div>
        ) : null}
        {children}
        {link || linkIcon ? (
          <div className="flex-shrink-0 flex-grow-0 self-end -mt-2.5 -mr-2.5 order-2 sm:order-none">
            {link && (
              <Link
                to={link}
                className="inline-flex justify-center items-center w-8 h-8 text-dark hover:text-secondary transition-colors"
              >
                <ArrowSmUpIcon className="w-5 h-5 rotate-45 transform-gpu" />
              </Link>)}
              {(!link && linkIcon) && (
                <span className="inline-flex justify-center items-center w-8 h-8 text-dark group-hover:text-secondary transition-colors">
                  <ArrowSmUpIcon className="w-5 h-5 rotate-45 transform-gpu" />
                </span>
              )}
          </div>
        ) : null}
      </div>
    )
  }
  return null
}

export default BoxCardHeader
