import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Avatar from '../../components/general/Avatar'
import FormModal from '../../components/general/FormModal'
import PrimaryButton from '../../components/general/PrimaryButton'
import SecondaryButton from '../../components/general/SecondaryButton'
import { signInAsAsync } from '../auth/authSlice'
import { readManyResourcesAsync } from '../resource/resourceSlice'
import TablePagination from './TablePagination'
import UpdateUsersTagsStatusForm from './UpdateUsersTagsStatusForm'
import UploadCSVForm from './UploadCSVForm'
import UserForm from './UserForm'
import UserManagementOptions from './UserManagementOptions'
import UserSearch from './UserSearch'
import { readManyUsersAsync, deleteUserAsync, UserLoadingKeys } from './userSlice'
import { useDebounce } from '../../helpers/index';
import { MainLoader } from '../../components/Loaders/MainLoader'

const UserManagement = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const userState = useSelector((state) => state.user)
  const { users, totalCount, premiumCount, basicCount, loadingKeys: userLoadingKeys, loading } = userState
  const { currentUser } = useSelector((state) => state.auth)
  const isAdmin = currentUser.role === 'admin'
  const isCoach = currentUser.role === 'coach'

  const [search, setSearch] = useState('')
  const [usersPerPage, setUsersPerPage] = useState(10)
  const [selectedUsers, setSelectedUsers] = useState([])

  const debouncedSearchValue = useDebounce(search, 500);

  const [page, setPage] = useState(1)
  const goToNextPage = () => setPage(page + 1)
  const goToPrevPage = () => {
    if (page > 1) setPage(page - 1)
  }

  useEffect(() => {
    dispatch(readManyUsersAsync({ page, usersPerPage, search: debouncedSearchValue }))
  }, [dispatch, page, usersPerPage, debouncedSearchValue])

  useEffect(() => setPage(1), [debouncedSearchValue])

  useEffect(() => {
    dispatch(readManyResourcesAsync({ filters: {}, search: '', itemsPerPage: 1000 }))
  }, [dispatch])

  // modal
  const [userModalOpen, setUserModalOpen] = useState(false)
  const [editingUser, setEditingUser] = useState({})

  const [uploadCSVModalOpen, setUploadCSVModalOpen] = useState(false)
  const [updateUsersTagsStatusModalOpen, setUpdateUsersTagsStatusModalOpen] = useState(false)

  const onToggleSelectedUser = (userId) => {
    if (selectedUsers.includes(userId))
      setSelectedUsers(selectedUsers.filter((id) => id !== userId))
    else setSelectedUsers([...selectedUsers, userId])
  }

  const onClickSubProblems = () => {
    const filters = { stripeStatus: ['unpaid'] }
    dispatch(readManyUsersAsync({ page, usersPerPage, search: debouncedSearchValue, filters }))
  }

  return (
    <div className="h-screen py-6 flex flex-col">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 w-full">
        <div className="flex justify-between flex-wrap gap-2">
          <h1 className="text-2xl font-semibold text-gray-900">User Dashboard</h1>
          <div className="flex items-center flex-wrap gap-2">
            {isAdmin && (
              <PrimaryButton
                size="sm"
                extraClasses="py-2 px-4 mr-4"
                label="Add new User"
                disabled={loading && userLoadingKeys.includes(UserLoadingKeys.users)}
                onClick={() => setUserModalOpen(true)}
              />
            )}
            <UserSearch search={search} setSearch={setSearch} placeholder={"Search Users"} />
            {isAdmin && (
              <UserManagementOptions
                setUploadCSVModalOpen={setUploadCSVModalOpen}
                setUpdateUsersTagsStatusModalOpen={setUpdateUsersTagsStatusModalOpen}
                selectedUsers={selectedUsers}
                disabled={loading && userLoadingKeys.includes(UserLoadingKeys.users)}
                onClickSubProblems={onClickSubProblems}
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex-1 w-full">
        {loading && userLoadingKeys.includes(UserLoadingKeys.users) ? (
          <MainLoader />
        ) : (
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        ></th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Role
                          <span className="block text-sm text-gray-400">
                            {premiumCount} premium / {basicCount} basic
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="text-center px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Stripe Status
                        </th>
                        <th
                          scope="col"
                          className="text-center px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Date Joined
                        </th>

                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {users.length ? (
                        users.map((user) => (
                          <tr key={user.id}>
                            <td className="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {isAdmin && (
                                <input
                                  className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300 rounded"
                                  type="checkbox"
                                  onClick={() => onToggleSelectedUser(user.id)}
                                />
                              )}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <Avatar user={user} />
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">
                                    {user.fullName}{' '}
                                    <span className="text-xs text-gray-400">({user.id})</span>
                                  </div>
                                  <div className="text-sm text-gray-500">{user.email}</div>
                                </div>

                                {isAdmin && (
                                  <SecondaryButton
                                    label="Sign in as..."
                                    extraClasses="px-2 ml-2 text-xs bg-gray-200"
                                    onClick={() => dispatch(signInAsAsync({ email: user.email }))}
                                  ></SecondaryButton>
                                )}
                              </div>
                            </td>

                            <td className="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              <div className="flex justify-center items-center">
                                {user.role}
                                {'premium' === user.role && (
                                  <PrimaryButton
                                    label="Go to board"
                                    size="sm"
                                    extraClasses="px-2 ml-2"
                                    onClick={() => history.push('/move-the-needle/' + user.id)}
                                  ></PrimaryButton>
                                )}
                              </div>
                            </td>
                            <td className="text-center px-6 py-4 whitespace-nowrap">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                {user.stripeStatus}
                              </span>
                            </td>
                            <td className="text-center px-6 py-4 whitespace-nowrap">
                              {new Date(user.createdAt).toLocaleDateString()}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              {(isAdmin || isCoach) && (
                                <button
                                  onClick={() => {
                                    setEditingUser(user)
                                    setUserModalOpen(true)
                                  }}
                                  className="text-purple-450 hover:text-purple-450"
                                >
                                  Edit
                                </button>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            <div className="my-4 text-lg">No users</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <TablePagination
                    count={'coach' === currentUser.role ? premiumCount : totalCount}
                    goToNextPage={goToNextPage}
                    goToPrevPage={goToPrevPage}
                    setItemsPerPage={setUsersPerPage}
                    itemsPerPage={usersPerPage}
                    itemsPerPageLabel="Users per page"
                    page={page}
                  />
                </div>
              </div>
            </div>
          </div>   
        )}
      </div>
      <FormModal open={uploadCSVModalOpen} setOpen={setUploadCSVModalOpen} Form={UploadCSVForm} />
      <FormModal
        open={userModalOpen}
        setOpen={setUserModalOpen}
        Form={UserForm}
        editingItem={editingUser}
        setEditingItem={setEditingUser}
        deleteAction={async () => {
          await dispatch(deleteUserAsync({ user: editingUser }))
        }}
        onAfterClose={() => dispatch(readManyUsersAsync({ page, usersPerPage, search: debouncedSearchValue }))}
      />
      <FormModal
        open={updateUsersTagsStatusModalOpen}
        setOpen={setUpdateUsersTagsStatusModalOpen}
        Form={UpdateUsersTagsStatusForm}
        onAfterClose={() => {
          dispatch(readManyUsersAsync({ page, usersPerPage, search: debouncedSearchValue }))
        }}
      />
    </div>
  )
}

export default UserManagement
