import React, { useState, useEffect, Suspense } from 'react'
import { useDispatch } from 'react-redux'
import PrimaryButton from '../../../components/general/PrimaryButton'
import SecondaryButton from '../../../components/general/SecondaryButton'
import { deleteCheckListItemAsync, upsertCheckListItemAsync } from '../moveTheNeedleSlice'
import MarkdownPreview, { rehypeRewrite } from '../../../components/general/MarkdownPreview'
import LoadingSpinner from '../../../components/general/LoadingSpinner'

const MDEditor = React.lazy(() => import('@uiw/react-md-editor'))

const CheckListItem = ({ item }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(item)
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setFormValues(item)
  }, [item])

  return (
    <div className="flex items-start">
      <input
        id="completed"
        aria-describedby="title"
        name="completed"
        type="checkbox"
        className="focus:ring-purple-450 h-5 w-5 text-purple-450 border-gray-300 rounded mr-4 self-center"
        checked={formValues?.completed}
        onChange={(event) => {
          dispatch(
            upsertCheckListItemAsync({
              checkListItem: { ...formValues, completed: event.target.checked },
              CheckListId: formValues?.CheckListId
            })
          )
        }}
      />
      <div
        className="flex-1 py-2"
        onClick={(event) => {
          // if user clicked on a link, don't show the textarea
          if (event.target.tagName === 'A') return

          event.preventDefault()
          event.stopPropagation()
          setIsEditing(true)
        }}
      >
        <div data-color-mode="light">
          {isEditing ? (
            <Suspense fallback={<LoadingSpinner />}>
              <MDEditor
                id="title"
                name="title"
                value={formValues?.title || ''}
                onChange={(value) => setFormValues((prevState) => ({ ...prevState, title: value }))}
                hideToolbar={true}
                preview={isEditing ? 'edit' : 'preview'}
                previewOptions={{ rehypeRewrite }}
                autoFocus={true}
                className={`text-md ${
                  isEditing ? 'border-2' : 'border-0'
                } rounded-md hover:border-gray-400`}
                height={100}
                visibleDragbar={true}
              />
            </Suspense>
          ) : (
            <div className="text-lg">
              <MarkdownPreview markdown={formValues?.title} />
            </div>
          )}

          {isEditing && (
            <div className="flex mt-2">
              <PrimaryButton
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  setIsEditing(false)
                  dispatch(
                    upsertCheckListItemAsync({
                      checkListItem: formValues,
                      CheckListId: formValues?.CheckListId
                    })
                  )
                }}
                style={{ width: 100 }}
                label="Save"
                size="sm"
                extraClasses="mr-2 uppercase mb-2"
              ></PrimaryButton>
              <SecondaryButton
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  setIsEditing(false)
                  dispatch(deleteCheckListItemAsync(item.id))
                }}
                style={{ width: 100 }}
                label="Delete"
                size="sm"
                extraClasses="uppercase bg-red-600 hover:bg-red-500 text-white mr-2 mb-2"
              ></SecondaryButton>
              <SecondaryButton
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  setIsEditing(false)
                }}
                style={{ width: 100 }}
                label="Cancel"
                size="sm"
                extraClasses="uppercase mb-2"
              ></SecondaryButton>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CheckListItem
