import React from 'react'
import {
  UserIcon as UserSolidIcon
} from '@heroicons/react/solid';

const Avatar = ({ user, testId }) => {
  return (
    <>
      {user?.photoUrl ? (
        <span
          className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100 bg-center bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${user.photoUrl})` }}
          data-testid={testId}
        ></span>
      ) : (
        <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100" data-testid={testId}>
          <UserSolidIcon className="h-full w-full text-gray-300 mt-2" />
        </span>
      )}
    </>
  )
}

export default Avatar
