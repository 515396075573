import classNames from "classnames";
import {
  SearchIcon
} from "@heroicons/react/outline";

const Search = ({value, onChange, placeholder, name, disabled, testId}) => {
    return (
        <div className="relative rounded-lg w-full" data-testid={testId}>
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-dark-300">
              <SearchIcon className="w-4 h-4 text-dark-300" />
            </div>
            <input
              type="search"
              name={name}
              value={value}
              onChange={(event) => onChange(event.target.value)}
              className={classNames([
                'rounded-lg border border-solid border-white-400 focus:border-secondary block w-full max-w-full overflow-hidden bg-white pl-9.5 py-2.25 pr-3.5 font-normal font-base tracking-0.5 truncate text-base leading-24 text-dark placeholder-dark-300 focus:ring-secondary',
                {
                  'text-gray-400 bg-grey-100 cursor-not-allowed': disabled
                }
              ])}
              placeholder={placeholder}
              disabled={disabled}
            />
          </div>
    )
}

export default Search;