import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
//import { toast } from "react-toastify";

export const PQ_QUESTIONS = [
  'Are you looking at the top searched, closest-related keyword? (It should be very specific).',
  'What is the search volume?',
  'Are there less than 1000 competitors?',
  'Are there sellers on the 1st page for your main keywords with reviews under 1000?',
  'Is the average price of this product over $15?',
  'Are 3-5 of the top 10 listings selling 300+ units a month?',
  'With a quick search on Alibaba for related products, can you get this product sourced for 4-5x less than price on Amazon?',
  'Is CPR 8 Day Giveaway Affordable within your Projected Budget?',
  'Do you see other FBA sellers on XRAY?',
  'Can you improve / innovate the product?',
  'Is Profit Margin on Power Profit Tool 35-50% or higher?'
]

const initialState = {
  loading: true,
  emailDefaults: {
    answers: [
      { id: 'keyword', question: PQ_QUESTIONS[0], answer: 'Yes' },
      {
        id: 'volume',
        question: PQ_QUESTIONS[1],
        answer: 10000,
        feedback: 'You’re in the sweet spot! Most Rainmakers’ first products are within this range.'
      },
      { id: 'competitors', question: PQ_QUESTIONS[2], answer: 'No' },
      { id: 'sellers', question: PQ_QUESTIONS[3], answer: 'Yes' },
      { id: 'price', question: PQ_QUESTIONS[4], answer: 'Yes' },
      { id: 'units', question: PQ_QUESTIONS[5], answer: 'Yes' },
      { id: 'alibaba', question: PQ_QUESTIONS[6], answer: 'No' },
      { id: 'cpr', question: PQ_QUESTIONS[7], answer: 'Yes' },
      { id: 'xray', question: PQ_QUESTIONS[8], answer: 'Yes' },
      { id: 'improve', question: PQ_QUESTIONS[9], answer: 'No' },
      { id: 'profit', question: PQ_QUESTIONS[10], answer: 'No' }
    ],
    score: 5,
    result: '👍'
  }
}

export const sendProductQualifierResultsAsync = createAsyncThunk(
  'productQualifier/sendProductQualifierResults',
  async ({ score, answers, result, email }) => {
    await axios.post('/api/product-qualifier/send', {
      score,
      answers,
      result,
      email
    })
    return { success: true }
  }
)

export const saveProductQualifierResultsAsync = async ({ score, answers }) =>
  axios.post('/api/product-qualifier/save', { score, answers })

export const productQualifierSlice = createSlice({
  name: 'productQualifier',
  initialState,
  reducers: {
    updateAnswers: (state, action) => {
      for (const key in action.payload.answers) {
        if (Object.hasOwnProperty.call(action.payload.answers, key)) {
          const value = action.payload.answers[key]
          let answerIndex = state.emailDefaults.answers.findIndex((a) => a.id === key)

          if (answerIndex >= 0)
            state.emailDefaults.answers[answerIndex].answer =
              '0' === value ? 'No' : '1' === value ? 'Yes' : value
        }
      }
      state.emailDefaults.score = action.payload.score
      state.emailDefaults.result = action.payload.result
    }
  },
  extraReducers: () => {}
})

export const { updateAnswers } = productQualifierSlice.actions

export default productQualifierSlice.reducer
