import {
    ArrowLeftIcon
} from "@heroicons/react/solid"

export const BackToCourses = ({
    title = "Back to courses"
}) => {
    return (
        <a 
            className="max-w-max flex flex-row gap-1 items-center text-light-violet font-semibold cursor-pointer hover:text-black-violet transition-colors" 
            href="/courses"
        >
            <ArrowLeftIcon className="w-4 h-4" />
            {title}
        </a>
    )
}