import {
    LockClosedIcon
  } from '@heroicons/react/outline'
import classNames from "classnames";

const AItoolCard = ({ title, name, click, img, description, link, blocked, testId }) => {
    return (
        <div 
            onClick={() => (click && !blocked) && click()}
            className={classNames([
                'bg-white rounded-2xl border border-solid border-primary border-opacity-20 shadow-box flex flex-col overflow-hidden', {
                   'cursor-pointer group': !blocked && (click || link),
                   'relative': link
                }
            ])}
            data-testid={testId}
        >
            {(link && !blocked) && (
                <a data-testid={testId ? `link-${testId}` : undefined} href={link} className="absolute top-0 left-0 w-full h-full z-10"> </a>
            )}
            <div className="relative pt-full overflow-hidden group-hover:opacity-80 transition-opacity">
                {img && (
                    <img data-testid={testId ? `img-${testId}` : undefined} src={img} alt={title} className="absolute top-0 left-0 w-full h-full object-center object-cover"/>
                )}
                {blocked && (
                    <div className="absolute top-0 left-0 w-full h-full flex flex-col text-white bg-black bg-opacity-60 p-2.5">
                        <div className="flex-1 flex items-center justify-center">
                            <LockClosedIcon className="w-25 h-25"/>
                        </div>
                        <p className="flex-initial text-center text-sm leading-20 font-medium font-base">Mastermind Members Only</p>
                    </div>
                )}
            </div>
            <div className="flex-1 flex flex-col justify-center pt-4 pb-5 px-1.5 text-center">
                <h4 className="text-base leading-24 2xl:leading-20 text-dark group-hover:text-secondary font-geist font-semibold text-dark transition-colors">
                    {title} {name && (<><br/>({name})</>)}
                </h4>
                {description && (
                    <p className="text-xs leading-20 text-dark -mb-4">{description}</p>
                )}
            </div>
        </div>
    )
}

export default AItoolCard;