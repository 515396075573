import React from 'react'

const CommunityUser = ({ photoUrl, name, city, state, testId }) => {
  const getUserInitials = (fullName) => {
    return (
      fullName
        .split(' ')
        .map((name) => name[0])
        // get first two initials
        .slice(0, 2)
        .join('')
        .toUpperCase()
    )
  }
  return (
    <div className="flex items-center gap-3" data-testid={testId}>
      <div className="flex-shrink-0 w-10 h-10 rounded-full overflow-hidden bg-secondary flex items-center justify-center">
        {photoUrl ? (
          <img
            src={photoUrl}
            className="w-full h-full object-cover object-center"
            alt={name ? name : ''}
          />
        ) : (
          <span className="text-base leading-24 text-white font-title font-medium">
            {getUserInitials(name)}
          </span>
        )}
      </div>
      <div className="flex-1 max-w-full overflow-hidden">
        {name && (
          <p className="text-sm leading-20 text-dark tracking-0.5 font-base font-medium font-base truncate">
            {name}
          </p>
        )}
        <p className="text-sm leading-20 text-dark-400 tracking-0.5 font-base font-normal">
          {city && state ? city + ', ' + state : 'United States'}
        </p>
      </div>
    </div>
  )
}

export default CommunityUser
