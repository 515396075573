import React from 'react'
import FormFieldTooltip from '../../components/general/FormFieldTooltip'

const MetricItem = ({ 
  itemName,
  isIncorrectValue = false,
  errorTooltip,
  itemValue, 
  tooltip, 
  percentage, 
  dollar, 
  type, 
  enableValueHighlighting, 
  fixedPoints 
}) => {
  const chopIfDecimnal = (n) => (n % 1 !== 0 ? Number(n).toFixed(2) : n || 0)
  const widthClassnames = type === "default" ? "w-48" : "w-48 md:w-52 xl:w-64"

  const format = (n) => fixedPoints !== undefined ? Number(n).toFixed(fixedPoints) : n;

  const floatItemValue = parseFloat(itemValue)
  const incorrentValue = !!(floatItemValue && floatItemValue < 0) || isIncorrectValue;
  const coloredValueClassname = enableValueHighlighting ? incorrentValue ? "text-red-800" : "text-green-800" : "";

  return (
    <div className={`flex flex-col items-center p-2 xl:p-4 xl:pr-6 xl:pl-6 ${widthClassnames} mb-2 sm:mb-0 justify-center`}>
      <span className={`${!floatItemValue ? 'text-gray-400' : ''} text-3xl font-bold ${coloredValueClassname}`}>
        {dollar
          ? itemValue >= 0
            ? '$' + format(chopIfDecimnal(itemValue))
            : '-$' + format(Math.abs(itemValue))
          : null}

        {percentage ? format((chopIfDecimnal(itemValue)) || 0) + '%' : null}
      </span>
      <span
        className={`${
          !itemValue ? 'text-gray-400' : ''
        } text-md mt-2 flex flex-col space-y-2 text-center items-center`}
      >
        <span>{itemName}</span>
        <div className="flex flex-row gap-2">
          {tooltip && <FormFieldTooltip dataFor={tooltip} />}
          {errorTooltip && <FormFieldTooltip dataFor={errorTooltip} label={"Net loss"} isError={true} />}
        </div>
      </span>
    </div>
  )
}

export default MetricItem
