import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import axios from 'axios'

const initialState = {
  currentUser: null,
  loading: true,
  error: null,
  rainaChatIsVisible: false,
  generalError: null
}

export const forgotPasswordAsync = createAsyncThunk(
  'auth/forgotPassword',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/auth/forgot-password', { email })

      const { data } = response
      return data
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const resetPasswordAsync = createAsyncThunk(
  'users/reset-password',
  async ({ password, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/auth/reset-password/${token}`, {
        password
      })

      const { data } = response
      return { currentUser: data.user }
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const loginAsync = createAsyncThunk('auth/login', async ({ user }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/api/auth/signin`, user).then((resp) => resp.data)
    return { currentUser: response.currentUser }
  } catch (error) {
    console.error(error)
    return rejectWithValue(error.response.data)
  }
})

export const signInAsAsync = createAsyncThunk(
  'auth/signInAs',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/auth/signinas`, { email }).then((resp) => resp.data)
      return { currentUser: response.currentUser }
    } catch (error) {
      console.error(error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const logoutAsync = createAsyncThunk('auth/logout', async () => {
  await axios.post(`/api/auth/signout`)
  return { success: true }
})

export const checkAuthStatusAsync = createAsyncThunk('auth/checkAuthStatus', async () => {
  const { currentUser } = await axios.get(`/api/auth/me`).then((resp) => resp.data)
  return { currentUser }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null
    },
    setGeneralError: (state, action) => {
      state.generalError = action.payload.error
    },
    clearGeneralError: (state) => {
      state.generalError = null
    },
    setRainaVisibilityChat: (state, action) => {
      state.rainaChatIsVisible = action.payload || false
    },
    switchRole: (state, action) => {
      if (state.currentUser) {
        state.currentUser.role = action.payload.role
        state.currentUser.isAdmin = true
      }
    },
    addResource: (state, action) => {
      state.currentUser.Resources.push({ id: action.payload.id })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPasswordAsync.pending, (state) => {
        state.loading = true
        state.error = null
        state.generalError = null
      })
      .addCase(forgotPasswordAsync.fulfilled, (state, action) => {
        state.loading = false
        toast.success(`Email sent successfully`)
      })
      .addCase(forgotPasswordAsync.rejected, (state, action) => {
        state.loading = false
        state.generalError = action.payload.error
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.loading = true
        state.error = null
        state.generalError = null
      })
      .addCase(resetPasswordAsync.fulfilled, (state, action) => {
        state.loading = false
        state.currentUser = action.payload.currentUser
        toast.success(`Password reset successfully`)
      })
      .addCase(resetPasswordAsync.rejected, (state, action) => {
        state.loading = false
        state.generalError = action.payload.error
      })
      .addCase(loginAsync.pending, (state) => {
        state.loading = true
        state.error = null
        state.generalError = null
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loading = false
        state.currentUser = action.payload.currentUser
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loading = false
        state.generalError = action.payload.error
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        state.loading = false
        state.currentUser = null
      })
      .addCase(logoutAsync.rejected, (state, action) => {
        state.loading = false
        state.error = 'Internal server error logging out'
      })
      .addCase(checkAuthStatusAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(checkAuthStatusAsync.fulfilled, (state, action) => {
        state.loading = false
        state.currentUser = action.payload.currentUser
      })
      .addCase(checkAuthStatusAsync.rejected, (state, action) => {
        state.loading = false
        state.currentUser = null
      })
      .addCase(signInAsAsync.fulfilled, (state, action) => {
        state.loading = false
        state.currentUser = action.payload.currentUser
      })
  }
})

export const { clearErrors, clearGeneralError, switchRole, addResource, setGeneralError, setRainaVisibilityChat } =
  authSlice.actions

export default authSlice.reducer
