import classNames from "classnames";

const ProgressBar = ({ count, sizeText, testId }) => {
  return (
    <div className="flex items-center gap-3" data-testid={testId}>
      <div className="flex-1 rounded bg-white-300 relative">
        <div className="h-2 rounded bg-secondary relative z-2" style={{ width: count + '%' }} />
        {sizeText === 'xs' && (
          <span data-testid={testId ? `sizeText-${testId}` : undefined} className="rounded border border-solid border-primary border-opacity-20 absolute top-0 left-0 w-full h-full z-1" />
        )}
      </div>
      <span 
        className={classNames([
          'flex-initial font-secondary text-dark',
          {
            'text-xs leading-none font-medium': sizeText === 'xs',
            'text-sm leading-20 font-bold': !sizeText,
          }
        ])}>
        {count + '%'}
      </span>
    </div>
  )
}

export default ProgressBar
