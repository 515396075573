import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bulkUpdateUsersAsync, generateTestCSVAsync, validateCSVUsersAsync } from './userSlice'
import { UploadIcon } from '@heroicons/react/outline'
import Select from 'react-select'
import { useState } from 'react'

const csvStringToArray = (strData) => {
  const objPattern = new RegExp(
    '(\\,|\\r?\\n|\\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^\\,\\r\\n]*))',
    'gi'
  )
  let arrMatches = null,
    arrData = [[]]
  while ((arrMatches = objPattern.exec(strData))) {
    if (arrMatches[1].length && arrMatches[1] !== ',') arrData.push([])
    arrData[arrData.length - 1].push(
      arrMatches[2] ? arrMatches[2].replace(new RegExp('""', 'g'), '"') : arrMatches[3]
    )
  }
  return arrData
}

const UpdateUsersTagsStatusForm = ({ setOpen }) => {
  const file = useRef(null)
  const dispatch = useDispatch()
  const { loading, CSVFoundUsers, CSVDuplicateUsers, CSVNotFoundUsers } = useSelector(
    (state) => state.user
  )
  const [formValues, setFormValues] = useState({})
  const [importingStage, setImportingStage] = useState('preparing') // 'preparing', 'viewingReport'
  const { tags } = useSelector((state) => state.tag)

  // upload file listener
  useEffect(() => {
    document.getElementById('file').onclick = (event) => {
      document.getElementById('file').value = null
    }

    document.getElementById('file').onchange = (event) => {
      if (event.target.files && event.target.files.length > 0) {
        var file = document.getElementById('file').files[0]
        var reader = new FileReader()

        reader.onload = function (event) {
          if (event.target.readyState !== 2) return
          if (event.target.error) {
            console.error('Error while reading file')
            return
          }

          const csvContents = event.target.result

          const usersArray = csvStringToArray(csvContents).filter((u) => u[0] !== '')

          setFormValues((prevState) => ({ ...prevState, usersArray: usersArray }))

          dispatch(validateCSVUsersAsync({ users: usersArray })).then(() => {
            setImportingStage('viewingReport')
          })
        }

        reader.readAsText(file)
      }
    }

    // eslint-disable-next-line
  }, [])

  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const [foundShowing, setFoundShowing] = useState(false)
  const [bulkUpdating, setBulkUpdating] = useState(false)

  return (
    <>
      <div className="flex justify-between">
        <div className="" style={{ flexBasis: importingStage !== 'preparing' ? '60%' : '100%' }}>
          <div className="mb-6">
            <div>
              <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                Would you like to add or remove tags
              </label>
              <p className="text-sm leading-5 text-gray-500">
                these tags will be bulk added / removed
              </p>
              <div className="mt-1">
                <select
                  id="availableAction"
                  name="availableAction"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-450 focus:border-purple-450 sm:text-sm rounded-md"
                  value={formValues?.availableAction}
                  onChange={onChange}
                >
                  <option value="">Don't add or remove tags</option>
                  <option value="add">Add new tags</option>
                  <option value="remove">Remove existing tags</option>
                </select>
              </div>
            </div>
          </div>

          {formValues?.availableAction && (
            <div className="mb-6">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                User tags
              </label>
              <div className="mt-1">
                <Select
                  isMulti={true}
                  value={formValues?.Tags?.map((tag) => ({
                    value: tag.id,
                    label: tag.name
                  }))}
                  onChange={(Tags) =>
                    setFormValues((prevState) => ({
                      ...prevState,
                      Tags: Tags.map((tag) => ({
                        id: tag.value,
                        name: tag.label
                      }))
                    }))
                  }
                  options={tags.map((tag) => ({
                    value: tag.id,
                    label: tag.name
                  }))}
                />
              </div>
            </div>
          )}

          <div className="mb-6">
            <div>
              <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                Bulk update users status to:
              </label>
              <div className="mt-1">
                <select
                  id="role"
                  name="role"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-450 focus:border-purple-450 sm:text-sm rounded-md"
                  value={formValues?.role}
                  onChange={onChange}
                >
                  <option value="">Don't update</option>
                  <option value="basic">Basic</option>
                  <option value="premium">Premium</option>
                </select>
              </div>
            </div>
          </div>

          <input
            id="file"
            type="file"
            name="file"
            ref={file}
            className="hidden"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          {importingStage === 'preparing' ? (
            <>
              <h1 className="text-center text-2xl mb-2 text-purple-450">
                Upload CSV -{' '}
                <button
                  onClick={() => generateTestCSVAsync()}
                  className="text-sm border-b border-purple-450"
                >
                  download test CSV structure
                </button>
              </h1>
              <div className="border-2 border-dashed border-purple-450 bg-purple-100 h-24 text-purple-450 flex flex-col justify-center items-center">
                {loading ? (
                  'uploading...'
                ) : (
                  <>
                    <UploadIcon className="h-10 w-10" />
                    <button
                      disabled={loading}
                      onClick={() => {
                        if (file && file.current) {
                          file.current.click()
                        }
                      }}
                      className="uppercase border border-purple-450 rounded-md px-2 py-1 mt-2 hover:bg-pink-200"
                    >
                      Upload CSV
                    </button>
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="">
              CSV processed sucessfully. results are displayed on the right column. Press{' '}
              <i className="text-bold">Begin bulk update</i> below to begin, or{' '}
              <i className="text-bold">cancel</i> to start over.
            </div>
          )}
        </div>
        {importingStage === 'viewingReport' && (
          <div className="px-8" style={{ flexBasis: '40%' }}>
            <>
              <h2 className="text-red-400">Not found: ({CSVNotFoundUsers?.length})</h2>
              {CSVNotFoundUsers?.length ? (
                <ul>
                  {CSVNotFoundUsers?.map((email, i) => (
                    <li key={i}>{email}</li>
                  ))}
                </ul>
              ) : null}
            </>

            <>
              <h2 className="mt-8 text-yellow-400">
                Duplicate emails: ({CSVDuplicateUsers?.length}){' '}
              </h2>
              {CSVDuplicateUsers?.length ? (
                <ul>
                  {CSVDuplicateUsers?.map((email, i) => (
                    <li key={i}>{email}</li>
                  ))}
                </ul>
              ) : null}
            </>

            <>
              <h2 className="mt-8 text-green-400">Found: ({CSVFoundUsers?.length})</h2>
              {CSVFoundUsers?.length ? (
                <>
                  <button
                    className="my-2 py-0.5 px-2 rounded-md border border-purple-450 text-purple-450"
                    onClick={() => setFoundShowing((p) => !p)}
                  >
                    {foundShowing ? 'hide' : 'show'}
                  </button>
                  {foundShowing && (
                    <ul className="max-h-20 overflow-y-scroll border">
                      {CSVFoundUsers?.map((email, i) => (
                        <li key={i}>{email}</li>
                      ))}
                    </ul>
                  )}{' '}
                </>
              ) : null}
            </>
          </div>
        )}
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          className={`${
            importingStage === 'preparing' ? 'bg-gray-400' : 'bg-purple-450'
          } mt-6 inline-flex items-center rounded border border-transparent  hover:bg-purple-45 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
          disabled={importingStage === 'preparing' || bulkUpdating}
          onClick={() => {
            setBulkUpdating(true)
            dispatch(bulkUpdateUsersAsync({ formValues, CSVFoundUsers })).then(() => {
              setOpen(false)
              setBulkUpdating(false)
            })
          }}
        >
          {bulkUpdating ? (
            <>
              <svg
                role="status"
                class="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>{' '}
              update in progress
            </>
          ) : (
            'Begin bulk update'
          )}
        </button>

        <button
          type="button"
          className={`bg-gray-400 mt-6 inline-flex items-center rounded border border-transparent  hover:bg-purple-45 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
          onClick={() => {
            setOpen(false)
          }}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default UpdateUsersTagsStatusForm
