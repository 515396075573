import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '../../../components/general/Avatar'
import { clearMentionedUsers, upsertActivityAsync } from '../moveTheNeedleSlice'

import Mention from './Mention'
import Mentions from './Mentions'

const CommentBox = () => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({})
  const { activeCardId } = useSelector((state) => state.moveTheNeedle)
  const { currentUser } = useSelector((state) => state.auth)
  const [mentionsShowing, setMentionsShowing] = useState(false)
  const { mentionedUsers } = useSelector((state) => state.moveTheNeedle)

  return (
    <div className="flex space-x-4">
      <Avatar user={currentUser} />
      <div className="flex-1">
        <form className="relative">
          <Mention mentionsShowing={mentionsShowing} setMentionsShowing={setMentionsShowing} />
          <Mentions mentionedUsers={mentionedUsers} />
          <textarea
            name="comment"
            rows={3}
            className={`${
              mentionedUsers?.length ? 'pt-8' : ''
            } shadow-sm block w-full focus:ring-purple-450 focus:border-purple-450 sm:text-sm border border-gray-300 rounded-md`}
            value={formValues?.comment || ''}
            onChange={(event) =>
              setFormValues((prevState) => ({
                ...prevState,
                comment: event.target.value
              }))
            }
            onKeyDown={async (event) => {
              if (event.keyCode === 13 && event.shiftKey === false) {
                event.preventDefault()
                if (formValues?.comment) {
                  await dispatch(
                    upsertActivityAsync({
                      activity: { ...formValues },
                      CardId: activeCardId,
                      mentionedUsers: mentionedUsers?.map((user) => user.id)
                    })
                  )
                  setFormValues(null)
                  dispatch(clearMentionedUsers())
                }
              }
            }}
          />
        </form>
      </div>
    </div>
  )
}

export default CommentBox
