import { PhotographIcon } from '@heroicons/react/solid'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../../components/general/LoadingSpinner.js'
import { uploadFileAsync, deleteFile } from './uploaderSlice.js'
import {
  UserIcon as UserSolidIcon
} from '@heroicons/react/solid';

const ProfileUploaderFormField = ({ field, state, setState, buttonLabel, profile }) => {
  const file = useRef(null)
  const { loading } = useSelector((state) => state.uploader)
  const dispatch = useDispatch()

  const deleteFileFromModel = () => {
    setState((prevState) => ({
      ...prevState,
      [field]: null
    }))
  }

  // upload file listener
  useEffect(() => {
    document.getElementById(field).onclick = (event) => {
      document.getElementById(field).value = null
    }

    document.getElementById(field).onchange = (event) => {
      if (event.target.files && event.target.files.length > 0) {
        dispatch(uploadFileAsync({ id: field })).then(({ payload }) => {
          setState((prevState) => ({
            ...prevState,
            [field]: payload.latestUploadedFileUrl
          }))
        })
      }
    }

    return () => {
      dispatch(deleteFile())
      deleteFileFromModel()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="">
      <input id={field} type="file" name={field} ref={file} className="hidden" accept="image/*" />
      <div className="flex justify-center pt-5 pb-6  border-gray-300 rounded-md">
        <div className="text-center">
          {state && state[field] ? (
            <img className="mb-5" src={state[field]} alt="preview" />
          ) : (
            <>
              {profile ? (
                <span className="mb-16 inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100 text-gray-300">
                  <UserSolidIcon className="h-full w-full text-gray-300 mt-2" />
                </span>
              ) : (
                <span className="mb-16 inline-block h-24 w-24 rounded-full overflow-hidden bg-gray-100 text-gray-300">
                  <PhotographIcon className="h-24 w-24" />
                </span>
              )}
            </>
          )}

          <button
            type="button"
            className="uppercase inline-flex items-center w-full justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-450 hover:bg-purple-450 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450"
            disabled={loading}
            onClick={() => {
              if (file && file.current) {
                file.current.click()
              }
            }}
          >
            {loading ? <LoadingSpinner /> : buttonLabel}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProfileUploaderFormField
