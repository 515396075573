import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import axios from 'axios'
import { getCompletedLessonsAsync, readManyModulesAsync } from '../course/courseSlice'
import { ListsLoadingKey, readManyListsAsync } from '../move-the-needle/moveTheNeedleSlice'
import { countProductsAsync, ProductLoadingKey } from '../power-profit-tool/powerProfitToolSlice'
import { readManyResourcesAsync, ResourceLoadingKey } from '../resource/resourceSlice'
import * as moment from 'moment'
import DashboardBanner from '../../components/general/DashboardBanner'
import ErrorBoundary from '../../components/general/ErrorBoundary'
import {
  SearchIcon,
} from "@heroicons/react/outline";
import classNames from 'classnames'
import CommunityUser from '../../components/general/CommunityUser'
import BoxCard from '../../components/general/box-card/BoxCard'
import BoxCardHeader from '../../components/general/box-card/BoxCardHeader'
import ProgressBar from '../../components/general/ProgressBar';
import { ADMIN_ROLE, PREMIUM_ROLE } from '../../constants'
import { readManyCoursesAsync } from '../courses-list/coursesListSlice'
import { arrayHasItems, getFilteredCoursesByRole } from '../../helpers'
import LoadingSpinner from '../../components/general/LoadingSpinner'
import { MainLoader } from '../../components/Loaders/MainLoader'

const getMaxHeightCard = (max) => {
  return max ? 360 : 288;
}

const Dashboard = () => {
  const dispatch = useDispatch()
  const { 
    productsTotal,
    loadingKeys: loadingProductKeys,
    loading: loadingProduct
  } = useSelector((state) => state.powerProfitTool)
  const { lists, loadingKeys: listsLoadingKeys, loading: listsIsLoading } = useSelector((state) => state.moveTheNeedle)
  const { courses, loading: courseListLoading } = useSelector(
    (state) => state.courseList
  )
  const { currentUser } = useSelector((state) => state.auth)
  const [courseProgress, setCourseProgress] = useState(0)
  const averageShippingRate = useSelector((state) => state?.dashboardBanner?.premiumBanner?.average_shipping_rate || null)

  useEffect(() => {
    if(!arrayHasItems(courses)) {
      setCourseProgress(0);
      return;
    }
    const currentCourses = courses.filter(
      (course) => arrayHasItems(course.Modules) && course.Modules.some((module) => module.Lessons.length > 0)
    );
    const filteredCourses = getFilteredCoursesByRole(currentCourses, currentUser?.role || 'basic');
    setCourseProgress(
      filteredCourses.length > 0 ? 
        Math.ceil(filteredCourses.reduce((acc, obj) => acc + obj.totalProgress, 0) / filteredCourses.length) : 
        0
    )
  }, [courses]);

  useEffect(() => {
    dispatch(readManyCoursesAsync({ itemsPerPage: 1000 }))
    dispatch(readManyListsAsync({}))
    dispatch(readManyModulesAsync({}))
    dispatch(getCompletedLessonsAsync())
    dispatch(countProductsAsync())
    dispatch(readManyResourcesAsync({ itemsPerPage: 15, filters: {} }))
  }, [dispatch])

  // resources
  const { resources, loadingKeys: resourceLoadingKeys, loading: resourceIsLoading } = useSelector((state) => state.resource)

  const [donutValues, setDonutValues] = useState({
    progress: 0,
    hold: 0,
    completed: 0
  })
  const segmentOffset = 25
  const segmentSpacing = 0

  const isAverageShippingRateVisible = () => {
    const isAverageShippingRateSet = !!averageShippingRate && averageShippingRate !== '0';
    return [PREMIUM_ROLE, ADMIN_ROLE].includes(currentUser?.role) && isAverageShippingRateSet;
  }

  useEffect(() => {
    if (!lists) return
    const MTNDataReduced = lists?.reduce((acc, board) => {
      switch (board.name) {
        case 'NOT STARTED':
        case 'IN PROGRESS':
        case 'COMPLETED':
        case 'ON HOLD':
          if (!acc['total']) {
            acc['total'] = board.Cards.length
          } else {
            acc['total'] += board.Cards.length
          }
          acc[board.name] = board.Cards.length
          break
        default:
          break
      }

      return acc
    }, {})

    if (0 === MTNDataReduced.total) {
      setDonutValues({
        notStarted: 0,
        progress: 0,
        hold: 0,
        completed: 0
      })
    } else {
      setDonutValues({
        notStarted: MTNDataReduced['NOT STARTED'] / (MTNDataReduced.total / 100),
        progress: MTNDataReduced['IN PROGRESS'] / (MTNDataReduced.total / 100),
        hold: MTNDataReduced['ON HOLD'] / (MTNDataReduced.total / 100),
        completed: MTNDataReduced['COMPLETED'] / (MTNDataReduced.total / 100)
      })
    }
  }, [lists])

  return (
    <div className="pt-7.5 md:pt-9.5 pb-6 md:pb-12">
      <div className="container pb-9 md:pb-9.5">
        <h1 className="text-lg md:text-xl leading-38 font-semibold tracking-1 font-base">
          Dashboard
        </h1>
      </div>
      <div className="container">
        {/* Replace with your content */}
        <div
          className={classNames([
            'grid grid-rows-auto sm:grid-cols-2 gap-4',
            {
              'xl:grid-cols-3': currentUser?.role !== 'basic'
            }
          ])}
        >
          <div className="max-w-full overflow-hidden col-span-full">
            <DashboardBanner />
          </div>
          <div className="max-w-full overflow-hidden sm:row-start-2 sm:col-start-1 sm:col-end-2">
            <BoxCard link="/courses">
              <BoxCardHeader title="Course Progress" linkIcon={true} />
              {courseListLoading ? <LoadingSpinner color="light-violet" /> : <ProgressBar count={courseProgress} />}
            </BoxCard>
          </div>
          <div className="max-w-full overflow-hidden sm:row-start-3 sm:col-start-1 sm:col-end-2">
            <ErrorBoundary fallback={CommunitySearchErrorUI}>
              <CommunitySearch heightCard={getMaxHeightCard(true)}/>
            </ErrorBoundary>
          </div>
          <div className="max-w-full overflow-hidden sm:row-start-2 sm:col-start-2">
            <BoxCard link="/profit-calculator">
              <BoxCardHeader title="Total Products" linkIcon={true} />
              {loadingProduct && loadingProductKeys.includes(ProductLoadingKey.count) ? <LoadingSpinner color="light-violet" /> : <p className="text-lg leading-20 font-medium font-base text-dark">{productsTotal}</p>}
            </BoxCard>
          </div>
          <div className="max-w-full overflow-hidden sm:row-start-3 sm:col-start-2">
            <BoxCard>
              <BoxCardHeader title="Recent Resources" link="/resources" />
              {resourceIsLoading && resourceLoadingKeys.includes(ResourceLoadingKey.resources) ? (
                 <MainLoader />
              ) : (
                <div className="overflow-auto" style={{maxHeight: getMaxHeightCard(true)}}>
                  <div className="divide-y divide-white-500">
                      {resources?.map((resource) => (
                        <div key={resource.id} className="py-2.5">
                          <Link
                            to={`/resources?id=${resource.id}`}
                            onClick={() => {
                              // scroll to top
                              document.getElementById('main').scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                              })
                            }}
                            className="group flex items-center gap-3"
                          >
                            <div className="flex-shrink-0 w-10 h-10 rounded-full overflow-hidden flex items-center justify-center">
                              {resource.coverPhotoUrl ? (
                                <img
                                  className="w-full h-full object-cover object-center"
                                  src={resource.coverPhotoUrl}
                                  alt=""
                                />
                              ) : (
                                <img
                                  className="w-full h-full object-cover object-center"
                                  src="https://storage.googleapis.com/rainmakers-academy-cms-staging-bucket/1637320669636.png"
                                  alt=""
                                />
                              )}
                            </div>
    
                            <div className="flex-1 max-w-full overflow-hidden">
                              <p
                                className="text-sm leading-20 text-dark tracking-0.5 font-base font-medium font-base truncate group-hover:text-secondary transition-colors"
                                title={resource.title}
                              >
                                {resource.title}
                              </p>
                              {/* <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                                  {resource?.ResourceCategory?.name ?? '-'}
                                </p> */}
                              <p className="text-sm leading-20 text-dark-400 tracking-0.5 font-base font-normal">
                                {moment(resource.createdAt).fromNow()}
                              </p>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </BoxCard>
          </div>
          {isAverageShippingRateVisible() && (
            <div className="xl:row-start-2 xl:row-end-3 col-span-full xl:col-start-3 xl:col-end-4">
              <BoxCard link="/courses/phase1/410/860">
                <BoxCardHeader title="Current Average Shipping Rate" linkIcon={true} />
                <p className="text-lg leading-20 font-medium font-base text-dark">{averageShippingRate}</p>
              </BoxCard>
            </div>
          )}
          {currentUser?.role !== 'basic' && (
            <div className={classNames([
              "max-w-full overflow-hidden col-span-full xl:col-start-3 xl:col-end-4",
              {
                'xl:row-start-2 xl:row-end-4' : !isAverageShippingRateVisible(),
                'xl:row-start-3 xl:row-end-4' : isAverageShippingRateVisible()
              }
            ])}>
              <BoxCard>
                <BoxCardHeader title="Move the Needle" link="/move-the-needle" />
                {listsIsLoading && listsLoadingKeys.includes(ListsLoadingKey.lists) ? (
                    <MainLoader />
                ) : (
                  <>
                    <div className="flex-1 flex items-center justify-center border-t border-b border-solid border-white-400">
                      <div className="relative">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 42 42"
                          className="donut"
                          role="img"
                        >
                          {!donutValues.progress && !donutValues.hold && !donutValues.completed ? (
                            <>
                              <circle
                                cx="21"
                                cy="21"
                                r="15.91549430918954"
                                fill="transparent"
                                role="presentation"
                              ></circle>
                              <circle
                                className="stroke-current text-white-300"
                                cx="21"
                                cy="21"
                                r="15.91549430918954"
                                fill="transparent"
                                strokeWidth="2.8"
                                strokeLinecap="round"
                                role="presentation"
                              ></circle>
                            </>
                          ) : (
                            <>
                              {donutValues.notStarted && (
                                <circle
                                  className="donut-segment"
                                  cx="21"
                                  cy="21"
                                  r="15.91549430918954"
                                  fill="transparent"
                                  stroke="#EBEBEB"
                                  strokeWidth="2.8"
                                  strokeDasharray={`${donutValues.notStarted - segmentSpacing} ${
                                    100 - donutValues.notStarted + segmentSpacing
                                  }`}
                                  strokeDashoffset={`${
                                    100 -
                                    donutValues.progress -
                                    donutValues.hold -
                                    donutValues.completed +
                                    segmentOffset
                                  }`}
                                ></circle>
                              )}
                              {donutValues.progress && (
                                <circle
                                  className="donut-segment"
                                  cx="21"
                                  cy="21"
                                  r="15.91549430918954"
                                  fill="transparent"
                                  stroke="#3e73eb"
                                  strokeWidth="2.8"
                                  strokeLinecap="round"
                                  strokeDasharray={`${donutValues.progress - segmentSpacing} ${
                                    100 - donutValues.progress + segmentSpacing
                                  }`}
                                  strokeDashoffset={0 + segmentOffset}
                                  aria-labelledby="donut-segment-1-title donut-segment-1-desc"
                                ></circle>
                              )}

                              {donutValues.hold && (
                                <circle
                                  className="donut-segment"
                                  cx="21"
                                  cy="21"
                                  r="15.91549430918954"
                                  fill="transparent"
                                  stroke="#489f87"
                                  strokeLinecap="round"
                                  strokeWidth="2.8"
                                  strokeDasharray={`${donutValues.hold - segmentSpacing} ${
                                    100 - donutValues.hold + segmentSpacing
                                  }`}
                                  strokeDashoffset={`${100 - donutValues.progress + segmentOffset}`}
                                ></circle>
                              )}

                              {donutValues.completed && (
                                <circle
                                  className="donut-segment"
                                  cx="21"
                                  cy="21"
                                  r="15.91549430918954"
                                  fill="transparent"
                                  stroke="#ac508b"
                                  strokeLinecap="round"
                                  strokeWidth="2.8"
                                  strokeDasharray={`${donutValues.completed - segmentSpacing} ${
                                    100 - donutValues.completed + segmentSpacing
                                  }`}
                                  strokeDashoffset={`${
                                    100 - donutValues.progress - donutValues.hold + segmentOffset
                                  }`}
                                ></circle>
                              )}
                            </>
                          )}
                        </svg>
                        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lg leading-52 text-dark font-geist font-semibold tracking-2">
                          {Math.round(donutValues.completed || 0)}%
                        </span>
                      </div>
                    </div>
                    <div className="mt-auto pt-4.5 flex justify-between text-center">
                      <div className="flex-1">
                        <p className="text-md-2 leading-20 text-dark-600 font-geist font-semibold mb-1.25">
                          {Math.round(donutValues.progress || 0)}%
                        </p>
                        <p className="text-xs leading-20 text-dark-400 font-medium font-base uppercase">
                          in progress
                        </p>
                      </div>
                      <div className="flex-1">
                        <p className="text-md-2 leading-20 text-dark-600 font-geist font-semibold mb-1.25">
                          {Math.round(donutValues.hold || 0)}%
                        </p>
                        <p className="text-xs leading-20 text-dark-400 font-medium font-base uppercase">
                          on hold
                        </p>
                      </div>
                      <div className="flex-1">
                        <p className="text-md-2 leading-20 text-dark-600 font-geist font-semibold mb-1.25">
                          {Math.round(donutValues.completed || 0)}%
                        </p>
                        <p className="text-xs leading-20 text-dark-400 font-medium font-base uppercase">
                          completed
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </BoxCard>
            </div>
          )}
        </div>
        {/* /End replace */}
      </div>
    </div>
  )
}

const CommunitySearch = ({heightCard}) => {
  const { users, loading, search, setSearch, fetchNextPage } = useCommunityUsers()
  const [isFocused, setIsFocused] = useState(false);

  const handleOnFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <BoxCard>
      <BoxCardHeader title="Community Search">
        <div
          className={classNames([
            'sm:h-5.5 w-full sm:w-28 flex-shrink flex items-center',
            {
              'flex-1 sm:w-full': isFocused
            }
          ])}
        >
          <div className="relative rounded-10 w-full">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-dark-300">
              <SearchIcon className="w-4 h-4 text-dark-300" />
            </div>
            <input
              type="search"
              name="search"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              className="rounded-10 focus:ring-secondary focus:border-secondary block w-full pl-10 sm:text-sm border-white-400 text-sm leading-24 text-dark font-normal font-base tracking-0.5 truncate placeholder-dark-300 max-w-full w-full overflow-hidden py-1.25"
              placeholder="Search"
              onFocus={handleOnFocus}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </BoxCardHeader>
      {loading ? (
        <MainLoader />
      ) : (
        <div className="overflow-auto" style={{maxHeight: heightCard}}>
          {!loading && !users?.length && (
            <p className="text-sm leading-20 font-base font-base">
              No Rainmakers found. Try searching a neighboring city!
            </p>
          )}
          <InfiniteScroll
            dataLength={users.length}
            next={fetchNextPage}
            hasMore={true}
            loader={null}
            endMessage={null}
            // needed for container to know when to load next
            // set to 360 because of parent div height 360px

            height={heightCard}
          >
            <div className="divide-y divide-white-500">
              {users?.map((user, i) => (
                <div className="py-2.5" key={i}>
                  <CommunityUser
                    photoUrl={user.photoUrl}
                    name={user.fullName}
                    city={user.city}
                    state={user.state}
                  />
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      )}
    </BoxCard>
  )
}

const useCommunityUsers = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState()
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  // handles page change
  useEffect(() => {
    async function fetchUsers() {
      const params = { page, usersPerPage: 15, filters: { role: ['basic', 'premium'] } }
      if (search) params.filters.cityOrState = search

      const { data } = await axios.get('/api/user/read-many', { params })

      const result = {
        users: data.users,
        premiumCount: data.premiumCount,
        basicCount: data.basicCount,
        totalCount: data.totalCount
      }
      setUsers((users) => [...users, ...result.users])
      setLoading(false)
      setHasMore(result.totalCount > users.length)
    }
    fetchUsers()
    // should not depend on users.length to prevent infinite loop
    // should not depend on search because when search is changed the user list should be reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  // handles search change
  useEffect(() => {
    async function fetchUsers() {
      const params = { page, usersPerPage: 15, filters: { role: ['basic', 'premium'] } }
      if (search) params.filters.cityOrState = search

      const { data } = await axios.get('/api/user/read-many', { params })

      const result = {
        users: data.users,
        premiumCount: data.premiumCount,
        basicCount: data.basicCount,
        totalCount: data.totalCount
      }
      // reset page to 1 and overwrite users
      setPage(1)
      setUsers(result.users)
      setHasMore(result.totalCount > users.length)
    }
    fetchUsers()
    // should not contain other deps because this should only run when search is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const fetchNextPage = () => {
    setPage(page + 1)
  }

  return { users, loading, search, setSearch, hasMore, fetchNextPage }
}

const CommunitySearchErrorUI = () => {
  return (
    <h1 className="w-full h-96 bg-white text-center py-12 rounded-lg mt-6 shadow text-xl">
      Oops! Something went wrong.
    </h1>
  )
}

export default Dashboard
