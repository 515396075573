import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../../components/general/PrimaryButton'
import FormField from '../../utils/FormField'
import ProfileUploaderFormField from '../uploader/ProfileUploaderFormField'
import { clearError, upsertUserAsync } from '../user/userSlice'
import { CheckCircleIcon } from '@heroicons/react/solid'

const Profile = () => {
  const dispatch = useDispatch()
  const { error } = useSelector((state) => state.user)
  const { currentUser } = useSelector((state) => state.auth)
  const [user, setUser] = useState(currentUser)
  const [savedSuccessfully, setSavedSuccessfully] = useState(false)

  useEffect(() => {
    setUser(currentUser)
  }, [currentUser])

  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    error && error[name] && dispatch(clearError({ field: name }))
    setUser((prevState) => ({ ...prevState, [name]: value }))
  }

  useEffect(() => {
    // chrome autofill password bug fix
    setTimeout(() => {
      setUser((prevstate) => ({ ...prevstate, password: '*' }))
      setUser((prevstate) => ({ ...prevstate, password: '' }))
    }, 1000)
  }, [])

  return (
    <div className="h-screen py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <div className="mt-10 sm:mt-0">
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 items-start">
              <div className="md:col-span-1 shadow-md px-16 py-6">
                <div className="w-full sm:w-52">
                  <div className="mt-1 flex items-center flex-col">
                    <ProfileUploaderFormField
                      field="photoUrl"
                      state={user}
                      setState={setUser}
                      buttonLabel="Upload New Avatar"
                      profile={true}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex-1 mt-5 md:mt-0 md:col-span-2">
                <form
                  onSubmit={async (event) => {
                    event.preventDefault()
                    const { type } = await dispatch(upsertUserAsync({ user }))
                    if ('user/upsert/fulfilled' === type) {
                      setSavedSuccessfully(true)
                      document.getElementById('main').scrollTo(0, 0)
                    }
                  }}
                >
                  <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="flex flex-col space-y-4">
                        <h1>Profile</h1>

                        {savedSuccessfully && (
                          <div className="rounded-md bg-green-50 p-4">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <CheckCircleIcon
                                  className="h-5 w-5 text-green-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-3">
                                <h3 className="text-sm font-medium text-green-800">
                                  your changes have been saved successfully
                                </h3>
                              </div>
                            </div>
                          </div>
                        )}

                        <FormField
                          label="Full Name"
                          field="fullName"
                          error={error && error['fullName']}
                          onChange={onChange}
                          state={user}
                        />

                        <FormField
                          label="Email Address"
                          field="email"
                          error={error && error['email']}
                          onChange={onChange}
                          disabled
                          autoComplete="username"
                          state={user}
                        />

                        <FormField
                          label="Password"
                          field="password"
                          type="password"
                          error={error && error['password']}
                          onChange={onChange}
                          state={user}
                          autoComplete="off"
                          required={false}
                        />

                        <FormField
                          label="City"
                          field="city"
                          error={error && error['city']}
                          onChange={onChange}
                          autoComplete="city"
                          state={user}
                          required={false}
                        />
                        <FormField
                          label="State"
                          field="state"
                          error={error && error['state']}
                          onChange={onChange}
                          autoComplete="state"
                          state={user}
                          required={false}
                        />

                        <FormField
                          label="Country"
                          field="country"
                          error={error && error['country']}
                          onChange={onChange}
                          autoComplete="country"
                          state={user}
                          required={false}
                        />

                        <FormField
                          label="Phone number"
                          field="phoneNumber"
                          error={error && error['phoneNumber']}
                          onChange={onChange}
                          autoComplete="phone"
                          state={user}
                          required={false}
                        />
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <PrimaryButton
                        type="submit"
                        extraClasses="uppercase"
                        label="Update Account"
                      ></PrimaryButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /End replace */}
      </div>
    </div>
  )
}

export default Profile
