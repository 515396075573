import Btn from "../general/Btn"
import LoadingSpinner from "../general/LoadingSpinner"
import { ALL_TAB, COURSES_TAB, enumCoursesTabs, LESSONS_TITLES_TAB, LESSONS_CONTENT_TAB, MODULES_TAB } from "./consts"

export const CountTab = ({
    isLoading,
    count
}) => {
    if (!isLoading) {
        return <span className="px-1.5">{count}</span>
    }
    return <LoadingSpinner color="black" />
}

export const CoursesTabs = ({
    activeTab = ALL_TAB,
    setActiveTab,
    coursesCount = 0,
    modulesCount = 0,
    lessonsTitlesCount = 0,
    lessonsContentCount = 0,
    isLoading = false
}) => {
    const getActiveTabClassname = (tab) => activeTab === tab ? "active-tab" : "non-active-tab";
    return (
        <section className="flex gap-4 flex-wrap justify-center md:justify-start">
            <Btn 
                variant={getActiveTabClassname(ALL_TAB)} 
                onClick={() => setActiveTab(ALL_TAB)}
                className={"inline-flex"}
            >
                {enumCoursesTabs[ALL_TAB]}
            </Btn>
            <Btn 
                variant={getActiveTabClassname(COURSES_TAB)} 
                onClick={() => setActiveTab(COURSES_TAB)}
                className={"inline-flex"}
            >
                {enumCoursesTabs[COURSES_TAB]} ( <CountTab count={coursesCount} isLoading={isLoading} /> )
            </Btn>
            <Btn 
                variant={getActiveTabClassname(MODULES_TAB)} 
                onClick={() => setActiveTab(MODULES_TAB)}
                className={"inline-flex"}
            >
                {enumCoursesTabs[MODULES_TAB]} ( <CountTab count={modulesCount} isLoading={isLoading} /> )
            </Btn>
            <Btn 
                variant={getActiveTabClassname(LESSONS_TITLES_TAB)} 
                onClick={() => setActiveTab(LESSONS_TITLES_TAB)}
                className={"inline-flex"}
            >
                {enumCoursesTabs[LESSONS_TITLES_TAB]} ( <CountTab count={lessonsTitlesCount} isLoading={isLoading} /> )
            </Btn>
            <Btn 
                variant={getActiveTabClassname(LESSONS_CONTENT_TAB)} 
                onClick={() => setActiveTab(LESSONS_CONTENT_TAB)}
                className={"inline-flex"}
            >
                {enumCoursesTabs[LESSONS_CONTENT_TAB]} ( <CountTab count={lessonsContentCount} isLoading={isLoading} /> )
            </Btn>
        </section>
    )
}