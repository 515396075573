import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/outline'

const UpdateModal = ({ open, onClose }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false)
  const handleClose = () => {
    if (dontShowAgain) localStorage.setItem('dontShowMTNUpgrade', 'true')
    onClose()
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={handleClose}
                >
                  <span className="sr-only">Close</span>
                  <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div className="flex flex-col items-center">
                <Dialog.Title as="h3" className="text-3xl mb-4">
                  Welcome To The New MTN Tool 2.0
                </Dialog.Title>

                <p className="mb-4">Click play to see how to activate your new tool:</p>

                <iframe
                  title="vimeo-player"
                  src="https://player.vimeo.com/video/756272619?h=f31e64b714"
                  width="640"
                  height="321"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>

                <label
                  htmlFor="dontShowAgain"
                  className="flex items-center mt-2 font-medium text-gray-700"
                >
                  <input
                    type="checkbox"
                    id="dontShowAgain"
                    className="mr-2 focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300 rounded"
                    value={dontShowAgain}
                    onChange={() => setDontShowAgain((s) => !s)}
                  />
                  Don't show again
                </label>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default UpdateModal
