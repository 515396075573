import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../../components/general/PrimaryButton'
import FormField from '../../utils/FormField'
import Select from 'react-select'

import { clearErrors, upsertUserAsync } from './userSlice'

const UserForm = ({ setState, setOpen, editingItem }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({})
  const { error } = useSelector((state) => state.user)
  const { tags } = useSelector((state) => state.tag)
  const { resources } = useSelector((state) => state.resource)
  const currentUser = useSelector((state) => state.auth.currentUser)
  // admin and coach can use this form, some fields are only for admin
  const isAdmin = currentUser?.role === 'admin'

  useEffect(() => {
    if (editingItem) setFormValues({ ...editingItem })
    else setFormValues({ position: 0 })
  }, [editingItem])

  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    error && error[name] && dispatch(clearErrors({ field: name }))
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  useEffect(() => {
    // chrome autofill password bug fix
    setTimeout(() => {
      setFormValues((prevstate) => ({ ...prevstate, password: '*' }))
      setFormValues((prevstate) => ({ ...prevstate, password: '' }))
    }, 1000)
  }, [])

  const onSubmit = async (event) => {
    event.preventDefault()
    const upsertResult = await dispatch(upsertUserAsync({ user: formValues }))
    const upsertSucceeded = upsertResult.type === 'user/upsert/fulfilled'
    if (upsertSucceeded) setOpen(false)
  }

  return (
    <div>
      <h3 className="text-2xl">
        {formValues?.id ? 'Edit user: ' + formValues.fullName : 'Add a new user'}
      </h3>
      <form onSubmit={onSubmit}>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          {isAdmin && (
            <div className="sm:col-span-3">
              <FormField
                label="Full name"
                field="fullName"
                error={error && error['fullName']}
                onChange={onChange}
                state={formValues}
              />
            </div>
          )}
          {isAdmin && (
            <div className="sm:col-span-3">
              <div>
                <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                  Role
                </label>
                <div className="mt-1">
                  <select
                    id="role"
                    name="role"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-450 focus:border-purple-450 sm:text-sm rounded-md"
                    value={formValues?.role}
                    onChange={onChange}
                  >
                    <option value="basic">Basic</option>
                    <option value="course only">Course Only</option>
                    <option value="premium">Premium</option>
                    <option value="coach">Coach</option>
                    <option value="admin">Admin</option>
                  </select>
                </div>
              </div>
            </div>
          )}

          {isAdmin && (
            <div className="sm:col-span-3">
              <FormField
                label="email"
                field="email"
                error={error && error['users_email']}
                onChange={onChange}
                state={formValues}
                autoComplete="username"
              />
            </div>
          )}
          {isAdmin && (
            <div className="sm:col-span-3">
              <FormField
                label="password"
                field="password"
                type="password"
                error={error && error['password']}
                onChange={onChange}
                state={formValues}
                required={false}
                autoComplete="current-password"
              />
            </div>
          )}

          {isAdmin && (
            <div className="sm:col-span-3">
              <label htmlFor="tags" className="block text-sm font-medium text-gray-700 mb-3">
                Always grant access
              </label>

              <div className="mt-1">
                <div className="relative flex items-center">
                  <div className="flex items-center h-5">
                    <input
                      id="alwaysGrantAccess"
                      name="alwaysGrantAccess"
                      type="checkbox"
                      className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300 rounded"
                      checked={formValues?.alwaysGrantAccess}
                      onChange={(event) => {
                        setFormValues((prevState) => ({
                          ...prevState,
                          alwaysGrantAccess: event.target.checked
                        }))
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="alwaysGrantAccess" className="font-medium text-gray-700">
                      Yes
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isAdmin && (
            <div className="sm:col-span-3">
              <FormField
                label="city"
                field="city"
                error={error && error['city']}
                onChange={onChange}
                state={formValues}
                required={false}
              />
            </div>
          )}
          {isAdmin && (
            <div className="sm:col-span-3">
              <FormField
                label="state"
                field="state"
                error={error && error['state']}
                onChange={onChange}
                state={formValues}
                required={false}
              />
            </div>
          )}
          {isAdmin && (
            <div className="sm:col-span-3">
              <FormField
                label="country"
                field="country"
                error={error && error['country']}
                onChange={onChange}
                state={formValues}
                required={false}
              />
            </div>
          )}

          <div className="sm:col-span-3">
            <label
              htmlFor="tags"
              className="block text-sm font-medium text-gray-700"
              error={error && error['country']}
              onChange={onChange}
              state={formValues}
            >
              User Tags
            </label>
            <div className="mt-1">
              <Select
                id="tags"
                isMulti={true}
                value={formValues?.Tags?.map((tag) => ({
                  value: tag.id,
                  label: tag.name
                }))}
                onChange={(Tags) =>
                  setFormValues((prevState) => ({
                    ...prevState,
                    Tags: Tags.map((tag) => ({
                      id: tag.value,
                      name: tag.label
                    }))
                  }))
                }
                options={tags.map((tag) => ({
                  value: tag.id,
                  label: tag.name
                }))}
              />
            </div>
          </div>

          {isAdmin && (
            <div className="sm:col-span-3">
              <label
                htmlFor="owned"
                className="block text-sm font-medium text-gray-700"
                error={error && error['country']}
                onChange={onChange}
                state={formValues}
              >
                Owned Resources
              </label>
              <div className="mt-1">
                <Select
                  id="owned"
                  isMulti={true}
                  value={formValues?.Resources?.map((resource) => ({
                    value: resource.id,
                    label: resource.title
                  }))}
                  onChange={(Resources) =>
                    setFormValues((prevState) => ({
                      ...prevState,
                      Resources: Resources.map((resource) => ({
                        id: resource.value,
                        title: resource.label
                      }))
                    }))
                  }
                  options={resources.map((resource) => ({
                    value: resource.id,
                    label: resource.title
                  }))}
                />
              </div>
            </div>
          )}
        </div>

        <div className="mt-24 py-3 sm:flex sm:justify-between">
          <div>
            {editingItem?.id && (
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                onClick={() => setState('delete')}
              >
                Delete
              </button>
            )}
          </div>
          <div className="flex">
            <PrimaryButton extraClasses="sm:w-auto sm:ml-3" label="Save" type="submit" />

            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UserForm
