import { Link } from "react-router-dom"

const Breadcrumbs = ({ breadcrumbs = [], separator = "/" }) => {
    return (
        <div>
            {breadcrumbs.map((item, index) => 
            <div className="inline-block" key={index}>
                <Link className="text-sm text-gray-500 leading-18 hover:text-secondary" to={item.href}>{item.name}</Link>
                { index !== breadcrumbs.length - 1 ? <span className="px-2 text-gray-500 text-sm leading-18">{separator}</span> : null }
            </div>
            )}
        </div>
    )
}

export default Breadcrumbs
