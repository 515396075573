import { getDetailInformationMessage } from '../../helpers';
import CourseCard from './CourseCard';

const CourseCardItem = ({ courseItem, isReorderingMode, onClickEdit, isAdmin }) => {
  return (
    <CourseCard
      isEditAllowed={isAdmin}
      isReorderingMode={isReorderingMode}
      onClickEdit={onClickEdit}
      countCompleted={courseItem.totalProgress || 0}
      title={courseItem?.title}
      detailInformationMessage={isAdmin ? getDetailInformationMessage(courseItem) : ''}
      description={courseItem?.description}
      status={courseItem?.status}
      image={courseItem?.coverPhotoUrl}
      slug={courseItem?.slug}
      showProgress={
        courseItem?.Modules && courseItem?.Modules.length && (courseItem.totalProgress || 0) < 100
      }
    />
  )
}

export default CourseCardItem
