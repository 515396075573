const Range = (props) => {
  const { min, max, fixedPoints = 0, minLabel, maxLabel, value, onChange, prefix, suffix } = props

  return (
    <div className="w-full max-w-2xl relative mt-4">
      <span
        className="text-white bg-purple-450 text-xs rounded-full py-1.5 w-16 text-center absolute top-0"
        style={{
          left: `calc(${((Math.min(value, max) - min) / (max - min)) * 100}% - ${
            27.5 + (((Math.min(value, max) - min) / (max - min)) * 100) / 10
          }px)`
        }}
      >
        {prefix || null}
        {value.toFixed(fixedPoints)}
        {suffix || null}
      </span>
      <input
        type="range"
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        step={1 / 10 ** fixedPoints}
        className="slider mt-10"
        min={min}
        max={max}
      />
      <div className="flex justify-between">
        {minLabel ? <span>{minLabel}</span> : null}
        {maxLabel ? <span>{maxLabel}</span> : null}
      </div>
    </div>
  )
}

export default Range
