import React from 'react'
import {
  SearchIcon
} from '@heroicons/react/outline';

const ResourceSearch = ({ search, setSearch, placeholder = "Search" }) => {
  return (
    <div>
      <div className="relative rounded-md">
        <div className="absolute inset-y-0 left-0 pl-3.5 flex items-center pointer-events-none text-dark-300">
          <SearchIcon className="w-4 h-4 text-dark-300" />
        </div>
        <input
          type="search"
          name="search"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          className="text-base sm:text-base leading-24 text-dark font-normal font-base tracking-0.5 placeholder-dark-300 border border-solid border-white-700 bg-white focus:ring-secondary focus:border-secondary block w-full pl-9 py-1.75 pr-3.5 sm:text-sm border-gray-300 rounded-md"
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}

export default ResourceSearch
