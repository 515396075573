import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getCourseContentAsync } from '../courses-list/coursesListSlice';

const initialState = {
  loading: true,
  isFirstLoadComplete: false,
  error: null,
  modules: [],
  lessons: [],
  lessonOptions: [],
  completedLessons: [],
  activeModule: null,
  activeLesson: null
}

export const useCourseModules = () => {
  const dispatch = useDispatch()
  const { modules, isFirstLoadComplete } = useSelector((state) => state.course)

  // if modules were not already loaded, load them
  useEffect(() => {
    if (!isFirstLoadComplete) dispatch(readManyModulesAsync())
  }, [dispatch, isFirstLoadComplete])

  function refreshModules() {
    dispatch(readManyModulesAsync())
  }

  return { modules, refreshModules }
}

export const readManyModulesAsync = createAsyncThunk('module/readMany', async () => {
  const { data } = await axios.get('/api/module/read-many')
  return { modules: data.modules }
})

export const readLessonOptionsAsync = createAsyncThunk('lesson/readOptions', async () => {
  const { data } = await axios.get('/api/lesson/read-options')
  return { lessonOptions: data.lessons }
})

export const getCompletedLessonsAsync = createAsyncThunk('lesson/completed', async () => {
  const { data } = await axios.get('/api/lesson/completed')
  return { lessons: data.lessons }
})

export const reorderModulesAsync = createAsyncThunk(
  'module/reorder',
  async ({ modules }, { dispatch }) => {
    try {
      // Fix error 413 payload too large
      const idPosition = modules.map((module) => {
        return { id: module.id, position: module.position }
      })
      const res = await axios.post('/api/module/reorder', { modules: idPosition })
      if (res.data?.success) {
        dispatch(readManyModulesAsync())
        toast.success('Module reordered successfully')
      } else {
        throw new Error('Something went wrong')
      }
    } catch (error) {
      toast.error(error)
    }
  }
)

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null
    },
    clearErrors: (state) => {
      state.error = null
      state.generalError = null
    },
    setError: (state, action) => {
      state.error[action.payload.field] = action.payload.error
    },
    setActiveLesson: (state, action) => {
      state.activeLesson = action.payload.lesson
    },
    setActiveModule: (state, action) => {
      state.activeModule = action.payload.module
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(readManyModulesAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(readManyModulesAsync.fulfilled, (state, action) => {
        state.loading = false
        state.isFirstLoadComplete = true
        state.modules = action.payload.modules
      })
      .addCase(readManyModulesAsync.rejected, (state, action) => {
        state.loading = false
      })

      .addCase(getCompletedLessonsAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(getCompletedLessonsAsync.fulfilled, (state, action) => {
        state.loading = false
        state.completedLessons = action.payload.lessons
      })
      .addCase(getCompletedLessonsAsync.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(readLessonOptionsAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(readLessonOptionsAsync.fulfilled, (state, action) => {
        state.loading = false
        state.lessonOptions = action.payload.lessonOptions
      })
      .addCase(readLessonOptionsAsync.rejected, (state, action) => {
        state.loading = false
      })
  }
})
export const { clearError, clearErrors, setError, setActiveLesson, setActiveModule } =
  courseSlice.actions
export default courseSlice.reducer
