import classNames from 'classnames';
import { Link } from 'react-router-dom';

const BoxCard = ({ children, link }) => {
  return (
    <div 
      data-testid="box-card"
      className={classNames([
        'bg-white rounded-2xl border border-solid border-primary border-opacity-20 shadow-box px-5 pb-5 pt-4 flex flex-col h-full overflow-hidden',
        {
          'relative group': link
        }
      ])}
    >
      {link && (
        <Link
          to={link}
          className="absolute top-0 left-0 w-full h-full z-3"
        ></Link>
      )}
      {children}
    </div>
  )
}

export default BoxCard
