import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'
import { removeLoadingKey } from '../../helpers'

export const ProductLoadingKey = {
  products: "ALL_PRODUCTS",
  count: "COUNT_PRODUCTS"
}

const initialState = {
  loading: true,
  loadingKeys: [],
  countProductsLoading: false,
  error: null,
  products: [],
  productsTotal: 0
}

export const readManyProductsAsync = createAsyncThunk('product/readMany', async () => {
  const {
    data: { products }
  } = await axios.get('/api/product/read-many')
  return { products }
})

export const countProductsAsync = createAsyncThunk('product/count', async () => {
  const {
    data: { total }
  } = await axios.get('/api/product/count')
  return { total }
})

export const upsertProductAsync = createAsyncThunk(
  'product/upsert',
  async ({ product, typeAdditionalFees }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`/api/product/upsert`, { product, typeAdditionalFees })
      return response.data
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors)
      }
    }
  }
)

export const deleteProductAsync = createAsyncThunk('product/delete', async ({ id }) => {
  await axios.delete(`/api/product/${id}`)
  return { id }
})

export const powerProfitToolSlice = createSlice({
  name: 'powerProfitTool',
  initialState,
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null
    },
    clearErrors: (state) => {
      state.error = null
      state.generalError = null
    },
    setError: (state, action) => {
      state.error[action.payload.field] = action.payload.error
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(readManyProductsAsync.pending, (state) => {
        state.loading = true
        state.loadingKeys = [...state.loadingKeys, ProductLoadingKey.products]
      })
      .addCase(readManyProductsAsync.fulfilled, (state, action) => {
        state.products = action.payload.products
        state.loadingKeys = removeLoadingKey(ProductLoadingKey.products, state.loadingKeys)
      })
      .addCase(readManyProductsAsync.rejected, (state, action) => {
        state.loading = false
        state.loadingKeys = removeLoadingKey(ProductLoadingKey.users, state.loadingKeys)
      })
      .addCase(countProductsAsync.pending, (state) => {
        state.loading = true
        state.loadingKeys = [...state.loadingKeys, ProductLoadingKey.count]
      })
      .addCase(countProductsAsync.fulfilled, (state, action) => {
        state.productsTotal = action.payload.total
        state.loading = false
        state.loadingKeys = removeLoadingKey(ProductLoadingKey.count, state.loadingKeys)
      })
      .addCase(countProductsAsync.rejected, (state, action) => {
        state.loading = false
        state.loadingKeys = removeLoadingKey(ProductLoadingKey.count, state.loadingKeys)
      })
      .addCase(upsertProductAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertProductAsync.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.isNew) {
          // insert
          state.products.unshift(action.payload.product)
          toast.success('Product created successfully')
        } else {
          // update
          state.products = state.products.map((product) => {
            return product.id === action.payload.product.id ? action.payload.product : product
          })
          toast.success('Product saved successfully')
        }
      })
      .addCase(upsertProductAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(deleteProductAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteProductAsync.fulfilled, (state, action) => {
        state.loading = false
        state.products = state.products.filter((product) => product.id !== action.payload.id)
        toast.success('Product deleted successfully')
      })
      .addCase(deleteProductAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
  }
})
export const { clearError, clearErrors, setError } = powerProfitToolSlice.actions
export default powerProfitToolSlice.reducer
