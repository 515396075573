import React, { useEffect, useState } from 'react';
import FormField from '../../utils/FormField';
import ProfileUploaderFormField from '../uploader/ProfileUploaderFormField';
import PrimaryButton from '../../components/general/PrimaryButton';
import DeleteButton from '../../components/general/DeleteButton'
import SecondaryButton from '../../components/general/SecondaryButton';
import { upsertCourseAsync } from './coursesListSlice';
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { useCourseModules } from '../course/courseSlice';

function Checkbox({ value, onChange, label, name }) {
    return (
      <div className="flex items-center h-5">
        <input
          id={name}
          name={name}
          type="checkbox"
          className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300 rounded cursor-pointer"
          checked={value || false} // default to false to avoid warning in case undefined was passed
          onChange={onChange}
        />
        <div className="ml-3 text-sm">
          <label htmlFor={name} className="font-medium text-gray-700 cursor-pointer select-none">
            {label}
          </label>
        </div>
      </div>
    )
  }

const CourseForm = ({ 
    setOpen, 
    editingItem, 
    search,
    setState,
}) => {
    const dispatch = useDispatch()
    const [formValues, setFormValues] = useState({ visibleToBasic: true, visibleToPremium: true });
    const { tags } = useSelector((state) => state.tag)
    const { modules, refreshModules } = useCourseModules();

    const onChange = (event) => {
        event.preventDefault()
        let { name, value } = event.target;
        setFormValues((prevState) => ({ ...prevState, [name]: value }))
    }

    useEffect(() => {
        if (editingItem) {
            setFormValues({ ...editingItem })
        } else {
            //   modules[0] &&
            //     setFormValues((prevState) => ({
            //       ...prevState,
            //       ModuleId: modules[0].id,
            //       position: 0
            //     }))
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <h3 className="text-2xl">{editingItem ? 'Edit course' : 'Add New Course'}</h3>
            <form
                onSubmit={async (event) => {
                    event.preventDefault()
                    await dispatch(upsertCourseAsync({ course: formValues, search }))
                    refreshModules()
                    setOpen(false)
                }}
            >
                <div className="grid sm:grid-cols-2 gap-y-6 gap-x-4">
                    <div className="col-span-full">
                        <FormField
                            label="Title"
                            field="title"
                            //error={'title erroe'}
                            onChange={onChange}
                            state={formValues}
                        />
                    </div>
                    <div className="col-span-full">
                        <FormField
                            type="textarea"
                            label="Description"
                            field="description"
                            required={false}
                            //error={'error Description'}
                            onChange={onChange}
                            state={formValues}
                        />
                    </div>
                    <div>
                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                            Status
                        </label>
                        <select
                            id="status"
                            value={formValues?.status}
                            onChange={(event) => 
                                setFormValues((prevState) => ({
                                    ...prevState,
                                    status: event.target.value
                                }))
                            }
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-450 focus:border-purple-450 sm:text-sm rounded-md"
                        >
                            <option>DRAFT</option>
                            <option>PUBLISHED</option>
                        </select>
                    </div>
                    <div>
                        <FormField
                            label="Slug"
                            field="slug"
                            //error={'error Description'}
                            onChange={onChange}
                            state={formValues}
                        />
                    </div>
                    <div className="col-span-full">
                        <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                            Required Tags
                        </label>
                        <div className="mt-1">
                            <Select
                                id="tags"
                                isMulti={true}
                                value={formValues?.Tags?.map((tag) => ({
                                    value: tag.id,
                                    label: tag.name
                                }))}
                                onChange={(Tags) =>
                                    setFormValues((prevState) => ({
                                        ...prevState,
                                        Tags: Tags.map((tag) => ({
                                            id: tag.value,
                                            name: tag.label
                                        }))
                                    }))
                                }
                                options={tags.map((tag) => ({
                                    value: tag.id,
                                    label: tag.name
                                }))}
                            />
                        </div>
                    </div>
                    <div className="col-span-full">
                        <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                            Course Module
                        </label>
                        <div className="mt-1">
                            <Select
                                id="tags"
                                isMulti={true}
                                value={formValues?.Modules?.map((module) => ({
                                    value: module.id,
                                    label: module.title
                                }))}
                                onChange={(Modules) =>
                                    setFormValues((prevState) => ({
                                        ...prevState,
                                        Modules: Modules.map((module) => ({
                                            id: module.value,
                                            title: module.label
                                        }))
                                    }))
                                }
                                options={modules
                                    .filter((module) => !module.CourseId || module.CourseId === editingItem?.id)
                                    .map((module) => ({
                                        value: module.id,
                                        label: module.title
                                    }))
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <ProfileUploaderFormField
                            field="coverPhotoUrl"
                            state={formValues}
                            setState={setFormValues}
                            buttonLabel="Upload New Course Picture"
                        />
                    </div>
                    <div className="space-y-1">
                        <Checkbox
                            value={formValues.visibleToBasic}
                            onChange={(event) => 
                                setFormValues((prevState) => ({
                                    ...prevState,
                                    visibleToBasic: event.target.checked
                                }))}
                            label="Visible to basic users"
                            name="visibleToBasic"
                        />
                        <Checkbox
                            value={formValues.visibleToPremium}
                            onChange={(event) => 
                                setFormValues((prevState) => ({
                                    ...prevState,
                                    visibleToPremium: event.target.checked
                                }))}
                            label="Visible to premium users"
                            name="visibleToPremium"
                        />
                    </div>

                </div>
                <div className="mt-24 py-3 flex flex-row flex-wrap gap-2 sm:gap-4 justify-between">
                    <div className="flex justify-start w-full sm:w-auto">
                        {editingItem?.id && (
                            <DeleteButton label="Delete" extraClasses="uppercase w-full sm:w-auto" onClick={() => setState('delete')} />
                        )}
                    </div>
                    <div className="flex gap-2 sm:gap-4 w-full sm:w-auto justify-end">
                        <PrimaryButton extraClasses="uppercase w-full sm:w-auto" label="Save" type="submit" />
                        <SecondaryButton
                            extraClasses="uppercase w-full sm:w-auto"
                            label="Cancel"
                            onClick={() => setOpen(false)}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}

export default CourseForm;