import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../../components/general/PrimaryButton'
import FormField from '../../utils/FormField'
import { clearError, upsertProductAsync } from './powerProfitToolSlice'
import ReactTooltip from 'react-tooltip'

const PromotionForm = ({ setState, setOpen, editingItem }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({})
  const { error } = useSelector((state) => state.powerProfitTool)

  useEffect(() => {
    if (editingItem) {
      setFormValues({ ...editingItem })
    } else {
      setFormValues({ position: 0 })
    }
  }, [editingItem])

  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    error && error[name] && dispatch(clearError({ field: name }))
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <div>
      <h3 className="text-2xl">Promotion and Advertising Planning</h3>

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          await dispatch(upsertProductAsync({ product: formValues }))
          setOpen(false)
        }}
      >
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <div className="mt-1">
              <FormField
                label="Title"
                field="title"
                error={error && error['title']}
                onChange={onChange}
                state={formValues}
              />
            </div>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <div className="mt-1">
              <FormField
                label="Promotion Units per Day"
                field="promotionUnitsPerDay"
                error={error && error['promotionUnitsPerDay']}
                onChange={onChange}
                state={formValues}
                tooltip="promotion-units-per-day-tooltip"
                type="number"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <div className="mt-1">
              <FormField
                label="Promotion Percentage"
                field="promotionPercentage"
                error={error && error['promotionPercentage']}
                onChange={onChange}
                state={formValues}
                tooltip="promotion-percentage-tooltip"
                type="number"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <div className="mt-1">
              <FormField
                label="Promotion Days"
                field="promotionDays"
                error={error && error['promotionDays']}
                onChange={onChange}
                state={formValues}
                tooltip="promotion-days-tooltip"
                type="number"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <div className="mt-1">
              <FormField
                label="Daily Advertising Cost"
                field="dailyAdvertisingCost"
                error={error && error['dailyAdvertisingCost']}
                onChange={onChange}
                state={formValues}
                tooltip="daily-advertising-cost-tooltip"
                type="number"
              />
            </div>
          </div>
        </div>

        <div className="mt-24 py-3 flex flex-row space-x-4 sm:justify-between">
          <div className="w-1/3">
            {editingItem?.id && (
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1.5 sm:py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                onClick={() => setState('delete')}
              >
                Delete
              </button>
            )}
          </div>
          <div className="w-2/3 flex flex-1 space-x-4 sm:flex-grow-0 items-start">
            <PrimaryButton extraClasses="px-8" label="Save" type="submit" />

            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-1.5 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default PromotionForm
