import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'
import { removeLoadingKey } from '../../helpers'

export const TagsLoadingKeys = {
  tags: "ALL_TAGS"
}

const initialState = {
  loading: true,
  loadingKeys: [],
  error: null,
  tags: []
}

export const readManyTagsAsync = createAsyncThunk('tag/readMany', async () => {
  const { data } = await axios.get('/api/tag/read-many')
  return { tags: data.tags }
})

export const upsertTagAsync = createAsyncThunk(
  'tag/upsert',
  async ({ tag }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/api/tag/upsert`, tag)
      return data
    } catch (error) {
      if ('string' === typeof error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.response.data.errors)
      }
    }
  }
)

export const deleteTagAsync = createAsyncThunk('tag/delete', async ({ tag: { id } }) => {
  await axios.delete(`/api/tag/${id}`)
  return { id }
})

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null
    },
    clearErrors: (state) => {
      state.error = null
    },
    setError: (state, action) => {
      state.error[action.payload.field] = action.payload.error
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(readManyTagsAsync.pending, (state) => {
        state.loading = true
        state.loadingKeys = [...state.loadingKeys, TagsLoadingKeys.tags]
      })
      .addCase(readManyTagsAsync.fulfilled, (state, action) => {
        state.tags = action.payload.tags
        state.loadingKeys = removeLoadingKey(TagsLoadingKeys.tags, state.loadingKeys)
      })
      .addCase(readManyTagsAsync.rejected, (state, action) => {
        state.loading = false
        state.loadingKeys = removeLoadingKey(TagsLoadingKeys.tags, state.loadingKeys)
      })
      .addCase(upsertTagAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(upsertTagAsync.fulfilled, (state, action) => {
        state.loading = false
        console.log('hello', action.payload)
        if (action.payload.isNew) {
          // insert

          state.tags.push(action.payload.tag)
        } else {
          // update
          state.tags = state.tags.map((tag) => {
            if (tag.id === action.payload.tag.id) {
              tag = Object.assign(tag, action.payload.tag)
            }
            return tag
          })
        }
        toast.success('Tag saved successfully')
      })
      .addCase(upsertTagAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(deleteTagAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteTagAsync.fulfilled, (state, action) => {
        state.loading = false
        state.tags = state.tags.filter((tag) => tag.id !== action.payload.id)
      })
      .addCase(deleteTagAsync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
  }
})
export const { clearError, clearErrors, setError } = tagSlice.actions
export default tagSlice.reducer
