import classNames from "classnames"
import {
    ArrowUpIcon
} from "@heroicons/react/outline";
import { useBackToTop } from "../../helpers";
import { useSelector } from "react-redux";

export const BackToTop = ({
    isLoading,
}) => {
    const { rainaChatIsVisible } = useSelector((state) => state.auth)
    useBackToTop(isLoading);
    return (
        <div className={classNames([
            "fixed bg-secondary z-10 p-2 raina-min:p-4 rounded-full cursor-pointer hidden bottom-4 raina-min:bottom-7",
            {
                "right-16 raina-min:right-26" : rainaChatIsVisible,
                "right-4": !rainaChatIsVisible
            }
        ])} id="scroll-to-top-button">
            <ArrowUpIcon className="w-7 h-7 text-white" />
        </div>
    )
}