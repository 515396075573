

const UNITS = "units"
const COST_PER_UNIT = "costPerUnit"
const SHIPPING_COST = "shippingCost"
const AMAZON_RETAIL = "amazonRetail"
const ADDITIONAL_FEES = "additionalFees"
const TOTAL_ADDITIONAL_FEES = "totalAdditionalFees"
const TOTAL_ESTIMATED_PROFIT_PER_UNIT = "totalEstimatedProfitPerUnit"
const TOTAL_ESTIMATED_BULK_ORDER_PROFIT = "totalEstimatedBulkOrderProfit"

const PERCENTAGE = 'percentage'
const DOLLAR = 'dollar'

exports.UNITS = UNITS
exports.COST_PER_UNIT = COST_PER_UNIT
exports.SHIPPING_COST = SHIPPING_COST
exports.DOLLAR = DOLLAR
exports.PERCENTAGE = PERCENTAGE
exports.AMAZON_RETAIL = AMAZON_RETAIL
exports.ADDITIONAL_FEES = ADDITIONAL_FEES
exports.TOTAL_ADDITIONAL_FEES = TOTAL_ADDITIONAL_FEES
exports.TOTAL_ESTIMATED_PROFIT_PER_UNIT = TOTAL_ESTIMATED_PROFIT_PER_UNIT
exports.TOTAL_ESTIMATED_BULK_ORDER_PROFIT = TOTAL_ESTIMATED_BULK_ORDER_PROFIT