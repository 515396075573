
import React from 'react'

const DeleteButton = ({
  label,
  type = 'button',
  onClick = () => {},
  testId,
  extraClasses,
  ...rest
}) => {

  return (
    <button
      type={type}
      data-testid={testId}
      className={`border border-transparent shadow-sm px-4 py-2 bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 rounded-md ${extraClasses}`}
      onClick={onClick}
      {...rest}
    >
      {label}
    </button>
  )
}

export default DeleteButton
