import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../../components/general/PrimaryButton'
import FormField from '../../utils/FormField'
import { clearError, upsertResourceCategoryAsync } from './resourceSlice'
import SecondaryButton from '../../components/general/SecondaryButton'

const ResourceCategoryForm = ({ setState, setOpen, editingItem }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({})
  const { error } = useSelector((state) => state.resource)

  useEffect(() => {
    if (editingItem) {
      setFormValues({ ...editingItem })
    } else {
      setFormValues({})
    }
  }, [editingItem])

  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    error && error[name] && dispatch(clearError({ field: name }))
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  return (
    <div>
      <h3 className="text-2xl">
        {formValues?.id ? 'Edit category: ' + formValues.name : 'Add a new category'}
      </h3>

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          await dispatch(upsertResourceCategoryAsync({ category: formValues }))
          setOpen(false)
        }}
      >
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <div className="mt-1">
              <FormField
                label="Name"
                field="name"
                error={error && error['name']}
                onChange={onChange}
                state={formValues}
              />
            </div>
          </div>
        </div>

        <div className="mt-24 py-3 flex flex-row space-x-4 sm:justify-between">
          <div className="w-1/3">
            {editingItem?.id && (
              <button
                type="button"
                className="uppercase w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1.5 sm:py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                onClick={() => setState('delete')}
              >
                Delete
              </button>
            )}
          </div>
          <div className="w-2/3 flex flex-1 space-x-4 sm:flex-grow-0 items-start">
            <PrimaryButton extraClasses="px-8 uppercase" label="Save" type="submit" />

            <SecondaryButton
              label="Cancel"
              extraClasses="uppercase"
              onClick={() => setOpen(false)}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ResourceCategoryForm
