import React, { useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { clearStripeError, createPaymentIntentAsync } from '../stripe/stripeSlice'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../../components/general/PrimaryButton'

const PaymentFormField = ({ setOpen, editingItem, setPaymentStatus, onPaymentSuccess }) => {
  const dispatch = useDispatch()

  const [stripeError, setStripeError] = useState(null)
  const [stripeCCEmpty, setStripeCCEmpty] = useState(true)
  const [stripeLoading, setStripeLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const { currentUser } = useSelector((state) => state.auth)

  const handleStripeChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    //console.log(event);
    setStripeCCEmpty(event.empty)
    setStripeError(event.error ? event.error.message : null)
    dispatch(clearStripeError())
  }

  const onSubmit = async (event) => {
    //dispatch(clearErrors());

    setStripeLoading(true)

    // Step 1: create a Payment Method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)
    })

    if (error) {
      console.log(error)
    } else {
      console.log('token generated!', paymentMethod)

      const { id } = paymentMethod

      const result = await dispatch(
        createPaymentIntentAsync({
          paymentMethodId: id,
          userId: currentUser.id,
          resourceId: editingItem.id
        })
      )

      if ('stripe/createPaymentIntent/fulfilled' === result.type) {
        setPaymentStatus('success')
        await onPaymentSuccess()
      }

      setStripeLoading(false)
    }
  }

  return (
    <>
      <div>
        {/* good: 4242 4242 4242 4242 / bad: 4000 0000 0000 9995 */}

        {
          <>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '14px'
                  },
                  invalid: {
                    color: '#9e2146'
                  }
                }
              }}
              onChange={handleStripeChange}
            />
            {stripeError ? <span className="text-red-500 text-xs pt-1">{stripeError}</span> : null}
            {stripeError && stripeCCEmpty ? (
              <span className="text-red-500 text-xs pt-1">Please enter a card</span>
            ) : null}
          </>
        }
      </div>
      <div className="w-full mt-12 py-3 flex flex-1 space-x-4 items-middle justify-end">
        <PrimaryButton
          label={
            <>
              Purchase&nbsp;
              <b className="font-bold">for ${parseFloat(editingItem?.price).toFixed(2)}</b>
            </>
          }
          extraClasses="px-8"
          type="button"
          onClick={async () => onSubmit()}
          disabled={stripeLoading}
        />

        <button
          type="button"
          className="w-40 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-1.5 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default PaymentFormField
