import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearGeneralError, resetPasswordAsync, setGeneralError } from './authSlice'
import { XCircleIcon } from '@heroicons/react/solid'
import FormField from '../../utils/FormField'
import { useParams } from 'react-router-dom'
import RainmakersLogo from '../../components/general/RainmakersLogo'

const ResetPassword = () => {
  const history = useHistory()
  const { token } = useParams()

  const [passwords, setPasswords] = useState({})

  const { generalError } = useSelector((state) => state.auth)

  const dispatch = useDispatch()

  const onChange = (event) => {
    const { name, value } = event.target
    setPasswords((prevState) => ({ ...prevState, [name]: value }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(clearGeneralError())
    if (passwords['password'] !== passwords['confirmPassword']) {
      dispatch(setGeneralError({ error: 'Passwords do not match' }))
      return
    }
    dispatch(resetPasswordAsync({ password: passwords['password'], token }))
    if (!generalError) {
      history.push('/')
    }
  }

  useEffect(() => {
    return () => {
      if (generalError) {
        dispatch(clearGeneralError())
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 px-6 lg:px-8">
      <div className="mx-auto w-full max-w-lg">
        <RainmakersLogo />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Reset password</h2>
      </div>

      <div className="mt-8 mx-auto w-full max-w-lg">
        <div className="bg-white py-8 shadow rounded-lg px-10">
          {generalError ? (
            <div className="rounded-md bg-red-50 p-4 mb-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{generalError}</h3>
                </div>
              </div>
            </div>
          ) : null}
          <form className="space-y-6" onSubmit={onSubmit}>
            <FormField
              label="Password"
              field="password"
              type="password"
              onChange={onChange}
              autoComplete="current-password"
              state={passwords}
            />
            <FormField
              label="Confirm Password"
              field="confirmPassword"
              type="password"
              onChange={onChange}
              autoComplete="current-password"
              state={passwords}
            />

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <Link to="/signup" className="font-medium text-purple-450 hover:text-purple-450">
                  Create new account
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-450 hover:bg-purple-450 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
