import React, { Suspense } from 'react'
import LoadingSpinner from './LoadingSpinner'

const Markdown = React.lazy(() =>
  import('@uiw/react-md-editor').then((module) => ({ default: module.default.Markdown }))
)

// updates the markdown to open links in a new tab
export const rehypeRewrite = (node) => {
  if (node.type === 'element' && node.tagName === 'a') {
    node.properties = { ...node.properties, target: '_blank', rel: 'noreferrer' }
  }
  if (node.type === 'raw' && node.value.includes('<a ')) {
    node.value = node.value.replaceAll('<a ', '<a target="_blank" rel="noreferrer" ');
  }
}

const hasAnchor = (withoutAnchors, node) => {
  const nodeProps = node.properties
  return withoutAnchors && node.type === 'element' && node.tagName === 'a' && nodeProps?.href && nodeProps.href.startsWith("#")
}

const MarkdownPreview = ({ markdown, withoutAnchors }) => {
  const customRehypeRewrite = (node, index, parent) => {
    if (hasAnchor(withoutAnchors, node)) {
      parent.children[index] = { type: 'text', value: node.children[0].value || '' };
    } else {
      rehypeRewrite(node);
    }
  };
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Markdown source={markdown} rehypeRewrite={customRehypeRewrite} />
    </Suspense>
  )
}

export default MarkdownPreview