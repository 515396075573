import { CheckIcon, PencilIcon } from '@heroicons/react/outline'
import React from 'react'
import { useDispatch } from 'react-redux'
import PrimaryButton from '../../../components/general/PrimaryButton'
import { addLabelToCardAsync, removeLabelFromCardAsync } from '../moveTheNeedleSlice'

const LabelList = ({ labels, card, setEditingLabel }) => {
  const dispatch = useDispatch()
  return (
    <div>
      <ul className="flex flex-col space-y-1">
        {labels?.map((label) => (
          <li key={label.color}>
            <div
              className={`flex justify-between items-center px-3 py-1.5 rounded-md text-xs bg-${label.color} text-white cursor-pointer`}
              onClick={() => {
                if (card.Labels.find((l) => l.id === label.id)) {
                  dispatch(
                    removeLabelFromCardAsync({
                      LabelId: label.id,
                      CardId: card.id
                    })
                  )
                } else {
                  dispatch(
                    addLabelToCardAsync({
                      LabelId: label.id,
                      CardId: card.id
                    })
                  )
                }
              }}
            >
              <span className="flex">
                {(card?.Labels || []).find((l) => l.color === label.color) && (
                  <CheckIcon className="h-4 w-4 mr-1" />
                )}
                {label.name}
              </span>
              <PencilIcon
                className="h-4 w-4"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setEditingLabel(label)
                }}
              />
            </div>
          </li>
        ))}
      </ul>

      {labels.length < 11 && (
        <PrimaryButton
          label="Create new label"
          extraClasses="uppercase mt-4 w-full"
          onClick={() => {
            setEditingLabel(true)
          }}
        />
      )}
    </div>
  )
}

export default LabelList
