import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { clearError } from '../user/userSlice'
import FormField from '../../utils/FormField'
import { clearGeneralError, loginAsync } from './authSlice'
import { XCircleIcon } from '@heroicons/react/solid'
import RainmakersLogo from '../../components/general/RainmakersLogo'

export default function SignIn() {
  const { error } = useSelector((state) => state.user)
  const { generalError } = useSelector((state) => state.auth)
  const [user, setUser] = useState({})
  const dispatch = useDispatch()
  const onSubmit = async (event) => {
    event.preventDefault()
    dispatch(loginAsync({ user }))
  }
  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    error && error[name] && dispatch(clearError({ field: name }))
    setUser((prevState) => ({ ...prevState, [name]: value }))
  }

  useEffect(() => {
    return () => {
      if (generalError) {
        dispatch(clearGeneralError())
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 px-6 lg:px-8">
      <div className="mx-auto w-full max-w-lg">
        <RainmakersLogo />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Rainmakers App: Sign In
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Welcome to The Rainmaker Family, the #1 place for moms to make passive income online.
          You're now entering our Rainmaker App, an online ecosystem of trainings, tools and
          resources to help you do more in less time.
        </p>
        <p className="mt-2 text-center text-sm text-gray-600">
          For a 7 Day training on how to use this app and the tools inside, consider joining our
          next
          <a
            className="text-base text-purple-450 hover:opacity-80 px-1 py-1 cursor-pointer"
            href="http://therainmakerchallenge.com"
            target="_blank"
            rel="noreferrer"
          >
            Rainmaker Challenge.
          </a>
        </p>
      </div>

      <div className="mt-8 mx-auto w-full max-w-lg">
        <div className="bg-white py-8 shadow rounded-lg px-10">
          {generalError ? (
            <div className="rounded-md bg-red-50 p-4 mb-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{generalError}</h3>
                </div>
              </div>
            </div>
          ) : null}
          <form className="space-y-6" onSubmit={onSubmit}>
            <FormField
              label="Email Address"
              field="email"
              error={error && error['email']}
              onChange={onChange}
              autoComplete="username"
              state={user}
            />

            <div>
              <FormField
                label="Password"
                field="password"
                type="password"
                error={error && error['password']}
                onChange={onChange}
                autoComplete="current-password"
                state={user}
              />
              <Link
                to="/forgot-password"
                className="text-sm font-medium text-purple-450 hover:text-purple-450"
              >
                Forgot your password?
              </Link>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-450 hover:bg-purple-450 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
        <div className="mt-8 text-center text-sm text-gray-600">
          <p className="mb-3">
            Signing into the Rainmaker app for the first time? Click on "Create account" below to
            get started!
          </p>
          <Link to="/signup" className="font-md text-lg text-purple-450 hover:text-purple-450">
            Create account
          </Link>
        </div>
      </div>
    </div>
  )
}
