import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { upsertCardAsync } from '../moveTheNeedleSlice'

const NewCardForm = ({ list, setAddingNewCard }) => {
  const dispatch = useDispatch()
  const [newCard, setNewCard] = useState({})
  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    setNewCard((prevState) => ({ ...prevState, [name]: value }))
  }

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault()
        await dispatch(
          upsertCardAsync({
            card: { ...newCard, ListId: list.id, position: list.Cards.length }
          })
        )
        setAddingNewCard(null)
      }}
      className="mx-auto"
      style={{ maxWidth: 250 }}
    >
      <input
        name="title"
        onChange={onChange}
        className="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none mb-3"
        placeholder="Card Title"
      />
      <div className="flex justify-between">
        <button
          className="uppercase py-1.5 px-3 hover:bg-gray-100 rounded-md"
          type="button"
          onClick={() => setAddingNewCard(null)}
        >
          Cancel
        </button>
        <button
          className="uppercase py-1.5 px-3 bg-purple-450 hover:opacity-80 text-white rounded-md transition-opacity"
          type="submit"
        >
          Add
        </button>
      </div>
    </form>
  )
}

export default NewCardForm
