import React, { useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentFormField from './PaymentFormField'
import { CheckIcon } from '@heroicons/react/solid'
import { addResource } from '../auth/authSlice'
import { useDispatch } from 'react-redux'

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY)

const ConfirmPurchaseForm = ({ setOpen, editingItem, onPaymentSuccess }) => {
  const [paymentStatus, setPaymentStatus] = useState('pending')
  const dispatch = useDispatch()
  return (
    <>
      {'pending' === paymentStatus ? (
        <div>
          <h3 className="text-2xl">Confirm Purchase</h3>
          <h3 className="text-xl mt-6">{editingItem?.title}</h3>
          <p className="text-lg mt-2">{editingItem?.description}</p>

          <table className="min-w-full divide-y divide-gray-200 my-6 border">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Resource
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900  font-bold">
                  {editingItem?.title}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  ${parseFloat(editingItem?.price).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
          {editingItem?.url && (
            <a
              className="text-purple-450 underline mt-1  mb-5 block outline-none"
              href={editingItem?.url}
              rel="noreferrer"
              target="_blank"
            >
              Learn more
            </a>
          )}

          <Elements stripe={stripePromise}>
            <PaymentFormField
              setOpen={setOpen}
              editingItem={editingItem}
              setPaymentStatus={setPaymentStatus}
              onPaymentSuccess={onPaymentSuccess}
            />
          </Elements>
        </div>
      ) : (
        <div>
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Payment successful</h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Thank you for your purchase. You may access your purchased resources in "My
                  Purchases" area.
                </p>
              </div>
              <button
                className="bg-gray-200 text-black rounded-md px-4 py-2 mt-3"
                onClick={() => {
                  setOpen(false)
                  setPaymentStatus('pending')
                  // reload owned resources
                  dispatch(addResource({ id: editingItem.id }))
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ConfirmPurchaseForm
