import classNames from "classnames";
import { InformationIcon } from "../../components/general/InformationIcon";
import ProgressBar from "../../components/general/ProgressBar";
import Btn from "./../../components/general/Btn";
import { PencilAltIcon } from "@heroicons/react/outline";
import { Link } from 'react-router-dom';

const CourseCard = ({
    image, 
    slug, 
    title, 
    description, 
    status, 
    countCompleted, 
    onClickEdit, 
    onClickGiftCourse, 
    onClickViewCertificate, 
    isEditAllowed,
    isReorderingMode,
    detailInformationMessage,
    showProgress
}) => {

    if(!isEditAllowed && status === "DRAFT") return null;
  
    const showEditButton = () => {
        if (isReorderingMode) return null;
        if (isEditAllowed && onClickEdit) {
            return (
                <span onClick={onClickEdit} className="absolute top-2.5 right-2.5 z-2 bg-white w-8 h-8 flex items-center justify-center rounded-lg text-dark hover:text-secondary transition-colors cursor-pointer">
                    <PencilAltIcon className="w-5 h-5" />
                </span>
            )
        }
        return null
    }

    const showDetailInformationIcon = () => {
        if (isReorderingMode) return null;
        if (detailInformationMessage) {
            return (
                <span className={classNames([
                    "absolute bottom-3.5 right-2.5 z-2 w-3.5 h-3.5 flex items-center justify-center rounded-lg text-dark hover:text-secondary transition-colors cursor-pointer"
                ])}>
                    <InformationIcon tooltipMessage={detailInformationMessage} tooltipId={`${slug}_${title}-${detailInformationMessage}`}/>
                </span>
            )
        }
        return null
    }

    return (
        <div className="data-item-card flex flex-col h-full relative bg-white rounded-2xl border border-solid border-primary border-opacity-20 bg-white overflow-hidden relative group">
            <Link
                to={`/courses/${slug}`}
                className="absolute top-0 left-0 w-full h-full cursor-pointer z-2"
            />
            <div
                className="relative overflow-hidden bg-secondary bg-opacity-5 flex-grow-0"
                style={{ paddingTop: '72.25%' }}
            >
                {image ? (
                    <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 h-full w-full bg-cover bg-no-repeat bg-center p-2.5 group-hover:scale-105 transition-transform"
                        style={{
                            backgroundImage: image ? 'url(' + image + ')' : null
                        }}
                    />
                ) : (
                    <div className="flex absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 h-full w-full bg-cover bg-no-repeat bg-center p-8 group-hover:scale-105 transition-transform bg-placeholder-course-card">
                        <img src="/images/white-rainmaker-family.svg" />
                    </div>
                )}
                {(status && isEditAllowed && status === "DRAFT") && (
                    <span className="absolute top-2.5 left-2.5 bg-white text-dark text-xs leading-20 px-1 rounded">DRAFT</span>
                )}

                {!(countCompleted < 100) && (
                    <div className="absolute top-0 left-0 z-1 w-full h-full bg-secondary bg-opacity-60 flex justify-center items-center">
                        <span className="text-base md:text-md leading-24 md:leading-26 font-medium font-base text-white px-4 py-1 border border-solid border-white border-opacity-14 rounded-full bg-black bg-opacity-30">🎉 Completed</span>
                    </div>
                )}

                {showDetailInformationIcon()}
                {showEditButton()}
                
            </div>
            <div className="xl:py-4 p-3 xl:px-4.5 flex flex-col flex-grow">
                {title && (
                    <h4 
                        className="text-base leading-24 xl:text-md-2 xl:leading-25 font-semibold font-title text-dark transition-colors group-hover:text-secondary cursor-pointer"
                    >
                        {title}
                    </h4>
                )}

                {countCompleted < 100 || showProgress ? (
                    <>
                        {description && countCompleted < 100 ? (
                            <p className="mt-1 text-sm xl:text-base leading-20 xl:leading-24 text-dark-400 font-base font-normal max-w-full">{description}</p>
                        ) : null }
                        {showProgress ? (
                            <div className="pt-4 mt-auto">
                                <ProgressBar
                                    count={countCompleted} 
                                    sizeText="xs"
                                />
                            </div>
                        ): null}
                        
                    </>
                ):(
                    onClickGiftCourse || onClickViewCertificate ? (
                        <div className="pt-4 mt-auto flex flex-wrap gap-2.5 whitespace-nowrap">
                            {onClickGiftCourse && (<div className="flex-1">
                                <Btn
                                    outline={true}
                                    full={true}
                                    onClick={() => onClickGiftCourse()}
                                >
                                    Gift this course
                                </Btn>
                            </div>)}
                            {onClickViewCertificate && (
                                <div className="flex-1">
                                    <Btn
                                        variant="secondary"
                                        outline={true}
                                        full={true}
                                        onClick={() => onClickViewCertificate()}
                                    >
                                        View Certificate
                                    </Btn>
                                </div>
                            )}
                        </div>
                    ) : null
                )}

            </div>
        </div>
    );
}

export default CourseCard;