import ReactTooltip from "react-tooltip"
import Search from "../general/form/Search"

export const CourseSearch = ({
    isReorderingMode,
    value,
    onChange,
    disabled,
    placeholder = "Search",
    tooltipMessage = "Search is disabled while reordering"
}) => {
    return (
        <div className="w-full md:w-auto xl:w-80" data-tip data-for={'search-tooltip'}>
            <Search
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
            />
            {isReorderingMode && (
                <ReactTooltip id="search-tooltip" effect="solid">{tooltipMessage}</ReactTooltip>
            )}
        </div>
    )
}