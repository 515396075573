import React, { useState, useEffect, Suspense } from 'react'
import CheckListItem from './CheckListItem'
import { ViewListIcon } from '@heroicons/react/outline'
import PrimaryButton from '../../../components/general/PrimaryButton'
import SecondaryButton from '../../../components/general/SecondaryButton'
import {
  deleteCheckListAsync,
  upsertCheckListAsync,
  upsertCheckListItemAsync
} from '../moveTheNeedleSlice'
import { useDispatch } from 'react-redux'
import { rehypeRewrite } from '../../../components/general/MarkdownPreview'
import LoadingSpinner from '../../../components/general/LoadingSpinner'

const MDEditor = React.lazy(() => import('@uiw/react-md-editor'))

const CheckList = ({ checkList }) => {
  const dispatch = useDispatch()
  const [isEditing, setIsEditing] = useState(false)
  const [formValues, setFormValues] = useState(checkList)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    setFormValues(checkList)

    let totalCheckListItems = 0
    let totalCheckListItemsCompleted = 0
    checkList.CheckListItems.forEach((item) => {
      ++totalCheckListItems
      if (item.completed) {
        ++totalCheckListItemsCompleted
      }
    })

    0 === totalCheckListItemsCompleted
      ? setProgress(0)
      : setProgress(parseInt((totalCheckListItemsCompleted / totalCheckListItems) * 100))
  }, [checkList])

  return (
    <fieldset className="mb-10">
      <legend className="sr-only">Checklist</legend>
      <h3
        className="flex items-start"
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          setIsEditing(true)
        }}
      >
        <ViewListIcon className="mr-2 flex-shrink-0 h-6 w-6 inline-block mt-2" />{' '}
        <div className="flex-1" data-color-mode="light">
          <Suspense fallback={<LoadingSpinner />}>
            <MDEditor
              name="title"
              value={formValues?.title || ''}
              onChange={(value) => setFormValues((prevState) => ({ ...prevState, title: value }))}
              hideToolbar={true}
              preview={isEditing ? 'edit' : 'preview'}
              previewOptions={{ rehypeRewrite }}
              autoFocus={true}
              className={`text-lg ${
                isEditing ? 'border-2' : 'border-0'
              } rounded-md hover:border-gray-400`}
              height={70}
              visibleDragbar={false}
            />
          </Suspense>
          {isEditing && (
            <div className="flex mt-2">
              <PrimaryButton
                type="button"
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  setIsEditing(false)
                  dispatch(upsertCheckListAsync({ checkList: formValues }))
                }}
                style={{ width: 100 }}
                label="Save"
                size="sm"
                extraClasses="mr-2 uppercase"
              ></PrimaryButton>
              <SecondaryButton
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  setIsEditing(false)
                  dispatch(deleteCheckListAsync(checkList.id))
                }}
                style={{ width: 100 }}
                label="Delete"
                size="sm"
                extraClasses="uppercase bg-red-600 hover:bg-red-500 text-white mr-2"
              ></SecondaryButton>
              <SecondaryButton
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  setIsEditing(false)
                }}
                style={{ width: 100 }}
                label="Cancel"
                size="sm"
                extraClasses="uppercase"
              ></SecondaryButton>
            </div>
          )}

          <div className="relative pt-1 pl-3">
            <div className="flex mb-2 items-center justify-between">
              <div>
                {progress !== 100 ? (
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-purple-450 bg-pink-100">
                    Checklist in progress
                  </span>
                ) : (
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-purple-450 bg-pink-100">
                    Checklist completed
                  </span>
                )}
              </div>
              <div className="text-right">
                <span className="text-xs font-semibold inline-block text-purple-450">
                  {progress}%
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-pink-200">
              <div
                style={{ width: `${progress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-450"
              ></div>
            </div>
          </div>
        </div>
      </h3>
      <div>
        {checkList.CheckListItems?.map((checkListItem) => (
          <CheckListItem key={checkListItem.id} item={checkListItem} />
        ))}

        <SecondaryButton
          onClick={() =>
            dispatch(
              upsertCheckListItemAsync({
                checkListItem: {},
                CheckListId: checkList.id
              })
            )
          }
          label="Add an item"
          extraClasses="ml-10 border mt-2 uppercase"
        />
      </div>
    </fieldset>
  )
}

export default CheckList
