import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { getLessonRedirectAsync } from '../courses-list/coursesListSlice';
import { MainLoader } from '../../components/Loaders/MainLoader';

const Course = () => {
  const { lessonId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { redirectUrl, redirectUrlLoadComplete } = useSelector((state) => state.courseList);

  useEffect(() => {
    if (lessonId) {
      dispatch(getLessonRedirectAsync({ lessonId }))
    }
  }, [lessonId])

  useEffect(() => {
    if (!redirectUrlLoadComplete) {
      return;
    }
    if (redirectUrl) {
      history.push(redirectUrl);
      return;
    }
    history.push('/not-found');
  }, [redirectUrl, redirectUrlLoadComplete]);

  return (<MainLoader />)
}

export default Course
