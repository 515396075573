import React, { useEffect } from 'react'
import { AtSymbolIcon, XIcon } from '@heroicons/react/outline'
import { useDispatch, useSelector } from 'react-redux'
import { mentionUser } from '../moveTheNeedleSlice'
import { getAllCoaches } from '../../user/userSlice'

const useCoaches = () => {
  const dispatch = useDispatch()
  const { coaches } = useSelector((state) => state.user)

  useEffect(() => {
    if (!coaches) dispatch(getAllCoaches())
  }, [dispatch, coaches])

  return coaches
}

const Mention = ({ mentionsShowing, setMentionsShowing }) => {
  const dispatch = useDispatch()
  const { mentionedUsers } = useSelector((state) => state.moveTheNeedle)
  const coaches = useCoaches()
  const unmentionedCoaches = coaches?.filter(
    (coach) => !mentionedUsers.map((user) => user.id).includes(coach.id)
  )

  return (
    <div className="absolute bottom-1 right-2 p-2 hover:bg-gray-100 rounded-full cursor-pointer select-none">
      {mentionsShowing && (
        <div className="relative ">
          <div className="absolute w-80 h-96 bottom-5 right-0 bg-white border px-3 pb-3 z-10">
            <div className="border-b p-3 text-center flex justify-between items-center">
              <span></span>
              <span>Mention a coach:</span>

              <span onClick={() => setMentionsShowing(false)}>
                <XIcon className="h-4 w-4 bg-purple-450 text-white rounded-full" />
              </span>
            </div>
            <div className="mt-2 overflow-scroll h-80">
              <ul className="text-md overflow-x-hidden" style={{ width: 1000 }}>
                {unmentionedCoaches.map((user) => (
                  <li
                    onClick={() => {
                      dispatch(mentionUser({ user }))
                      setMentionsShowing(false)
                    }}
                    key={user.id}
                    className="hover:bg-gray-100 p-1"
                  >
                    {user.fullName} ({user.email})
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
      <span onClick={() => setMentionsShowing((prevState) => !prevState)}>
        <AtSymbolIcon className="h-5 w-5" />
      </span>
    </div>
  )
}

export default Mention
