
const ALL_TAB = "ALL"
const COURSES_TAB = "COURSES"
const MODULES_TAB = "MODULES"
const LESSONS_TITLES_TAB = "LESSONS_TITLES_TAB"
const LESSONS_CONTENT_TAB = "LESSONS_CONTENT_TAB"

const enumCoursesTabs = {
    [ALL_TAB]: "All",
    [COURSES_TAB]: "Courses",
    [MODULES_TAB]: "Modules",
    [LESSONS_TITLES_TAB]: "Lessons (title)",
    [LESSONS_CONTENT_TAB]: "Lessons (content)"
}

exports.ALL_TAB = ALL_TAB
exports.COURSES_TAB = COURSES_TAB
exports.MODULES_TAB = MODULES_TAB
exports.LESSONS_TITLES_TAB = LESSONS_TITLES_TAB
exports.LESSONS_CONTENT_TAB = LESSONS_CONTENT_TAB
exports.enumCoursesTabs = enumCoursesTabs