/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { useDispatch } from 'react-redux'
import { switchRole } from '../../features/auth/authSlice'
import classNames from 'classnames'

function getClassNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SwitchRoleSelect = ({ currentUser, position, size, testId, testData }) => {
  const [roles] = useState(testData?.roles && testId ? testData?.roles : [
    {
      title: 'admin',
      description: 'Can manage the whole app',
      current: true
    },
    {
      title: 'coach',
      description:
        "Can review everyone's Move the Needle, see resources and see Premium Users in the User Dashboard section",
      current: false
    },
    {
      title: 'premium',
      description: 'Can view their own Move the Needle, premium PPT, and access premium resources',
      current: false
    },
    {
      title: 'course only',
      description: 'Can access course resources and premium PPT',
      current: false
    },
    {
      title: 'basic',
      description: 'Can access free resources and basic PPT',
      current: false
    }
  ])

  const dispatch = useDispatch()

  return (
    <div className="px-4 py-2 relative" data-testid={testId}>
      <Listbox
        value={currentUser?.role}
        onChange={(selected) => dispatch(switchRole({ role: selected.title }))}
      >
        <Listbox.Label className="inline-block text-sm leading-20 font-base mb-1 cursor-pointer">
          Switch roles
        </Listbox.Label>
        <div className="relative">
          <div className="inline-flex shadow-sm rounded-md divide-x divide-purple-450">
            <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-purple-450">
              <div className="relative inline-flex items-center bg-purple-450 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                <p className="ml-2.5 text-sm font-medium">{currentUser?.role}</p>
              </div>
              <Listbox.Button data-testid={testId ? `list-options-${testId}` : undefined} className="relative inline-flex items-center bg-purple-450 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-purple-450 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-450">
                <span className="sr-only">Switch roles</span>
                <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
              </Listbox.Button>
            </div>
          </div>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={classNames([
                'absolute z-10 rounded-md overflow-hidden bg-white divide-y divide-white-500 ring-1 ring-black ring-opacity-5 focus:outline-none',
                {
                  'w-72': !size,
                  'w-64': size === 'sm',
                  'right-0 mt-2': position !== 'top-center',
                  'bottom-full left-1/2 transform -translate-x-1/2 mb-2': position === 'top-center'
                }
              ])}
            >
              {roles.map((option) => (
                <Listbox.Option
                  key={option.title}
                  className={({ active }) =>
                    getClassNames(
                      active ? 'text-white bg-purple-450' : 'text-dark',
                      'cursor-pointer select-none relative text-sm',
                      size !== 'sm' ? 'p-4' : 'p-3'
                    )
                  }
                  value={option}
                >
                  {({ active }) => (
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <p
                          className={classNames([
                            {
                              'font-semibold': currentUser.role === option.title,
                              'font-normal': currentUser.role !== option.title,
                              'text-sm leading-20': size === 'sm'
                            }
                          ])}
                        >
                          {option.title?.toUpperCase()}
                        </p>
                        {currentUser.role === option.title ? (
                          <span
                            className={classNames([
                              {
                                'text-white': active,
                                'text-secondary': !active
                              }
                            ])}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                      <p
                        className={classNames([
                          {
                            'text-white': active,
                            'text-dark-400': !active,
                            'mt-2': size !== 'sm',
                            'text-xs leading-20 mt-1': size === 'sm'
                          }
                        ])}
                      >
                        {option.description}
                      </p>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export default SwitchRoleSelect
