import React from 'react'

const PrimaryButton = ({
  label,
  size = 'md',
  type = 'button',
  testId,
  onClick = () => {},
  extraClasses = '',
  ...rest
}) => {
  const sizeClasses = size === 'md' ? 'py-2 px-4' : 'py-1'
  return (
    <button
      type={type}
      data-testid={testId}
      className={`flex justify-center ${sizeClasses} border border-transparent rounded-md shadow-sm text-white bg-purple-450 hover:bg-purple-450 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450 ${extraClasses}`}
      onClick={onClick}
      {...rest}
    >
      {label}
    </button>
  )
}

export default PrimaryButton
