import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const createCustomerPortalSessionAsync = createAsyncThunk(
  'stripe/createCustomerPortalSession',
  async (payload) => {
    try {
      const response = await axios.post(`/api/stripe/create-customer-portal/${payload.customerId}`)

      return { url: response.data.url }
    } catch (error) {
      console.error(error)
    }
  }
)

// 1. Create Payment Intent
export const createPaymentIntentAsync = createAsyncThunk(
  'stripe/createPaymentIntent',
  async ({ userId, resourceId, paymentMethodId }, { dispatch }) => {
    try {
      const response = await axios.post(`/api/stripe/create-payment-intent`, {
        userId,
        resourceId,
        paymentMethodId
      })

      console.log(response)

      return {
        //customerId: response.data.customerId,
        //clientSecret: response.data.clientSecret,
      }
    } catch (error) {
      console.error(error)
    }
  }
)

export const syncWithStripeAsync = createAsyncThunk('stripe/sync', async () => {
  const { data } = await axios.post('/api/stripe/sync')

  return {
    data
  }
})

const initialState = {
  loading: false,

  stripeError: null
}

export const stripeSlice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    createStripeError: (state, action) => {
      state.stripeError = action.payload.message
    },
    clearStripeError: (state, action) => {
      state.stripeError = null
    }
  },
  extraReducers: {}
})

export const { createStripeError, clearStripeError } = stripeSlice.actions

export default stripeSlice.reducer
