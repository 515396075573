import { ArrowRightIcon } from '@heroicons/react/outline'

export const LinkTabTitle = ({
    title,
    buttonTitle,
    onClickButton
}) => {
    return (
        <div className="flex justify-start sm:justify-between w-full items-start sm:items-center gap-1 sm:gap-4 flex-col sm:flex-row">
            <h4 className=" font-bold text-md-4 leading-30">{title}</h4>
            <button 
                onClick={onClickButton}
                className="text-secondary text-sm font-bold flex gap-2 items-center"
            >{buttonTitle} <ArrowRightIcon className="text-secondary text-base font-bold h-5"/></button>
        </div>
    )
}