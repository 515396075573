import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { InformationCircleIcon } from '@heroicons/react/outline'
import PrimaryButton from '../../components/general/PrimaryButton'
import FormField from '../../utils/FormField'
import ProfileUploaderFormField from '../uploader/ProfileUploaderFormField'
import { clearError } from './resourceSlice'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { readManyTagsAsync } from '../tag/tagSlice'
import SecondaryButton from '../../components/general/SecondaryButton'
import LoadingSpinner from '../../components/general/LoadingSpinner'

const MDEditor = React.lazy(() => import('@uiw/react-md-editor'))

const resourceTypes = ['Link', 'Article', 'Course']
const resourceVisibility = [
  { label: 'Free', value: 'Free' },
  { label: 'Paid Upgrade', value: 'For Sale' },
  { label: 'Paid Upgrade Premium', value: 'For Sale Premium' },
  { label: 'Included with Premium', value: 'Included with Premium' }
]

const ResourceForm = ({ setState, setOpen, editingItem, onSubmit }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({ type: 'Link' })

  const { error, resourceCategories: categories, loading } = useSelector((state) => state.resource)
  const { tags } = useSelector((state) => state.tag)

  // set form initial values
  useEffect(() => {
    if (editingItem?.id) setFormValues({ ...editingItem })
    else setFormValues({ type: 'Link', publishedAt: new Date() })
  }, [editingItem])

  useEffect(() => {
    dispatch(readManyTagsAsync())
  }, [dispatch])

  const onChange = (event) => {
    event.preventDefault()
    let { name, value } = event.target
    if (event.target.type === 'checkbox') value = event.target.checked
    error && error[name] && dispatch(clearError({ field: name }))
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await onSubmit(formValues)
    setOpen(false)
  }

  // remove old validation and just prevent rendering if we're fetching anything
  if (loading) return null

  return (
    <div>
      <h3 className="text-2xl">
        {formValues?.id ? 'Edit resource: ' + formValues.title : 'Add a new resource'}
      </h3>

      {formValues?.id && <p className="mt-4">Resource ID: {formValues.id}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <div className="mt-1">
              <FormField
                label="Title"
                field="title"
                error={error && error['title']}
                onChange={onChange}
                state={formValues}
              />
            </div>
          </div>

          <div className="sm:col-span-6">
            <div className="mt-1">
              <FormField
                type="textarea"
                label="Description"
                field="description"
                error={error && error['description']}
                onChange={onChange}
                state={formValues}
              />
            </div>
          </div>

          <div className="sm:col-span-6">
            <div className="mt-1">
              <ProfileUploaderFormField
                field="coverPhotoUrl"
                state={formValues}
                setState={setFormValues}
                buttonLabel="Upload New Resource Picture"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <div className="mt-1">
              <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                Type
              </label>
              <select
                id="type"
                name="type"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-450 focus:border-purple-450 sm:text-sm rounded-md"
                onChange={onChange}
                value={formValues?.type}
              >
                {resourceTypes.map((type, i) => (
                  <option key={i} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="sm:col-span-3">
            <div className="mt-1">
              <label htmlFor="visibility" className="block text-sm font-medium text-gray-700">
                Visibility
              </label>
              <select
                id="visibility"
                name="visibility"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-450 focus:border-purple-450 sm:text-sm rounded-md"
                onChange={onChange}
                value={formValues?.visibility}
              >
                {resourceVisibility.map(({ label, value }, i) => (
                  <option key={i} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {'Article' === formValues?.type && (
            <div className="sm:col-span-6">
              <div className="mt-1" data-color-mode="light">
                <label htmlFor="content" className="block text-sm font-medium text-gray-700 mb-1">
                  Article Content
                </label>
                <Suspense fallback={<LoadingSpinner />}>
                  <MDEditor
                    id="content"
                    name="content"
                    value={formValues?.content || ''}
                    onChange={(value) =>
                      setFormValues((prevState) => ({
                        ...prevState,
                        content: value
                      }))
                    }
                    hideToolbar={false}
                    preview="edit"
                    className={`text-lg border-2 rounded-md hover:border-purple-450`}
                    visibleDragbar={true}
                  />
                </Suspense>
              </div>
            </div>
          )}

          {formValues.type !== 'Course' && (
            <div className="sm:col-span-6">
              <div className="mt-1">
                <FormField
                  label="URL"
                  field="url"
                  error={error && error['url']}
                  onChange={onChange}
                  state={formValues}
                />
              </div>
            </div>
          )}

          {categories && (
            <div className="sm:col-span-3">
              <div className="mt-1">
                <label
                  htmlFor="ResourceCategories"
                  className="block text-sm font-medium text-gray-700"
                >
                  Categories
                </label>
                <Select
                  id="ResourceCategories"
                  isMulti={true}
                  className="mt-2"
                  value={formValues?.ResourceCategories?.map((categ) => ({
                    value: categ.id,
                    label: categ.name
                  }))}
                  styles={{
                    input: (base) => ({
                      ...base,
                      '& input': {
                        boxShadow: 'none !important'
                      }
                    })
                  }}
                  onChange={(ResourceCategories) =>
                    setFormValues((prevState) => ({
                      ...prevState,
                      ResourceCategories: ResourceCategories.map((categ) => ({
                        id: categ.value,
                        name: categ.label
                      }))
                    }))
                  }
                  options={categories.map((categ) => ({
                    value: categ.id,
                    label: categ.name
                  }))}
                />
              </div>
            </div>
          )}

          <div className="sm:col-span-3">
            <label
              htmlFor="tags"
              className="block text-sm font-medium text-gray-700"
              error={error && error['country']}
              onChange={onChange}
              state={formValues}
            >
              Required Tags
            </label>
            <div className="mt-1">
              <Select
                id="tags"
                isMulti={true}
                className="mt-2"
                value={formValues?.Tags?.map((tag) => ({
                  value: tag.id,
                  label: tag.name
                }))}
                styles={{
                  input: (base) => ({
                    ...base,
                    '& input': {
                      boxShadow: 'none !important'
                    }
                  })
                }}
                onChange={(Tags) =>
                  setFormValues((prevState) => ({
                    ...prevState,
                    Tags: Tags.map((tag) => ({
                      id: tag.value,
                      name: tag.label
                    }))
                  }))
                }
                options={tags.map((tag) => ({
                  value: tag.id,
                  label: tag.name
                }))}
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <div className="mt-1">
              <label htmlFor="publishedDate" className="block text-sm font-medium text-gray-700">
                Published Date
              </label>

              {formValues.publishedAt && (
                <DatePicker
                  id="publishedDate"
                  selected={new Date(formValues.publishedAt)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-450 focus:border-purple-450 sm:text-sm rounded-md"
                  onChange={(date) =>
                    setFormValues((prevState) => ({
                      ...prevState,
                      publishedAt: date
                    }))
                  }
                />
              )}
            </div>
          </div>

          <div className="sm:col-span-3 sm:mt-8">
            <fieldset>
              <legend className="sr-only">Hidden</legend>
              <label className="relative flex items-start" htmlFor="hidden">
                <div className="flex items-center h-5">
                  <input
                    id="hidden"
                    name="hidden"
                    type="checkbox"
                    checked={formValues?.hidden}
                    onChange={onChange}
                    className="focus:ring-purple-450 h-4 w-4 text-purple-450 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <span className="font-medium text-gray-700">Hidden</span>
                </div>
              </label>
            </fieldset>
          </div>
        </div>

        {formValues?.visibility?.includes('For Sale') && (
          <div className="sm:col-span-3 sm:mt-8">
            <h1 className="flex align-middle">
              For Sale{' '}
              <span
                className="ml-2 text-sm text-gray-600"
                data-tip="Fill in either Price OR Purchase URL. If Purchase URL is filled in, the resource should have at least one Required Tag."
              >
                <InformationCircleIcon className="h-4 w-4 inline-block" />
              </span>
              <ReactTooltip effect="solid" />
            </h1>

            <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <div className="mt-1">
                  <FormField
                    type="number"
                    label="Price"
                    field="price"
                    required={false}
                    error={error && error['price']}
                    onChange={onChange}
                    state={formValues}
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <div className="mt-1">
                  <FormField
                    label="Purchase URL"
                    field="purchaseUrl"
                    required={false}
                    error={error && error['purchaseUrl']}
                    onChange={onChange}
                    state={formValues}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="mt-24 py-3 flex flex-row space-x-4 sm:justify-between">
          <div className="w-1/3">
            {editingItem?.id && (
              <button
                type="button"
                className="uppercase w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1.5 sm:py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                onClick={() => setState('delete')}
              >
                Delete
              </button>
            )}
          </div>
          <div className="w-2/3 flex flex-1 space-x-4 sm:flex-grow-0 items-start">
            <PrimaryButton extraClasses="px-8 uppercase" label="Save" type="submit" />
            <SecondaryButton
              extraClasses="uppercase"
              label="Cancel"
              onClick={() => setOpen(false)}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ResourceForm
