import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormModal from '../../components/general/FormModal'
import PrimaryButton from '../../components/general/PrimaryButton'
import TagForm from './TagForm'
import { deleteTagAsync, TagsLoadingKeys } from './tagSlice'
import { MainLoader } from '../../components/Loaders/MainLoader'

const TagManagement = () => {
  const { tags, loadingKeys: tagsLoadingKeys, loading } = useSelector((state) => state.tag)
  const [tagModalOpen, setTagModalOpen] = useState(false)
  const [editingTag, setEditingTag] = useState(null)
  const dispatch = useDispatch()
  return (
    <div className="h-screen py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">Tag Management</h1>
          <PrimaryButton
            size="sm"
            extraClasses="py-2 px-4 mr-4"
            label="Add new Tag"
            onClick={() => setTagModalOpen(true)}
            disabled={loading && tagsLoadingKeys.includes(TagsLoadingKeys.tags)}
          />
        </div>
      </div>
      <div className="mt-4 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="flex flex-col">
          {loading && tagsLoadingKeys.includes(TagsLoadingKeys.tags) ? (
            <MainLoader />
          ) : (
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          ></th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Name
                          </th>

                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {tags.length ? (
                          tags.map((tag) => (
                            <tr key={tag.id}>
                              <td className="text-center px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {tag.id}
                              </td>

                              <td className="px-6 py-4 whitespace-nowrap">{tag.name}</td>

                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <button
                                  onClick={() => {
                                    setEditingTag(tag)
                                    setTagModalOpen(true)
                                  }}
                                  className="text-purple-450 hover:text-purple-450"
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">
                              <div className="my-4 text-lg">No tags</div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <FormModal
        open={tagModalOpen}
        setOpen={setTagModalOpen}
        Form={TagForm}
        editingItem={editingTag}
        setEditingItem={setEditingTag}
        deleteAction={async () => {
          await dispatch(deleteTagAsync({ tag: editingTag }))
        }}
      />
    </div>
  )
}

export default TagManagement
