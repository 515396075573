import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { generateTestImportCSVAsync, importUsersAsync } from './userSlice'
import { UploadIcon } from '@heroicons/react/outline'
import { toast } from 'react-toastify'

const UploadCSVForm = ({ setOpen }) => {
  const file = useRef(null)
  const { loading } = useSelector((state) => state.user)

  // upload file listener
  useEffect(() => {
    document.getElementById('file').onclick = (event) => {
      document.getElementById('file').value = null
    }

    document.getElementById('file').onchange = (event) => {
      if (event.target.files && event.target.files.length > 0) {
        importUsersAsync('file')
          .then(() => {
            toast.success('Users imported successfully')
            setOpen(false)
          })
          .catch((err) => toast.error(err.response.data.error))
      }
    }

    // eslint-disable-next-line
  }, [])
  return (
    <div>
      <input
        id="file"
        type="file"
        name="file"
        ref={file}
        className="hidden"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <h1 className="text-center text-2xl mb-2 text-purple-450">
        Upload CSV -{' '}
        <button
          onClick={() => generateTestImportCSVAsync()}
          className="text-sm border-b border-purple-450"
        >
          download test CSV structure
        </button>
      </h1>
      <div className="border-2 border-dashed border-purple-450 bg-purple-100 h-48 text-purple-450 flex flex-col justify-center items-center">
        {loading ? (
          'uploading...'
        ) : (
          <>
            <UploadIcon className="h-10 w-10" />
            <button
              disabled={loading}
              onClick={() => {
                if (file && file.current) {
                  file.current.click()
                }
              }}
              className="uppercase border border-purple-450 rounded-md px-2 py-1 mt-2 hover:bg-pink-200"
            >
              Upload CSV
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default UploadCSVForm
