import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../../components/general/PrimaryButton'
import { createCustomerPortalSessionAsync } from './stripeSlice'

const Blocked = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => state.auth)
  return (
    <div className="h-screen py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 text-center flex flex-col h-full justify-center">
        <h1 className="text-2xl font-semibold text-gray-900">
          Looks like there's a problem with your subscription
        </h1>
        <p>
          Hey Rainmaker! It looks like there has been an error with your payment information.
          <br /> To unlock the full Rainmaker App please update your payment information below
          <br /> or contact support if you believe this alert is in error
        </p>
        <div className="flex justify-between max-w-xs w-full mx-auto my-12 items-center">
          <a
            className="border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-gray-100 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100 py-2 px-4"
            href="mailto:info@rainmakers.academy"
          >
            Contact Support
          </a>
          {currentUser.stripeCustomerId && (
            <div className="w-36">
              <PrimaryButton
                label="Fix"
                onClick={() =>
                  dispatch(
                    createCustomerPortalSessionAsync({
                      customerId: currentUser.stripeCustomerId
                    })
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Blocked
