import { useEffect } from 'react'

const useExternalScript = (lessonId, url) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = url
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
    // add the lesson id as a dependency so that the script is reloaded when the lesson changes
    // the script must be re-run when the lesson changes because the script looks for the div element
    // if we navigate from one lesson to another with a typeform without reloading the script, the typeform embed won't load
  }, [lessonId, url])
}

const TypeformEmbed = ({ lesson }) => {
  useExternalScript(lesson.id, 'https://embed.typeform.com/next/embed.js')

  // the div element that actually renders an embed is added in the lesson content
  // it looks like this: <div data-tf-live="01H5AF6ZP9M1MNDWWZHYFTZQTR" class="mb-4" />
  // this component is just to add the embed script, because the script won't load if it's in the lesson content
  return null
}

export default TypeformEmbed
