import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { clearError, setError, upsertUserAsync } from '../user/userSlice'
import FormField from '../../utils/FormField'
import { loginAsync } from './authSlice'
import RainmakersLogo from '../../components/general/RainmakersLogo'

export default function SignUp() {
  const [user, setUser] = useState({})
  const dispatch = useDispatch()
  const location = useLocation()
  const { error } = useSelector((state) => state.user)

  const onSubmit = async (event) => {
    event.preventDefault()
    try {
      const { type, payload } = await dispatch(upsertUserAsync({ user }))
      if (type.includes('fulfilled')) {
        await dispatch(loginAsync({ user }))
      } else {
        if ('unique violation' === payload[0].type && 'users_email' === payload[0].path) {
          dispatch(
            setError({
              field: 'email',
              error: 'This email address is already in use. Please, sign in instead.'
            })
          )
        }
      }
    } catch (error) {
      console.error('Failed to signup.', error)
    }
  }

  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    error && error[name] && dispatch(clearError({ field: name }))
    setUser((prevState) => ({ ...prevState, [name]: value }))
  }

  // use signup tags from query string
  useEffect(() => {
    const locationSearch = new URLSearchParams(location.search)
    const signupTagsFromQuery = locationSearch.get('signuptags')
    const Tags = signupTagsFromQuery?.split(',').map((id) => ({ id }))
    if (signupTagsFromQuery) setUser((prevState) => ({ ...prevState, Tags }))
  }, [location.search])

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 px-6 lg:px-8">
      <div className="mx-auto w-full max-w-lg">
        <RainmakersLogo />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Create your Rainmakers Account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Welcome to The Rainmaker Family, the #1 place for moms to make passive income online.
          You're now entering our Rainmaker App, an online ecosystem of trainings, tools and
          resources to help you do more in less time.
        </p>
        <p className="mt-2 text-center text-sm text-gray-600">
          For a 7 Day training on how to use this app and the tools inside, consider joining our
          next
          <a
            className="text-base text-purple-450 hover:opacity-80 px-1 py-1 cursor-pointer"
            href="http://therainmakerchallenge.com"
            target="_blank"
            rel="noreferrer"
          >
            Rainmaker Challenge.
          </a>
        </p>
      </div>

      <div className="mt-8 mx-auto w-full max-w-lg">
        <div className="bg-white py-8 shadow rounded-lg px-10">
          <form className="space-y-6" onSubmit={onSubmit}>
            <FormField
              label="Full Name"
              field="fullName"
              error={error && error['fullName']}
              onChange={onChange}
              state={user}
            />

            <FormField
              label="Email Address"
              field="email"
              type="email"
              error={error && error['email']}
              onChange={onChange}
              autoComplete="username"
              state={user}
            />

            <FormField
              label="Create your password"
              field="password"
              type="password"
              error={error && error['password']}
              onChange={onChange}
              autoComplete="current-password"
              state={user}
            />

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <Link to="/signin" className="font-medium text-purple-450 hover:text-purple-450">
                  Have an account?
                </Link>
              </div>

              <div className="text-sm">
                <Link
                  to="/forgot-password"
                  className="font-medium text-purple-450 hover:text-purple-450"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-450 hover:bg-purple-450 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-450"
              >
                Sign up
              </button>
            </div>
          </form>

          <div className="mt-6 text-center">
            <h3 className="text-2xl text-bold px-2 bg-white text-gray-500 mb-6">
              Watch this video and start today!
            </h3>

            <div className="relative" style={{ paddingTop: '56.25%' }}>
              <iframe
                src="https://player.vimeo.com/video/431973371?h=8ea39f9d44&amp;title=0&amp;byline=0&amp;portrait=0&amp;playsinline=0&amp;autopause=0&amp;app_id=122963"
                className="absolute inset-0 w-full h-full"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen=""
                title="Rainmaker App Welcome 2"
                data-ready="true"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
